<itc-custom-dialog>
  <div header class="tenants-picker-dialog__header">{{ 'instance-picker-dialog.title' | translate }}</div>
  <div main class="tenants-picker-dialog__main">
    <itc-smart-filter
      #search
      class="tenants-picker-dialog__search"
      [config]="config"
      [model]="model"
      (updateFilter)="onUpdateSearch($event)"
      (clearFilter)="onClearSearch()"
    />
    <div
      #tenantsContainer
      class="tenants-picker-dialog__tenants-container"
      itcScrollListener
      [style.height]="tenantsContainerHeight"
      [class.tenants-picker-dialog__tenants-container--top-border]="!isScrolledToTop"
      [class.tenants-picker-dialog__tenants-container--bottom-border]="!isScrolledToBottom"
      (scrolledToTop)="isScrolledToTop = $event"
      (scrolledToBottom)="isScrolledToBottom = $event"
    >
      <itc-instance-card *ngFor="let instance of instances$ | async" [module]="module" [instance]="instance" />
    </div>
  </div>
</itc-custom-dialog>
