import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { TutorialsFacade } from '@layout/tutorials/facades/tutorials.facade';

@Component({
  selector: 'itc-suggested-tutorial-card',
  templateUrl: './suggested-tutorial-card.component.html',
  styleUrls: ['./suggested-tutorial-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SuggestedTutorialCardComponent implements OnInit {
  @Input() id: string;
  @Input() title: string;
  @Input({ required: true }) videoUrl: string;

  videoUrlWithoutControls: string;

  constructor(private videoTutorialsFacade: TutorialsFacade) {}

  ngOnInit(): void {
    this.videoUrlWithoutControls = this.videoUrl + '&controls=false';
  }

  onCardClick(): void {
    this.videoTutorialsFacade.selectTutorial(this.id);
  }
}
