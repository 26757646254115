import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { IAuthorizationData } from '@core/authorization/models/authorization-data.interface';
import { environment } from '@env/environment';

@Injectable({ providedIn: 'root' })
export class TokenService {
  private readonly refreshTokenUrl = 'api/v1/refresh-token';
  private httpClientWithoutInterceptors: HttpClient;

  constructor(private httpBackend: HttpBackend) {
    this.httpClientWithoutInterceptors = new HttpClient(httpBackend);
  }

  refreshToken(refreshToken: string): Observable<IAuthorizationData> {
    return this.httpClientWithoutInterceptors.post<IAuthorizationData>(environment.portalApiUrl + this.refreshTokenUrl, {
      refreshToken,
    });
  }
}
