<div
  class="checkbox"
  role="checkbox"
  [class.disabled]="disabled"
  [class.checked]="checked"
  [attr.data-test-id]="testId"
  [id]="elementId"
  (keyup.enter)="toggle()"
>
  <div class="checkmark" tabindex="0">
    <img class="icon-checkmark" src="assets/icons/check.svg" />
  </div>
  <div #ngContent>
    <ng-content />
  </div>
  @if (!ngContent.children.length && label?.length) {
    <span class="text">{{ label | translate }}</span>
  }
</div>
