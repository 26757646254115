import { Injectable, OnDestroy } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap/modal/modal-config';
import { timer, Unsubscribable } from 'rxjs';
import { SessionTimeoutComponent } from '@layout/session-timeout/components/session-timeout/session-timeout.component';
import { Router } from '@angular/router';
import { AuthorizationService } from '@core/authorization/services/authorization.service';

@Injectable({
  providedIn: 'root',
})
export class UserActivityService implements OnDestroy {
  private readonly userActivityUiTimeoutMs = 345 * 1000 * 60;
  private lastActivity: Date;
  private userActivityUiTimer: Unsubscribable | null;

  constructor(
    private authorizationService: AuthorizationService,
    private modalService: NgbModal,
    private router: Router
  ) {
    this.authorizationService.onLogin.subscribe(() => this.beginTracking());
    this.authorizationService.onLogout.subscribe(() => this.endTracking());
  }

  trackActivity(): void {
    this.lastActivity = new Date();
  }

  ngOnDestroy(): void {
    this.endTracking();
  }

  private beginTracking(): void {
    this.trackActivity();
    this.scheduleUserActivityUiCheck(this.userActivityUiTimeoutMs);
  }

  private endTracking(): void {
    this.clearUserActivityUiTimer();
  }

  private clearUserActivityUiTimer(): void {
    if (this.userActivityUiTimer) {
      this.userActivityUiTimer.unsubscribe();
      this.userActivityUiTimer = null;
    }
  }

  private scheduleUserActivityUiCheck(time: number): void {
    this.clearUserActivityUiTimer();
    this.userActivityUiTimer = timer(time).subscribe(() => this.checkIfTimeout());
  }

  private checkIfTimeout(): void {
    const now = new Date();
    const timeLeft = this.lastActivity.getTime() + this.userActivityUiTimeoutMs - now.getTime();
    if (timeLeft <= 0) {
      this.onTimeout();
      return;
    }

    this.scheduleUserActivityUiCheck(timeLeft);
  }

  private onTimeout(): void {
    if (this.authorizationService.isAuthorized()) {
      this.modalService.dismissAll();

      const modalRef = this.modalService.open(SessionTimeoutComponent, <NgbModalOptions>{
        size: 'xs',
        centered: true,
        backdrop: 'static',
      });

      modalRef.closed.subscribe(isResume => {
        if (isResume) {
          this.trackActivity();
          this.scheduleUserActivityUiCheck(this.userActivityUiTimeoutMs);
          return;
        }

        this.router.navigateByUrl('logout');
      });
    }
  }
}
