import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { IDiscoverableModule } from '@layout/launcher/models/discoverable-module.interface';
import { TooltipModule } from '@design/tooltip/tooltip.module';
import { NgOptimizedImage } from '@angular/common';

@Component({
  standalone: true,
  selector: 'itc-discoverable-launcher-item',
  templateUrl: './discoverable-launcher-item.component.html',
  styleUrls: ['../launcher-item/launcher-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TooltipModule, NgOptimizedImage],
})
export class DiscoverableLauncherItemComponent {
  @Input({ required: true }) discoverableModule: IDiscoverableModule;
  @Input() isTooltipRightAligned = false;
  @Output() openedModule = new EventEmitter<void>();

  onLauncherItemClick(clickedModule: IDiscoverableModule): void {
    window.open(clickedModule.landingPageUrl);
    this.openedModule.emit();
  }

  isFullTextDisplayed(element: HTMLSpanElement): boolean {
    return element.clientHeight >= element.scrollHeight;
  }
}
