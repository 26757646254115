import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { AbsorbApiService } from '@core/sso/services/absorb/absorb-api.service';
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { ToastService } from '@design/toast/services/toast.service';
import { absorbSsoLoginErrorCodeToTranslationKey } from '@layout/left-navigation/model/absorb-sso-login-error-code-to-translation-key';

@Injectable({
  providedIn: 'root',
})
export class AbsorbFacade {
  readonly ssoLoginUri$ = this.getSsoLoginUri();

  constructor(
    private absorbApiService: AbsorbApiService,
    private toastService: ToastService
  ) {}

  getSsoLoginUri(): Observable<string> {
    return this.absorbApiService.getSsoLoginUri().pipe(
      catchError((errorResponse: HttpErrorResponse) => {
        if (errorResponse.status === HttpStatusCode.BadRequest) {
          this.toastService.showErrorToast(absorbSsoLoginErrorCodeToTranslationKey[errorResponse.error]);
        }

        return throwError(() => errorResponse);
      })
    );
  }
}
