<div class="launcher__wrapper">
  <div
    role="button"
    class="launcher__icon-container"
    tooltipPosition="bottom-right"
    [attr.aria-label]="'header.tooltips.launcher' | translate"
    [disableTooltip]="isWidgetVisible"
    [itcTooltip]="'header.tooltips.launcher' | translate"
    (click)="toggleWidget()"
  >
    <img aria-hidden="true" class="launcher__icon" src="assets/icons/launcher/waffle.svg" [alt]="'header.tooltips.launcher' | translate" />
  </div>

  <itc-launcher-content [hidden]="!isWidgetVisible" (toggledWidget)="toggleWidget()" />
</div>
