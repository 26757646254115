import { inject, Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { finalize, tap } from 'rxjs/operators';
import { EMPTY, Observable } from 'rxjs';
import { IK365Subscription } from '@layout/k365-subscriptions/models/k365-subscription.interface';
import { K365SubscriptionsService } from '@layout/k365-subscriptions/service/k365-subscriptions.service';
import { LoadK365Subscriptions, MarkK365SubscriptionsAsViewed } from '@layout/k365-subscriptions/store/actions/k365-subscriptions.actions';

interface IK365SubscriptionsStateModel {
  k365Subscriptions?: IK365Subscription[];
  markAsCompletedLoading: boolean;
}

@State<IK365SubscriptionsStateModel>({
  name: 'k365SubscriptionsOnboarding',
  defaults: {
    markAsCompletedLoading: false,
  },
})
@Injectable()
export class K365SubscriptionsState {
  private readonly k365OnboardingService = inject(K365SubscriptionsService);

  @Selector()
  static areAnyK365Subscriptions(state: IK365SubscriptionsStateModel): boolean | undefined {
    if (!state.k365Subscriptions) {
      return undefined;
    }

    return !!state.k365Subscriptions.length;
  }

  @Selector()
  static k365Subscriptions(state: IK365SubscriptionsStateModel): IK365Subscription[] | undefined {
    return state.k365Subscriptions;
  }

  @Selector()
  static markAsCompletedLoading(state: IK365SubscriptionsStateModel): boolean {
    return state.markAsCompletedLoading;
  }

  @Action(LoadK365Subscriptions, { cancelUncompleted: true })
  loadK365Subscriptions(context: StateContext<IK365SubscriptionsStateModel>): Observable<IK365Subscription[]> {
    return this.k365OnboardingService.getK365Subscriptions().pipe(tap(k365Subscriptions => context.patchState({ k365Subscriptions })));
  }

  @Action(MarkK365SubscriptionsAsViewed)
  markK365SubscriptionsAsViewed(
    context: StateContext<IK365SubscriptionsStateModel>,
    { subscriptionIds }: MarkK365SubscriptionsAsViewed
  ): Observable<boolean> {
    if (context.getState().markAsCompletedLoading) {
      return EMPTY;
    }

    context.patchState({ markAsCompletedLoading: true });

    return this.k365OnboardingService
      .markSubscriptionsAsViewed(subscriptionIds)
      .pipe(finalize(() => context.patchState({ markAsCompletedLoading: false })));
  }
}
