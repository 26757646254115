import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { VanillaForumsApiService } from '@core/sso/services/vanilla-forums/vanilla-forums-api.service';

@Injectable({
  providedIn: 'root',
})
export class VanillaForumsFacade {
  readonly loginUrl$ = this.getVanillaForumsUrl();

  constructor(private vanillaForumsApiService: VanillaForumsApiService) {}

  getVanillaForumsUrl(): Observable<string> {
    return this.vanillaForumsApiService.generateLoginUrl();
  }
}
