import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { IManager } from '@layout/organization-managers/models/manager.interface';
import { AvatarComponent } from '@design/avatar/components/avatar/avatar.component';
import { TooltipModule } from '@design/tooltip/tooltip.module';
import { NgOptimizedImage } from '@angular/common';

@Component({
  standalone: true,
  selector: 'itc-manager',
  templateUrl: './manager.component.html',
  styleUrls: ['./manager.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  imports: [AvatarComponent, TooltipModule, NgOptimizedImage],
})
export class ManagerComponent implements OnInit {
  @Input({ required: true }) jobTitle: string;
  @Input({ required: true }) manager: IManager;

  managerPhone?: string;

  ngOnInit(): void {
    this.managerPhone = this.manager.phone ?? this.manager.mobilePhone;
  }
}
