import { Action, Selector, State, StateContext } from '@ngxs/store';
import { inject, Injectable } from '@angular/core';
import { EMPTY, finalize, Observable, tap } from 'rxjs';
import { K365SetupGuidesService } from '@pages/k365-setup-guides/services/k365-setup-guides.service';
import { IK365SubscriptionResources } from '@pages/k365-setup-guides/models/k365-subscription-resources.interface';
import { LoadK365SubscriptionResources } from '@pages/k365-setup-guides/store/actions/k365-subscription-resources.actions';

interface IK365SubscriptionResourcesStateModel {
  resources?: IK365SubscriptionResources;
  loading: boolean;
}

@State<IK365SubscriptionResourcesStateModel>({
  name: 'k365SubscriptionResources',
  defaults: {
    loading: false,
  },
})
@Injectable()
export class K365SubscriptionResourcesState {
  private readonly k365SetupGuidesService = inject(K365SetupGuidesService);

  @Selector()
  static knowledgeBaseArticleUrl(state: IK365SubscriptionResourcesStateModel): string | undefined {
    return state.resources?.kbArticleUrl;
  }

  @Selector()
  static gettingStartedVideoUrl(state: IK365SubscriptionResourcesStateModel): string | undefined {
    return state.resources?.gettingStartedArticleUrl;
  }

  @Selector()
  static welcomeVideoUrl(state: IK365SubscriptionResourcesStateModel): string | undefined {
    return state.resources?.welcomeVideoUrl;
  }

  @Selector()
  static loading(state: IK365SubscriptionResourcesStateModel): boolean {
    return state.loading;
  }

  @Action(LoadK365SubscriptionResources)
  loadK365SubscriptionResources(
    context: StateContext<IK365SubscriptionResourcesStateModel>,
    { subscriptionId }: LoadK365SubscriptionResources
  ): Observable<IK365SubscriptionResources> {
    if (context.getState().loading) {
      return EMPTY;
    }

    context.patchState({ loading: true });

    return this.k365SetupGuidesService.getK365SubscriptionResources(subscriptionId).pipe(
      tap(resources => context.patchState({ resources })),
      finalize(() => context.patchState({ loading: false }))
    );
  }
}
