<div class="modal__container">
  <div class="modal__header">
    <div class="modal__header-text">
      <ng-content select="[header]" />
    </div>
    @if (isCloseButtonVisible) {
      <div class="modal__close" (mousedown)="close(false)"></div>
    }
  </div>
  <div>
    <ng-content select="[main]" />
  </div>
</div>
