import { Component, Input, EventEmitter, Output, HostListener, ElementRef } from '@angular/core';
import { IFilter } from '@design/filter/models/filter.interface';

@Component({
  selector: 'itc-suggestions',
  templateUrl: './suggestions.component.html',
  styleUrls: ['./suggestions.component.scss'],
})
export class SuggestionsComponent {
  @Input() suggestions: IFilter<unknown>[];
  @Output() selectFilter = new EventEmitter();
  @Output() closeSuggestions = new EventEmitter();

  constructor(private elementRef: ElementRef) {}

  onSelectFilter(event: Event, name: string): void {
    event.stopPropagation();
    this.selectFilter.emit(name);
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.closeSuggestions.emit();
    }
  }
}
