import { ISortBy } from '@design/table/models/sort-by.interface';
import { IITCompleteSubscriptionSku } from '@pages/subscriptions/models/module-subscriptions/it-complete-subscriptions-sku-item.interface';
import { IToggleSubscription } from '@pages/subscriptions/models/module-subscriptions/toggle-subscriptions.interface';

export class LoadITCompleteSubscriptions {
  static readonly type = '[ITCompleteSubscriptions] LoadITCompleteSubscriptions';
}

export class SearchITCompleteSubscriptionsByName {
  static readonly type = '[ITCompleteSubscriptions] SearchITCompleteSubscriptionsByName';
  constructor(public name: string) {}
}

export class ITCompleteSkusSorting {
  static readonly type = '[ITCompleteSubscriptions] ITCompleteSkusSorting';
  constructor(
    public sortBy: ISortBy<keyof IITCompleteSubscriptionSku>,
    public name: string
  ) {}
}
export class ToggleITCompleteSubscription {
  static readonly type = '[ITCompleteSubscriptions] ToggleITCompleteSubscription';
  constructor(public toggleSubscription: IToggleSubscription) {}
}

export class ToggleAllITCompleteSubscriptions {
  static readonly type = '[ITCompleteSubscriptions] ToggleAllITCompleteSubscriptions';
  constructor(public areAllExpandedSubscriptions: boolean) {}
}

export class ToggleChildSubscription {
  static readonly type = '[ITCompleteSubscriptions] ToggleChildSubscription';
  constructor(public toggleSubscription: IToggleSubscription) {}
}
