import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { IZendeskSsoRequestInfo } from '@core/sso/models/zendesk/zendesk-sso-request-info.interface';

@Injectable({ providedIn: 'root' })
export class ZendeskLoginApiService {
  private readonly zendeskLoginSsoUrl = 'api/v2/zendesk/auth';

  constructor(private httpClient: HttpClient) {}

  getZendeskSsoUrl(returnTo: string): Observable<IZendeskSsoRequestInfo> {
    return this.httpClient.post<IZendeskSsoRequestInfo>(environment.portalApiUrl + this.zendeskLoginSsoUrl, { returnTo });
  }
}
