import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { getCssVariableValue } from '@shared/utils/css-variable-value';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

@Component({
  selector: 'itc-progress-spinner',
  templateUrl: './progress-spinner.component.html',
  styleUrls: ['./progress-spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [ProgressSpinnerModule],
})
export class ProgressSpinnerComponent {
  @Input() spinnerColor = getCssVariableValue('--primary-color');
  @Input() spinnerSize = 20;
  @Input() spinnerLineHeight?: number;
  @Input() strokeWidth = '4';
}
