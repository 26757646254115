import { ChangeDetectionStrategy, Component, DestroyRef, ElementRef, HostListener, OnDestroy } from '@angular/core';
import { INotification } from '@layout/notifications/models/notification.interface';
import { NotificationTemplateService } from '@layout/notifications/services/notification-template.service';
import { NotificationSource } from '@layout/notifications/models/notification-source.enum';
import { ZendeskLoginService } from '@core/sso/services/zendesk/zendesk-login.service';
import { NotificationsFacade } from '@layout/notifications/facades/notifications.facade';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TooltipModule } from '@design/tooltip/tooltip.module';
import { TranslateModule } from '@ngx-translate/core';
import { AsyncPipe, DatePipe } from '@angular/common';
import { filterIsNotUndefined } from '@shared/rxjs-operators/filter-is-not-undefined';
import { Observable } from 'rxjs';

@Component({
  standalone: true,
  selector: 'itc-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TooltipModule, TranslateModule, AsyncPipe, DatePipe],
})
export class NotificationsComponent implements OnDestroy {
  readonly maximumNotificationCount = 25;

  notifications$: Observable<INotification[]> = this.notificationsFacade.notifications$.pipe(filterIsNotUndefined());
  unreadNotificationsCount$ = this.notificationsFacade.unreadNotificationsCount$;
  areNotificationsVisible = false;

  constructor(
    private elementRef: ElementRef,
    private notificationTemplateService: NotificationTemplateService,
    private zendeskLoginService: ZendeskLoginService,
    private notificationsFacade: NotificationsFacade,
    private destroyRef: DestroyRef
  ) {
    this.notificationsFacade.startCheckingNotifications();
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.areNotificationsVisible = false;
    }
  }

  getNotificationTitle(notification: INotification): string {
    return this.notificationTemplateService.getNotificationTitle(notification);
  }

  getNotificationBody(notification: INotification): string {
    return this.notificationTemplateService.getNotificationBody(notification);
  }

  toggleNotificationsVisibility(): void {
    this.areNotificationsVisible = !this.areNotificationsVisible;
  }

  markAllNotificationsAsViewed(): void {
    this.notificationsFacade.markAllNotificationsAsViewed();
  }

  toggleNotificationViewability({ id, source, viewed }: INotification): void {
    this.notificationsFacade.toggleNotificationViewability(id, source, !viewed);
  }

  deleteAllNotifications(): void {
    this.notificationsFacade.deleteAllNotifications();
  }

  deleteNotification({ id, source }: INotification): void {
    this.notificationsFacade.deleteNotification(id, source);
  }

  openNotification({ source, zendeskTicketFieldWasChanged, zendeskTicketWasChanged }: INotification): void {
    const ticketId = zendeskTicketWasChanged?.ticketId ?? zendeskTicketFieldWasChanged?.ticketId;
    if (source !== NotificationSource.zendesk || !ticketId) {
      return;
    }

    this.notificationsFacade.loadZendeskSsoRequestInfo(ticketId);
    this.notificationsFacade.onLoadZendeskSsoRequestInfoSuccess$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(zendeskSsoRequestInfo => {
        this.zendeskLoginService.goToZendesk(zendeskSsoRequestInfo);
      });
  }

  ngOnDestroy(): void {
    this.notificationsFacade.stopCheckingNotifications();
  }
}
