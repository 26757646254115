import { inject, Injectable } from '@angular/core';
import { CanMatchFn, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { filterIsNotUndefined } from '@shared/rxjs-operators/filter-is-not-undefined';
import { PartnerProgramActiveFacade } from '@layout/partner-program-active/facades/partner-program-active.facade';

@Injectable({
  providedIn: 'root',
})
export class PartnerProgramActiveGuard {
  constructor(
    private partnerProgramActiveFacade: PartnerProgramActiveFacade,
    private router: Router
  ) {
    this.partnerProgramActiveFacade.loadPartnerProgramActive();
  }

  canOpenPage(): Observable<boolean | UrlTree> {
    return this.partnerProgramActiveFacade.isPartnerProgramActive$.pipe(
      filterIsNotUndefined(),
      map(isPartnerProgramActive => {
        return isPartnerProgramActive || this.router.createUrlTree(['access-denied']);
      })
    );
  }
}

export const canMatchPartnerProgramActive: CanMatchFn = () => {
  return inject(PartnerProgramActiveGuard).canOpenPage();
};
