import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { catchError, tap } from 'rxjs/operators';
import { EMPTY, Observable, throwError } from 'rxjs';
import { ToastService } from '@design/toast/services/toast.service';
import { HttpErrorResponse } from '@angular/common/http';
import {
  LoadPartnerProgram,
  LoadPartnerProgramTiers,
  LoadPartnerProgramTiersIfNotLoaded,
} from '@layout/partner-program/store/actions/partner-program.actions';
import { IPartnerProgram } from '@layout/partner-program/models/partner-program-response.interface';
import { PartnerProgramService } from '@layout/partner-program/services/partner-program.service';
import { IPartnerProgramTier } from '@layout/partner-program/models/partner-program-tier.interface';
import { PartnerTier } from '@layout/partner-program/models/partner-tier.enum';

interface IPartnerProgramStateModel {
  currentUserData?: IPartnerProgram;
  partnerProgramTiers?: IPartnerProgramTier[];
}
@State<IPartnerProgramStateModel>({
  name: 'partnerProgram',
})
@Injectable()
export class PartnerProgramState {
  constructor(
    private partnerProgramService: PartnerProgramService,
    private toastService: ToastService
  ) {}

  @Selector()
  static partnerProgram(state: IPartnerProgramStateModel): IPartnerProgram | undefined {
    return state.currentUserData;
  }

  @Selector()
  static currentPartnerTier(state: IPartnerProgramStateModel): PartnerTier | undefined {
    return state.currentUserData?.partnerTier;
  }

  @Selector()
  static partnerProgramTiers(state: IPartnerProgramStateModel): IPartnerProgramTier[] | undefined {
    return state.partnerProgramTiers;
  }

  @Selector()
  static valueToNextTier(state: IPartnerProgramStateModel): number | undefined {
    return state.partnerProgramTiers?.find(partnerProgramTier => partnerProgramTier.partnerTier === state.currentUserData?.nextPartnerTier)
      ?.minimumAnnualRevenue;
  }

  @Selector()
  static currentPartnerMinimumRevenue(state: IPartnerProgramStateModel): number | undefined {
    const currentUserPartnerTier = PartnerProgramState.currentPartnerTier(state);
    return state.partnerProgramTiers?.find(partnerProgramTier => partnerProgramTier.partnerTier === currentUserPartnerTier)
      ?.minimumAnnualRevenue;
  }

  @Action(LoadPartnerProgram)
  loadPartnerProgram(context: StateContext<IPartnerProgramStateModel>): Observable<IPartnerProgram> {
    return this.partnerProgramService.getPartnerProgram().pipe(
      tap(currentUserData => context.patchState({ currentUserData })),
      catchError((errorResponse: HttpErrorResponse) => {
        this.toastService.showErrorToast('partner-program-widget.request-error');
        return throwError(() => errorResponse);
      })
    );
  }

  @Action(LoadPartnerProgramTiersIfNotLoaded)
  loadPartnerProgramTiersIfNotLoaded(context: StateContext<IPartnerProgramStateModel>): Observable<void> {
    if (context.getState().partnerProgramTiers) {
      return EMPTY;
    }

    return context.dispatch(LoadPartnerProgramTiers);
  }

  @Action(LoadPartnerProgramTiers)
  loadPartnerProgramTiers(context: StateContext<IPartnerProgramStateModel>): Observable<IPartnerProgramTier[]> {
    return this.partnerProgramService.getPartnerProgramTiers().pipe(
      tap(partnerProgramTiers =>
        context.patchState({
          partnerProgramTiers,
        })
      ),
      catchError((errorResponse: HttpErrorResponse) => {
        this.toastService.showErrorToast('partner-program-error.fetching-tiers');
        return throwError(() => errorResponse);
      })
    );
  }
}
