import { Action, State } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OnboardingVideoDialogApiService } from '@layout/onboarding-video-dialog/services/onboarding-video-dialog-api.service';
import { MarkWelcomeVideoAsViewed } from '@layout/onboarding-video-dialog/store/actions/onboarding-video-dialog.actions';

@State({
  name: 'onboardingVideoDialog',
})
@Injectable()
export class OnboardingVideoDialogState {
  constructor(private onboardingVideoDialogService: OnboardingVideoDialogApiService) {}

  @Action(MarkWelcomeVideoAsViewed)
  markWelcomeVideoAsViewed(): Observable<void> {
    return this.onboardingVideoDialogService.markWelcomeVideoAsViewed();
  }
}
