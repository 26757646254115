import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ApplicationVersionService } from '@core/application-version/services/application-version.service';
import { LoadApplicationVersion } from '@core/application-version/store/actions/application-version.actions';

export interface IApplicationVersionStateModel {
  applicationVersion?: string;
}

@State<IApplicationVersionStateModel>({
  name: 'applicationVersion',
})
@Injectable()
export class ApplicationVersionState {
  constructor(private applicationVersionService: ApplicationVersionService) {}

  @Selector()
  static getApplicationVersion(state: IApplicationVersionStateModel): string | undefined {
    return state.applicationVersion;
  }

  @Action(LoadApplicationVersion)
  loadApplicationVersion(context: StateContext<IApplicationVersionStateModel>): Observable<string> {
    return this.applicationVersionService.getApplicationVersion().pipe(
      tap(applicationVersion => {
        context.patchState({
          applicationVersion,
        });
      })
    );
  }
}
