<itc-custom-dialog class="tutorials-dialog">
  <div #header header class="tutorials-dialog__header">
    <span class="tutorials-dialog__header-title">{{ 'tutorials.title' | translate }}</span>
    <button class="tutorials-dialog__close-button" (click)="dismiss()">
      <img src="/assets/icons/close.svg" />
    </button>
  </div>
  <div main class="tutorials-dialog__main">
    @if (selectedTutorial$ | async) {
      <div class="tutorials-dialog__suggested-tutorials-column">
        <itc-suggested-tutorials-header />
        <itc-suggested-tutorials
          class="tutorials-dialog__suggested-tutorials"
          [selectedTutorial]="selectedTutorial$ | async"
          [style.height]="suggestedTutorialsHeight"
        />
      </div>
      <itc-tutorial-preview class="tutorials-dialog__tutorial-preview" [style.height]="tutorialPreviewHeight" [autoplay]="autoplay" />
    } @else {
      <itc-tutorials-categories class="tutorials-dialog__categories" [initialCategory]="initialCategory" />
      <div>
        @if (tutorialsFilter) {
          <itc-tutorials-filter />
        }
        <itc-tutorials class="tutorials-dialog__tutorials-list" [style.height]="tutorialsListHeight" />
      </div>
    }
  </div>
</itc-custom-dialog>
