import { ITenant } from '@core/sso/models/tenant.interface';

export class LoadTenants {
  static readonly type = '[Tenants] LoadTenants';
  constructor(public clientId: string) {}
}

export class SelectTenant {
  static readonly type = '[Tenants] SelectTenant';
  constructor(public selectedTenant: ITenant) {}
}
