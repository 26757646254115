import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgxMaskConfig } from 'ngx-mask';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'itc-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputComponent implements OnInit, OnDestroy {
  @Input() label?: string;
  @Input() ariaLabel?: string;
  @Input() ariaLabeledBy?: string;
  @Input({ required: true }) elementId: string;
  @Input() type = 'text';
  @Input() name = '';
  @Input() placeholder = '';
  @Input() autoFocus = false;
  @Input() itcRemoveSpaces = false;
  @Input() autocomplete = 'on';
  @Input() readonly = false;
  @Input() componentFormControl: FormControl<string | number | null>;
  @Input() testId: string;
  @Input() spinnerTestId?: string;
  @Input() mask?: string;
  @Input() isPopupError = false;
  @Input() thousandSeparator: NgxMaskConfig['thousandSeparator'];
  @Input() prefix: NgxMaskConfig['prefix'];
  @Input() showPrefixIfEmpty = true;
  @Input() defaultValueIfEmpty = false;
  @Input() loading = false;
  @Input() isCopyButtonVisible = false;
  @Input() isResetButtonVisible = false;
  @Input() isSmallFontSize = false;
  @Input() stopPropagation = false;

  @Output() copy = new EventEmitter<void>();
  @Output() blur = new EventEmitter<void>();

  @ViewChild('input') input: ElementRef<HTMLInputElement>;

  isInvalid?: boolean;
  isRequired?: boolean;
  tabIndex?: number;
  isEmpty?: boolean;

  private readonly unsubscribe$ = new Subject<void>();

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.setFormControlParams();
    this.handleFormControlUpdate();
  }

  get isResetAvailable(): boolean {
    return this.componentFormControl.defaultValue !== this.componentFormControl.value;
  }

  getPrefix(input: HTMLInputElement): NgxMaskConfig['prefix'] {
    return this.showPrefixIfEmpty || (!this.isEmpty && input.value !== this.prefix) ? this.prefix : '';
  }

  onClick(event: Event): void {
    if (this.stopPropagation) {
      event.stopPropagation();
    }
  }

  onReset(): void {
    this.componentFormControl.reset();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private handleFormControlUpdate(): void {
    this.componentFormControl.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(value => {
      if (this.defaultValueIfEmpty) {
        this.setDefaultValueIfEmpty(value);
      }
      this.setFormControlParams();
    });

    this.componentFormControl.statusChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.setFormControlParams();
    });
  }

  private setFormControlParams(): void {
    this.isInvalid = this.componentFormControl.invalid && this.componentFormControl.dirty;
    this.isRequired = this.componentFormControl.validator && this.componentFormControl.validator({} as never)?.required;
    this.tabIndex = this.readonly || this.componentFormControl.disabled ? -1 : 0;
    this.isEmpty = this.isEmptyValue(this.componentFormControl.value);
    this.changeDetectorRef.markForCheck();
  }

  private setDefaultValueIfEmpty(value: string | number | null): void {
    if (this.isEmptyValue(value) && value !== this.componentFormControl.defaultValue) {
      this.componentFormControl.reset(this.componentFormControl.defaultValue, { emitEvent: false });
    }
  }

  private isEmptyValue(value: string | number | null): boolean {
    return value === '' || value === null;
  }
}
