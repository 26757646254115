import { ITutorial } from '@layout/tutorials/models/tutorial.interface';
import { TutorialCategory } from '@layout/tutorials/models/tutorial-category.enum';

export const tutorialsData: ITutorial[] = [
  {
    id: '1',
    title: 'Welcome to KaseyaOne',
    description: `KaseyaOne is your gateway to Kaseya's IT Complete; a unified platform to streamline your IT operations – saving you time and 
      empowering you to get more IT done – quickly, easily, efficiently, and accurately!`,
    videoUrl: 'https://player.vimeo.com/video/926095052?h=abd0eb7f6a',
    category: TutorialCategory.GettingStarted,
    dateOfCreation: '2022-03-07T12:53:25Z',
  },
  {
    id: '2',
    title: 'Sync and add organizations from IT Glue',
    description: `Leveraging the IT Glue API, this integration facilitates customer onboarding onto BullPhish ID by importing SMB organizations and contacts from IT Glue, eliminating the need for manual data entry. The organizations and contacts remain linked, so any time a customer makes changes in IT Glue, the changes will automatically sync to BullPhish ID.
      Save time managing your clients' data by automatically synchronizing SMB orgs and users from IT Glue to BullPhish ID
      Save time creating organizations in BullPhish ID by using existing data from IT Glue and importing user lists (aka targets) for BullPhish ID campaigns`,
    videoUrl: 'https://player.vimeo.com/video/839071998?h=ab61c2e7b0',
    category: TutorialCategory.ITCompleteIntegrations,
  },
  {
    id: '3',
    title: 'Skip Whitelisting with BullPhish ID-Graphus Integration',
    description: `Lets BullPhish ID place phishing simulations and training emails directly into user inboxes, bypassing sending them via the Internet
      Eliminates the need to whitelist the BullPhish ID sending domains, saves time, ensures 100% delivery of phishing and training exercises = greater training effectiveness for the customers`,
    videoUrl: 'https://player.vimeo.com/video/847093201?h=447ce6a5e6',
    category: TutorialCategory.ITCompleteIntegrations,
  },
  {
    id: '4',
    title: 'SaaS Back-Up Status & Evidence of Compliance for Microsoft 365 and Google Workspace',
    description: `The SaaS Protection back-up status of any protected cloud users, mailboxes, calendars and more from Microsoft 365 or Google Workspace automatically generates an "evidence" file which is stored as an "Exhibit" in the Compliance Manager GRC Site's Files and Exhibits document storage area.
      This workflow automation eliminates the need for IT professionals to manually pull reports on information like last backup time from the Datto SaaS Protection Portal in the event of an audit and saves time by compiling all compliance-related evidence documents in one place.`,
    videoUrl: 'https://player.vimeo.com/video/838957223?h=76ee314aa1',
    category: TutorialCategory.ITCompleteIntegrations,
  },
  {
    id: '5',
    title: 'Compromised Password / User Scanning',
    description: `Passly protects passwords by scanning the dark web for known compromises. If a compromise is found we can block its usage and ensure user accounts rotate their passwors in a timely manner.
      By using DWID to protect passwords & users we are making Passly even more secure. Eliminating bad passwords is critical in IAM.`,
    videoUrl: 'https://player.vimeo.com/video/838948844?h=9dfce71d17',
    category: TutorialCategory.ITCompleteIntegrations,
  },
  {
    id: '6',
    title: 'Insert Credentials from ITGlue Password Vault into Remote Takeover Sessions',
    description: `Troubleshoot and resolve issues faster with direct access to IT Glue documentation and passwords within the Datto RMM interface.
      Datto Endpoint Detection and Response (EDR) offers an effective, easy to use solution to protect endpoints at scale, reducing time and money spent on managing multiple security solutions. Now you can provision and deploy EDR with Datto RMM - including policy management, dashboards, reporting, and centralized alerts.`,
    videoUrl: 'https://player.vimeo.com/video/839068065?h=f69d3df43c',
    category: TutorialCategory.ITCompleteIntegrations,
  },
  {
    id: '7',
    title: 'SaaS Protection Status from Datto RMM Dashboards',
    description: `Keep a closer eye on your SaaS Protection client health from your Datto RMM dashboard with a full status overview. Backup success is color coded to quickly identify backup health, and each client name provides a deep link to the SaaS Protection console for quick recovery or configuration changes.
      Reduce the pain of portal-hopping, and bring your backup visibility into Datto RMM. An RMM tool is the domain of backup monitoring and management, and this now extends to cloud backups as well as server/desktop backups.`,
    videoUrl: 'https://player.vimeo.com/video/847088614?h=d8a8421589',
    category: TutorialCategory.ITCompleteIntegrations,
  },
  {
    id: '8',
    title: 'AWS Documentation Into IT Glue',
    description: `Automates the creation and maintenance of AWS documentation in IT Glue pushed from Network Detective Pro
      This integration is crucial for reducing operational inefficiencies and saving time for MSPs and IT technicians by automating a manual process.`,
    videoUrl: 'https://player.vimeo.com/video/838941576?h=4d8081d4c4',
    category: TutorialCategory.ITCompleteIntegrations,
  },
  {
    id: '9',
    title: 'Dark Web Credential Monitoring for Google Workspace / DarkWeb Credential Monitoring in Gsuit',
    description: `Spanning Administrators can view Dark Web ID compromised credentials for the tenant domains. DWID reports compromised credentials even if they are not current accounts in the domain. 
      Increase security for users and prevent cyber attacks by ensuring leaked passwords aren't being used to login to Google Workspace.`,
    videoUrl: 'https://player.vimeo.com/video/847095347?h=56b9974315',
    category: TutorialCategory.ITCompleteIntegrations,
  },
  {
    id: '10',
    title: 'Unitrends & Datto Continuity for Microsoft Azure',
    videoUrl: 'https://player.vimeo.com/video/847033919?h=0d9aa615f1',
    category: TutorialCategory.ITCompleteIntegrations,
  },
  {
    id: '11',
    title: 'Discover, Monitor, and Manage Datto BCDR Devices Within VSA 10',
    description: `IT Professionals can save time by deploying and verifying Datto Continuity agents within VSA. They can then monitor BCDR for availability and whether a backup was successful. Directly link into the backup appliance to configure, backup, restore, and manage alerts.
      Integrating the top RMM and top BCDR solution will enable technicians to easily perform backup and recovery tasks more efficiently.`,
    videoUrl: 'https://player.vimeo.com/video/839077662?h=2eff94bd51',
    category: TutorialCategory.ITCompleteIntegrations,
  },
  {
    id: '12',
    title: 'Native Autotask Ticketing with Auto-Remediation',
    description: `VSA 10 can natively open tickets in Autotask based on monitoring criteria; workflows can be defined in VSA 10 to run automation and resolve and close tickets. Autotask ticket actions can be easily automated using the Automation Workflow Builder.
      Makes IT professionals much more efficient with automatic ticket creation and remediation, saving them time and reducing their workload.`,
    videoUrl: 'https://player.vimeo.com/video/847098490?h=4e46432916',
    category: TutorialCategory.ITCompleteIntegrations,
  },
  {
    id: '13',
    title: '1-Click IT Glue Password Vault Support for VSA 10',
    description: `This enhancement expands the current functionality of traditional 1-Click, such that when IT Glue is integrated with VSA, and 1-Click is enabled for a VSA 10 Agent, certain IT Glue “password” objects will be available for use in Remote Control activities for the Agent.
      Makes IT professionals more efficient and saves them time by not having to switch between screens and manually enter passwords while in a remote control session.`,
    videoUrl: 'https://player.vimeo.com/video/847102247?h=e77c06753c',
    category: TutorialCategory.ITCompleteIntegrations,
  },
  {
    id: '14',
    title: 'IT Glue In-Context Device Documentation',
    description: `View, manage and create documentation from It Glue while working in VSA 10.
      Makes IT professionals more efficient and saves them time by not having to jump between multiple interfaces.`,
    videoUrl: 'https://player.vimeo.com/video/847105009?h=74a8a1f0e6',
    category: TutorialCategory.ITCompleteIntegrations,
  },
  {
    id: '15',
    title: 'Learn how to enable Unified Login from the module settings',
    description: `Connect your IT Complete modules to KaseyaOne and automatically gain access to all of your connected IT Complete modules by logging in once to KaseyaOne.`,
    videoUrl: 'https://player.vimeo.com/video/926095513?h=53abfe7a39',
    category: TutorialCategory.Onboarding,
  },
  {
    id: '16',
    title: 'Learn how to configure an Identity Provider (IdP) single sign-on with KaseyaOne.',
    description: `KaseyaOne seamlessly integrates with your preferred Identity Provider (IdP) for effortless logins. Enjoy compatibility with popular IdPs like Passly, 
      Okta, Microsoft Entra, and Jump Cloud, giving you complete control over user access.`,
    videoUrl: 'https://player.vimeo.com/video/926095564?h=a6dcf39f5b',
    category: TutorialCategory.Onboarding,
  },
  {
    id: '17',
    title: 'Learn how to change the KaseyaOne password policy.',
    description: `Ensuring a robust password policy for your users is paramount in safeguarding your business against cyber threats seeking unauthorized access to your data.`,
    videoUrl: 'https://player.vimeo.com/video/926095554?h=dd77e1fce7',
    category: TutorialCategory.Onboarding,
  },
  {
    id: '18',
    title: 'Learn how to enable IP Address Access Control for your KaseyaOne account.',
    description: `Add an extra layer of application security by allowing access to KaseyaOne only from specified IP addresses with IP Address Access Control function in KaseyaOne.`,
    videoUrl: 'https://player.vimeo.com/video/926095548?h=462c83e063',
    category: TutorialCategory.Onboarding,
  },
  {
    id: '19',
    title: 'Learn how to manage Access Groups in your KaseyaOne account.',
    description: `KaseyaOne Access Groups grant users access to modules connected to your KaseyaOne account. You can control the access to specific modules on a user-by-user basis.`,
    videoUrl: 'https://player.vimeo.com/video/926095544?h=5e0e70495a',
    category: TutorialCategory.Onboarding,
  },
  {
    id: '20',
    title: 'Learn how to add new users to your KaseyaOne account.',
    description: `Multiple ways to add users in KaseyaOne: one-by-one, in bulk, or import from IT Complete modules.`,
    videoUrl: 'https://player.vimeo.com/video/926095531?h=7917325c6a',
    category: TutorialCategory.Onboarding,
  },
  {
    id: '21',
    title: 'Learn how to set Up Push MFA (multifactor authentication) with the KaseyaOne mobile app.',
    description: `Setting Up Push MFA (multifactor authentication) with the KaseyaOne mobile application.`,
    videoUrl: 'https://player.vimeo.com/video/926095520?h=9d7a0aa31c',
    category: TutorialCategory.Onboarding,
  },
];
