<itc-auth-container>
  <ng-container logo>
    <div class="auth-view__logo"></div>
  </ng-container>
  <ng-container content>
    <div class="auth-view__container">
      <router-outlet />
    </div>
    <itc-copyright />
  </ng-container>
</itc-auth-container>
