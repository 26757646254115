import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { LoadTenants, SelectTenant } from '@core/sso/store/actions/tenants.actions';
import { OpenIdConnectApiService } from '@core/sso/services/open-id-connect/open-id-connect-api.service';
import { ITenant } from '@core/sso/models/tenant.interface';

export interface ITenantsModel {
  data: {
    tenants?: ITenant[];
    selectedTenant?: ITenant;
  };
}

@State<ITenantsModel>({
  name: 'tenants',
  defaults: {
    data: {},
  },
})
@Injectable()
export class TenantsState {
  constructor(private tenantsService: OpenIdConnectApiService) {}

  @Selector()
  static getTenants(state: ITenantsModel): ITenant[] | undefined {
    return state.data.tenants;
  }

  @Selector()
  static getSelectedTenant(state: ITenantsModel): ITenant | undefined {
    return state.data.selectedTenant;
  }

  @Action(LoadTenants)
  loadTenants(context: StateContext<ITenantsModel>, { clientId }: LoadTenants): Observable<ITenant[]> {
    return this.tenantsService.getTenants(clientId).pipe(
      tap(tenants =>
        context.patchState({
          data: { tenants, selectedTenant: undefined },
        })
      )
    );
  }

  @Action(SelectTenant)
  selectTenant(context: StateContext<ITenantsModel>, { selectedTenant }: SelectTenant): void {
    const { tenants } = context.getState().data;
    context.patchState({ data: { tenants, selectedTenant } });
  }
}
