<div class="container">
  <div class="title">{{ name | translate }}</div>
  <div class="content">
    <itc-datepicker
      [clearDates$]="clearDates$"
      [componentFormControl]="startDateControl"
      [label]="'filter.from'"
      [maxDate]="dateForm.value.endDate"
    />

    <itc-datepicker
      [clearDates$]="clearDates$"
      [componentFormControl]="endDateControl"
      [label]="'filter.to'"
      [minDate]="dateForm.value.startDate"
    />
  </div>
</div>
<div class="buttons">
  <div class="link" (click)="clearDates()">{{ 'filter.clear' | translate }}</div>
  <div class="button" (click)="applyFilter()">{{ 'filter.apply' | translate }}</div>
</div>
