import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'itc-date-range-chip',
  templateUrl: './date-range-chip.component.html',
  providers: [DatePipe],
})
export class DateRangeChipComponent {
  @Input() fieldFrom: string;
  @Input() fieldTo: string;
  @Input() name: string;
  @Input() model: Record<string, string | null>;

  @Output() updateModel = new EventEmitter();

  constructor(
    private translateService: TranslateService,
    private datePipe: DatePipe
  ) {}

  getDataLabel(): string | null {
    const startDate = this.datePipe.transform(this.model[this.fieldFrom], 'MMMM d, y');
    const endDate = this.datePipe.transform(this.model[this.fieldTo], 'MMMM d, y');

    if (startDate === endDate) {
      return startDate;
    }

    return this.translateService.instant('filter.date-chip-label', { startDate, endDate });
  }

  removeChip(): void {
    this.model[this.fieldFrom] = null;
    this.model[this.fieldTo] = null;

    this.updateModel.emit(this.model);
  }
}
