import { AllSubscriptionsState } from '@pages/subscriptions/store/states/all-subscriptions.state';
import { ITCompleteSubscriptionsState } from '@pages/subscriptions/store/states/it-complete-subscriptions.state';
import { K365SubscriptionsState } from '@pages/subscriptions/store/states/k365-subscriptions.state';
import { EmptyK365SubscriptionsState } from '@pages/subscriptions/store/states/empty-k365-subscriptions.state';
import { K365SubscriptionState } from '@pages/subscriptions/store/states/k365-subscription.state';

export const subscriptionsState = [
  AllSubscriptionsState,
  ITCompleteSubscriptionsState,
  K365SubscriptionsState,
  EmptyK365SubscriptionsState,
  K365SubscriptionState,
];
