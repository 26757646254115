import { Action, Selector, State, StateContext } from '@ngxs/store';
import { inject, Injectable } from '@angular/core';
import { finalize, Observable, tap } from 'rxjs';
import { IK365SubscriptionActivationModule } from '@pages/k365-setup-guides/models/k365-subscription-activation-module.interface';
import { K365SetupGuidesService } from '@pages/k365-setup-guides/services/k365-setup-guides.service';
import { LoadK365SubscriptionActivationModules } from '@pages/k365-setup-guides/store/actions/k365-subscription-activation-modules.actions';

interface IK365SubscriptionActivationModulesStateModel {
  activationModules?: IK365SubscriptionActivationModule[];
  loading: boolean;
}

@State<IK365SubscriptionActivationModulesStateModel>({
  name: 'k365SubscriptionActivationModules',
  defaults: {
    loading: false,
  },
})
@Injectable()
export class K365SubscriptionActivationModulesState {
  private readonly k365SetupGuidesService = inject(K365SetupGuidesService);

  @Selector()
  static modules(state: IK365SubscriptionActivationModulesStateModel): IK365SubscriptionActivationModule[] | undefined {
    return state.activationModules;
  }

  @Selector()
  static newModules(state: IK365SubscriptionActivationModulesStateModel): IK365SubscriptionActivationModule[] | undefined {
    return state.activationModules?.filter(({ isNew }) => isNew);
  }

  @Selector()
  static existingModules(state: IK365SubscriptionActivationModulesStateModel): IK365SubscriptionActivationModule[] | undefined {
    return state.activationModules?.filter(({ isNew }) => !isNew);
  }

  @Selector()
  static loading(state: IK365SubscriptionActivationModulesStateModel): boolean {
    return state.loading;
  }

  @Action(LoadK365SubscriptionActivationModules, { cancelUncompleted: true })
  loadK365SubscriptionActivationModules(
    context: StateContext<IK365SubscriptionActivationModulesStateModel>,
    { subscriptionId }: LoadK365SubscriptionActivationModules
  ): Observable<IK365SubscriptionActivationModule[]> {
    context.patchState({ loading: true });

    return this.k365SetupGuidesService.getK365SubscriptionActivationModules(subscriptionId).pipe(
      tap(activationModules => context.patchState({ activationModules })),
      finalize(() => context.patchState({ loading: false }))
    );
  }
}
