import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { AuthorizationService } from '@core/authorization/services/authorization.service';
import { ApplicationVersionState } from '@core/application-version/store/states/application-version.state';
import { LoadApplicationVersion } from '@core/application-version/store/actions/application-version.actions';

@Injectable({
  providedIn: 'root',
})
export class ApplicationVersionFacade {
  readonly applicationVersion$ = this.store.select(ApplicationVersionState.getApplicationVersion);

  constructor(
    private store: Store,
    private authorizationService: AuthorizationService
  ) {
    this.authorizationService.onLogin.subscribe(() => {
      this.loadApplicationVersion();
    });
  }

  private loadApplicationVersion(): void {
    this.store.dispatch(new LoadApplicationVersion());
  }
}
