import { inject, Injectable } from '@angular/core';
import { CanMatchFn, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TenantsFacade } from '@core/sso/facades/tenants.facade';

@Injectable({
  providedIn: 'root',
})
export class TenantsGuard {
  constructor(
    private tenantsFacade: TenantsFacade,
    private router: Router
  ) {}

  canMatch(): Observable<UrlTree | boolean> {
    return this.tenantsFacade.tenants$.pipe(
      map(tenants => {
        if (tenants) {
          return true;
        }

        return this.router.createUrlTree(['home']);
      })
    );
  }
}

export const canMatchTenants: CanMatchFn = () => {
  return inject(TenantsGuard).canMatch();
};
