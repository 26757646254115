import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { mergeMap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { PageTitleTranslationKey } from '@core/page-title/models/page-title-translation-key.enum';
import { RouteDataService } from '@core/route-data/services/route-data.service';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PageTitleService {
  constructor(
    private title: Title,
    private translate: TranslateService,
    private routeDataService: RouteDataService
  ) {
    this.routeDataService
      .getDataWithTheDeepestProperty('title')
      .pipe(mergeMap(data => this.getTitleTranslation(data?.title)))
      .subscribe(translatedMessage => {
        this.setPageTitle(translatedMessage);
      });
  }

  private getTitleTranslation(titleKey?: string): Observable<string> {
    const titleKeyOrDefaultKey = titleKey ?? PageTitleTranslationKey.Default;
    return this.translate.get(titleKeyOrDefaultKey);
  }

  private setPageTitle(title: string): void {
    this.title.setTitle(title);
  }
}
