import { inject, Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { EMPTY, Observable } from 'rxjs';
import {
  LoadIsOnboardingCompleted,
  LoadIsOnboardingCompletedIfNotLoaded,
} from '@layout/first-time-onboarding/store/actions/first-time-onboarding-completed.actions';
import { FirstTimeOnboardingCompletedService } from '@layout/first-time-onboarding/services/first-time-onboarding-completed.service';

interface IFirstTimeOnboardingCompletedStateModel {
  isOnboardingCompleted?: boolean;
}

@State<IFirstTimeOnboardingCompletedStateModel>({
  name: 'firstTimeOnboardingCompleted',
})
@Injectable()
export class FirstTimeOnboardingCompletedState {
  private readonly firstTimeOnboardingCompletedService = inject(FirstTimeOnboardingCompletedService);

  @Selector()
  static isOnboardingCompleted(state: IFirstTimeOnboardingCompletedStateModel): boolean | undefined {
    return state.isOnboardingCompleted;
  }

  @Action(LoadIsOnboardingCompletedIfNotLoaded)
  loadIsOnboardingCompletedIfNotLoaded(context: StateContext<IFirstTimeOnboardingCompletedStateModel>): Observable<void> {
    const { isOnboardingCompleted } = context.getState();
    return isOnboardingCompleted !== undefined ? EMPTY : context.dispatch(LoadIsOnboardingCompleted);
  }

  @Action(LoadIsOnboardingCompleted)
  loadIsOnboardingCompleted(context: StateContext<IFirstTimeOnboardingCompletedStateModel>): Observable<boolean> {
    return this.firstTimeOnboardingCompletedService
      .isOnboardingCompleted()
      .pipe(tap(isOnboardingCompleted => context.patchState({ isOnboardingCompleted })));
  }
}
