export enum TagColorName {
  Default,
  OceanBlue,
  DarkBlue,
  SalmonPink,
  Green,
  CharcoalGray,
  LightGreen,
  SolidTeal,
  Teal,
  BluePurple,
  Pink,
  DarkGreen,
  Black,
  Critical,
  SolidDefault,
  SolidGreen,
  SolidGray,
  SolidCritical,
  SolidBlue,
  Success,
  Danger,
  Gray,
  LowSeverity,
  Purple,
  New,
  Orange,
  SolidPurple,
  SolidYellow,
  SolidSuccess,
  SolidIndigo,
  SolidOrange,
  Blue,
}
