import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { RouterLink } from '@angular/router';
import { HelpMenuComponent } from '@layout/help-menu/components/help-menu/help-menu.component';
import { NotificationsComponent } from '@layout/notifications/components/notifications.component';
import { ProfileMenuComponent } from '@layout/profile-menu/components/profile-menu/profile-menu.component';
import { LauncherComponent } from '@layout/launcher/components/launcher/launcher.component';
import { TranslateModule } from '@ngx-translate/core';
import { PermissionsModule } from '@core/permissions/permissions.module';
import { Permission } from '@core/permissions/models/permission.enum';

@Component({
  standalone: true,
  selector: 'itc-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AsyncPipe,
    RouterLink,
    HelpMenuComponent,
    NotificationsComponent,
    ProfileMenuComponent,
    LauncherComponent,
    TranslateModule,
    PermissionsModule,
  ],
})
export class HeaderComponent {
  readonly Permission = Permission;
}
