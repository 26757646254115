import { ILauncherModules } from '@layout/launcher/models/launcher-modules.interface';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { IModule } from '@layout/launcher/models/module.interface';
import { IDiscoverableModule } from '@layout/launcher/models/discoverable-module.interface';
import { LoadLauncherModules } from '@layout/launcher/store/actions/launcher.actions';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { LauncherService } from '@layout/launcher/services/launcher.service';
import { IModuleInstance } from '@layout/launcher/models/module-instance.interface';

interface IModuleStateModel {
  modules?: IModule[];
  discoverableModules?: IDiscoverableModule[];
  loading: boolean;
}

@State<IModuleStateModel>({
  name: 'launcherModules',
  defaults: {
    loading: false,
  },
})
@Injectable()
export class LauncherModulesState {
  constructor(private launcherService: LauncherService) {}

  @Selector()
  static modules(state: IModuleStateModel): IModule[] | undefined {
    return state.modules;
  }

  @Selector()
  static discoverableModules(state: IModuleStateModel): IDiscoverableModule[] | undefined {
    return state.discoverableModules;
  }

  @Selector()
  static loading(state: IModuleStateModel): boolean {
    return state.loading;
  }

  @Selector()
  static moduleInstances(state: IModuleStateModel): (moduleId: number) => IModuleInstance[] {
    return (moduleId: number) => {
      if (!state.modules) {
        return [];
      }

      return state.modules.find(module => module.moduleId == moduleId)?.instances ?? [];
    };
  }

  @Action(LoadLauncherModules)
  loadLauncherModules(context: StateContext<IModuleStateModel>): Observable<ILauncherModules> {
    const state = context.getState();

    if (!state.modules || !state.discoverableModules) {
      context.patchState({ loading: true });
    }

    return this.launcherService.getLauncherModules().pipe(
      finalize(() => context.patchState({ loading: false })),
      tap(({ modules, discoverableModules }) => context.patchState({ modules, discoverableModules }))
    );
  }
}
