import { ChangeDetectionStrategy, Component, DestroyRef, EventEmitter, Output } from '@angular/core';
import { WhatsNewFacade } from '@layout/whats-new/facades/whats-new.facade';
import { TranslateModule } from '@ngx-translate/core';
import { filterIsNotUndefined } from '@shared/rxjs-operators/filter-is-not-undefined';
import { AsyncPipe } from '@angular/common';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SmallButtonComponent } from '@design/button/components/small-button/small-button.component';

@Component({
  standalone: true,
  selector: 'itc-whats-new',
  templateUrl: './whats-new.component.html',
  styleUrls: ['./whats-new.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TranslateModule, AsyncPipe, SmallButtonComponent],
})
export class WhatsNewComponent {
  @Output() openedLink = new EventEmitter<void>();
  whatsNewPageUrl$ = this.whatsNewFacade.whatsNewPageUrl$.pipe(filterIsNotUndefined());
  loading$ = this.whatsNewFacade.loading$;

  constructor(
    private whatsNewFacade: WhatsNewFacade,
    private destroyRef: DestroyRef
  ) {
    this.whatsNewFacade.loadWhatsNewUrl();
  }

  openWhatsNew(): void {
    this.whatsNewPageUrl$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(url => {
      this.openedLink.emit();
      window.open(url);
    });
  }
}
