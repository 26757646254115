import { Injectable } from '@angular/core';
import { IZendeskLoginRequest } from '@core/sso/models/zendesk/zendesk-login-request.interface';
import { ZendeskLoginApiService } from '@core/sso/services/zendesk/zendesk-login-api.service';
import { ToastService } from '@design/toast/services/toast.service';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { SubmitFormService } from '@core/sso/services/submit-form.service';
import { IZendeskSsoRequestInfo } from '@core/sso/models/zendesk/zendesk-sso-request-info.interface';

@Injectable({ providedIn: 'root' })
export class ZendeskLoginService {
  constructor(
    private zendeskLoginApiService: ZendeskLoginApiService,
    private toastService: ToastService,
    private submitFormService: SubmitFormService
  ) {}

  initZendeskFlow(payload: IZendeskLoginRequest): void {
    const returnToUrl = payload.return_to;
    this.zendeskLoginApiService.getZendeskSsoUrl(returnToUrl).subscribe({
      next: ssoRequestInfo => {
        this.goToZendesk(ssoRequestInfo);
      },
      error: (httpErrorResponse: HttpErrorResponse) => {
        if (httpErrorResponse.status === HttpStatusCode.BadRequest) {
          if (httpErrorResponse.error === 'InvalidRequest') {
            this.toastService.showErrorToast('support-tickets-authentication.errors.invalid-url');
          }

          if (httpErrorResponse.error === 'InvalidReturnTo') {
            this.toastService.showErrorToast('support-tickets-authentication.errors.blocked-url');
          }
        }
      },
    });
  }

  goToZendesk(ssoRequestInfo: IZendeskSsoRequestInfo, openInNewTab = false): void {
    this.submitFormService.submitForm({
      method: 'post',
      target: openInNewTab ? '_blank' : '_self',
      action: ssoRequestInfo.ssoUri,
      inputs: [
        {
          type: 'hidden',
          name: 'jwt',
          value: ssoRequestInfo.jwt,
        },
      ],
    });
  }
}
