import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { IRecentSession } from '@pages/professional-services/models/recent-sessions/recent-session.interface';

@Injectable({
  providedIn: 'root',
})
export class ProfessionalServicesRecentSessionsService {
  constructor(private httpClient: HttpClient) {}

  getRecentSessions(): Observable<IRecentSession[]> {
    const url = 'api/v1/professional-services-dashboard/recent-sessions';

    return this.httpClient.get<IRecentSession[]>(environment.portalApiUrl + url);
  }
}
