import { Event, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AppInsightsService } from '@core/app-insight/services/app-insigts.service';

@Injectable({
  providedIn: 'root',
})
export class PageViewTracker {
  constructor(
    private router: Router,
    private appInsightsService: AppInsightsService
  ) {
    this.router.events.subscribe(event => this.handleNavigationError(event));
  }

  private handleNavigationError(event: Event): void {
    if (event instanceof NavigationStart) {
      this.appInsightsService.startTrackPage(event.url);
    } else if (event instanceof NavigationEnd) {
      this.appInsightsService.stopTrackPage(event.url);
    } else if (event instanceof NavigationCancel) {
      this.appInsightsService.stopTrackPage(event.url, { Reason: 'Canceled' });
    } else if (event instanceof NavigationError) {
      this.appInsightsService.stopTrackPage(event.url, { Reason: 'Error', Error: event.error });
    }
  }
}
