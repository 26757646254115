import { Injectable } from '@angular/core';
import { Action, Actions, ofActionDispatched, Selector, State, StateContext } from '@ngxs/store';
import { concatMap, finalize, takeUntil, tap } from 'rxjs/operators';
import { interval, Observable } from 'rxjs';
import { INotification } from '@layout/notifications/models/notification.interface';
import { NotificationsService } from '@layout/notifications/services/notifications.service';
import {
  DeleteAllNotifications,
  DeleteNotification,
  LoadNotifications,
  LoadZendeskSsoRequestInfo,
  LoadZendeskSsoRequestInfoSuccess,
  MarkAllNotificationsAsViewed,
  StartCheckingNotifications,
  StopCheckingNotifications,
  ToggleNotificationViewability,
} from '@layout/notifications/store/actions/notifications.actions';
import { IZendeskSsoRequestInfo } from '@core/sso/models/zendesk/zendesk-sso-request-info.interface';

interface INotificationsStateModel {
  notifications?: INotification[];
  loading: boolean;
}

@State<INotificationsStateModel>({
  name: 'notifications',
  defaults: {
    loading: false,
  },
})
@Injectable()
export class NotificationsState {
  private readonly notificationsFetchingIntervalDurationInMinutes = 5;

  constructor(
    private notificationsService: NotificationsService,
    private actions$: Actions
  ) {}

  @Selector()
  static notifications(state: INotificationsStateModel): INotification[] | undefined {
    return state.notifications;
  }

  @Selector()
  static unreadNotificationsCount(state: INotificationsStateModel): number | undefined {
    return state.notifications?.filter(({ viewed }) => !viewed).length;
  }

  @Selector()
  static loading(state: INotificationsStateModel): boolean {
    return state.loading;
  }

  @Action(LoadNotifications)
  loadNotifications(context: StateContext<INotificationsStateModel>): Observable<INotification[]> {
    context.patchState({ loading: true });
    return this.notificationsService.getNotifications().pipe(
      tap(notifications => context.patchState({ notifications })),
      finalize(() => context.patchState({ loading: false }))
    );
  }

  @Action(StartCheckingNotifications)
  loadManagers(context: StateContext<INotificationsStateModel>): Observable<INotification[]> {
    return this.notificationsService.getNotifications().pipe(
      tap(notifications => context.patchState({ notifications })),
      concatMap(() => {
        return interval(this.notificationsFetchingIntervalDurationInMinutes * 60 * 1000).pipe(
          takeUntil(this.actions$.pipe(ofActionDispatched(StopCheckingNotifications))),
          concatMap(() => this.notificationsService.getNotifications()),
          tap(notifications => context.patchState({ notifications }))
        );
      })
    );
  }

  @Action(MarkAllNotificationsAsViewed)
  markAllNotificationsAsViewed(context: StateContext<INotificationsStateModel>): Observable<void> {
    return this.notificationsService.markAllNotificationsAsRead().pipe(tap(() => context.dispatch(new LoadNotifications())));
  }

  @Action(ToggleNotificationViewability)
  toggleNotificationViewability(
    context: StateContext<INotificationsStateModel>,
    { notificationId, source, viewed }: ToggleNotificationViewability
  ): Observable<void> {
    return this.notificationsService
      .updateNotificationViewability(notificationId, source, viewed)
      .pipe(tap(() => context.dispatch(new LoadNotifications())));
  }

  @Action(DeleteNotification)
  deleteNotification(context: StateContext<INotificationsStateModel>, { notificationId, source }: DeleteNotification): Observable<void> {
    return this.notificationsService.deleteNotification(notificationId, source).pipe(tap(() => context.dispatch(new LoadNotifications())));
  }

  @Action(DeleteAllNotifications)
  deleteAllNotifications(context: StateContext<INotificationsStateModel>): Observable<void> {
    return this.notificationsService.deleteAllNotifications().pipe(tap(() => context.dispatch(new LoadNotifications())));
  }

  @Action(LoadZendeskSsoRequestInfo)
  loadZendeskSsoRequestInfo(
    context: StateContext<INotificationsStateModel>,
    { ticketId }: LoadZendeskSsoRequestInfo
  ): Observable<IZendeskSsoRequestInfo> {
    return this.notificationsService
      .getZendeskSsoRequestInfo(ticketId)
      .pipe(tap(zendeskSsoRequestInfo => context.dispatch(new LoadZendeskSsoRequestInfoSuccess(zendeskSsoRequestInfo))));
  }
}
