import { Injectable } from '@angular/core';
import { IAuthorizationData } from '@core/authorization/models/authorization-data.interface';
import { StorageService } from '@core/storage/services/storage.service';
import { Country } from '@core/authorization/models/country.enum';

@Injectable({
  providedIn: 'root',
})
export class CurrentUserFacade {
  constructor(private storageService: StorageService) {}

  getCountry(): Country | null {
    return this.authorizationData?.country || null;
  }

  get timezoneId(): string | undefined {
    return this.authorizationData?.userTimeZoneId;
  }

  getName(): string {
    return this.authorizationData?.name || '';
  }

  getOrganizationTrackingId(): string {
    return this.authorizationData?.organizationTrackingId || '';
  }

  getOrganizationId(): string {
    return this.authorizationData?.organizationId || '';
  }

  getUserTrackingId(): string {
    return this.authorizationData?.userTrackingId || '';
  }

  getFirstName(): string {
    return this.authorizationData?.userFirstName || '';
  }

  getLastName(): string {
    return this.authorizationData?.userLastName || '';
  }

  getFullName(): string {
    return `${this.getFirstName()} ${this.getLastName()}`.trim();
  }

  getEmail(): string {
    return this.authorizationData?.email || '';
  }

  getOrganizationName(): string {
    return this.storageService.getOrganizationName() || this.authorizationData?.organizationName || '';
  }

  getIsImpersonated(): boolean {
    return this.authorizationData?.isImpersonated || false;
  }

  updateFirstName(userFirstName: string): void {
    if (this.authorizationData) {
      this.storageService.setAuthorizationData({
        ...this.authorizationData,
        userFirstName,
      });
    }
  }

  updateLastName(userLastName: string): void {
    if (this.authorizationData) {
      this.storageService.setAuthorizationData({
        ...this.authorizationData,
        userLastName,
      });
    }
  }

  updateOrganizationName(organizationName: string): void {
    this.storageService.setOrganizationName(organizationName);
  }

  isUserFromCountries(countries: Country[]): boolean {
    const userCountry = this.getCountry();

    return userCountry !== null && countries.includes(userCountry);
  }

  private get authorizationData(): IAuthorizationData | undefined {
    return this.storageService.getAuthorizationData();
  }
}
