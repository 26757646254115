export enum Feature {
  WalkMe = 'WalkMe',
  CooperBotsTab = 'CooperBotsTab',
  CentralizedConfigurationModuleManagement = 'CentralizedConfigurationModuleManagement',
  AllSubscriptionsTab = 'AllSubscriptionsTab',
  ExecutiveReportsSecurityTab = 'ExecutiveReportsSecurityTab',
  OrganizationMappings = 'OrganizationMappings',
  OnboardingFirstMasterUser = 'OnboardingFirstMasterUser',
  Kaseya365Onboarding = 'Kaseya365Onboarding',
  Kaseya365SetupGuide = 'Kaseya365SetupGuides',
  CooperBotsActivityLogs = 'CooperBotsActivityLogs',
  Kaseya365SubscriptionTabV2 = 'Kaseya365SubscriptionTabV2',
}
