import { ChangeDetectionStrategy, Component, EventEmitter, Output, ViewEncapsulation } from '@angular/core';
import { TooltipModule } from '@design/tooltip/tooltip.module';
import { TranslateModule } from '@ngx-translate/core';
import { NgOptimizedImage } from '@angular/common';

@Component({
  standalone: true,
  selector: 'itc-managers-button',
  templateUrl: './managers-button.component.html',
  styleUrls: ['./managers-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  imports: [TooltipModule, TranslateModule, NgOptimizedImage],
})
export class ManagersButtonComponent {
  @Output() click = new EventEmitter<void>();
}
