import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { EMPTY, Observable } from 'rxjs';
import {
  LoadPartnerProgramActive,
  SetPartnerProgramInactive,
} from '@layout/partner-program-active/store/actions/partner-program-active.actions';
import { PartnerProgramActiveService } from '@layout/partner-program-active/services/partner-program-active.service';

interface IPartnerProgramActiveStateModel {
  isPartnerProgramActive?: boolean;
}
@State<IPartnerProgramActiveStateModel>({
  name: 'partnerProgramActive',
})
@Injectable()
export class PartnerProgramActiveState {
  constructor(private partnerProgramActiveService: PartnerProgramActiveService) {}

  @Selector()
  static isPartnerProgramActive(state: IPartnerProgramActiveStateModel): boolean | undefined {
    return state.isPartnerProgramActive;
  }

  @Action(SetPartnerProgramInactive)
  setPartnerProgramInactive(context: StateContext<IPartnerProgramActiveStateModel>): void {
    context.patchState({ isPartnerProgramActive: false });
  }

  @Action(LoadPartnerProgramActive)
  loadPartnerProgramActive(context: StateContext<IPartnerProgramActiveStateModel>): Observable<boolean> {
    if (context.getState().isPartnerProgramActive !== undefined) {
      return EMPTY;
    }

    return this.partnerProgramActiveService
      .getPartnerProgramActive()
      .pipe(tap(isPartnerProgramActive => context.patchState({ isPartnerProgramActive })));
  }
}
