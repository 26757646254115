import { Injectable } from '@angular/core';
import { tutorialsResponse } from '@layout/tutorials/mock-data/tutorials-response';
import { TutorialCategory } from '@layout/tutorials/models/tutorial-category.enum';
import { ITutorialFilter } from '@layout/tutorials/models/tutorial-filter.interface';
import { ITutorial } from '@layout/tutorials/models/tutorial.interface';
import { ITutorialsResponse } from '@layout/tutorials/models/tutorials-response.interface';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TutorialsService {
  getTutorials(filter: ITutorialFilter): Observable<ITutorialsResponse> {
    return of(tutorialsResponse).pipe(
      map(response => {
        const tutorialsByCategory = this.getTutorialsByCategory(response.items, filter.category);
        return {
          ...response,
          items: filter.searchKey ? this.getSearchedTutorials(tutorialsByCategory, filter.searchKey) : tutorialsByCategory,
        };
      })
    );
  }

  getSuggestedTutorials(category: TutorialCategory): Observable<ITutorial[]> {
    if (category === TutorialCategory.All) {
      return of(tutorialsResponse.items);
    }

    return of(tutorialsResponse.items.filter(tutorial => tutorial.category === category));
  }

  getTutorialsByCategory(tutorials: ITutorial[], category?: TutorialCategory): ITutorial[] {
    return category ? tutorials.filter(item => item.category === category) : tutorials;
  }

  private getSearchedTutorials(tutorials: ITutorial[], searchKey: string): ITutorial[] {
    const lowerCasedSearchKey = searchKey.toLowerCase();
    return tutorials.filter(
      tutorial =>
        tutorial.title.toLowerCase().includes(lowerCasedSearchKey) || tutorial.description?.toLowerCase().includes(lowerCasedSearchKey)
    );
  }
}
