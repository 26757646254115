import { NotificationSource } from '@layout/notifications/models/notification-source.enum';
import { IZendeskSsoRequestInfo } from '@core/sso/models/zendesk/zendesk-sso-request-info.interface';

export class LoadNotifications {
  static readonly type = '[Notifications] LoadNotifications';
}

export class StartCheckingNotifications {
  static readonly type = '[Notifications] StartCheckingNotifications';
}

export class StopCheckingNotifications {
  static readonly type = '[Notifications] StopCheckingNotifications';
}

export class MarkAllNotificationsAsViewed {
  static readonly type = '[Notifications] MarkAllNotificationsAsViewed';
}

export class ToggleNotificationViewability {
  static readonly type = '[Notifications] ToggleNotificationViewability';
  constructor(
    public notificationId: string,
    public source: NotificationSource,
    public viewed: boolean
  ) {}
}

export class DeleteAllNotifications {
  static readonly type = '[Notifications] DeleteAllNotifications';
}

export class DeleteNotification {
  static readonly type = '[Notifications] DeleteNotification';
  constructor(
    public notificationId: string,
    public source: NotificationSource
  ) {}
}

export class LoadZendeskSsoRequestInfo {
  static readonly type = '[Notifications] LoadZendeskSsoRequestInfo';
  constructor(public ticketId: string) {}
}

export class LoadZendeskSsoRequestInfoSuccess {
  static readonly type = '[Notifications] LoadZendeskSsoRequestInfoSuccess';
  constructor(public zendeskSsoRequestInfo: IZendeskSsoRequestInfo) {}
}
