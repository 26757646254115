import { Injectable } from '@angular/core';
import { WhatsNewService } from '@layout/whats-new/services/whats-new.service';
import { LoadWhatsNewUrl } from '@layout/whats-new/store/actions/whats-new.actions';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';

export interface IWhatsNewStateModel {
  whatsNewUrl?: string;
  loading: boolean;
}

@State<IWhatsNewStateModel>({
  name: 'whatsNew',
  defaults: {
    loading: false,
  },
})
@Injectable()
export class WhatsNewState {
  constructor(private whatsNewService: WhatsNewService) {}

  @Selector()
  static getWhatsNewPageUrl(state: IWhatsNewStateModel): string | undefined {
    return state.whatsNewUrl;
  }

  @Selector()
  static loading(state: IWhatsNewStateModel): boolean {
    return state.loading;
  }

  @Action(LoadWhatsNewUrl)
  loadWhatsNewUrl(context: StateContext<IWhatsNewStateModel>): Observable<string> {
    context.patchState({ loading: true });

    return this.whatsNewService.getWhatsNewUrl().pipe(
      tap(whatsNewUrl => context.patchState({ whatsNewUrl })),
      finalize(() => context.patchState({ loading: false }))
    );
  }
}
