import { ApplicationRef, ComponentRef, createComponent, EnvironmentInjector, Injectable, Type } from '@angular/core';
import { SidePanelComponent } from '@design/side-panel/components/side-panel/side-panel.component';
import { ISidePanelConfig } from '@design/side-panel/model/side-panel-config.interface';
import { defaultConfig } from '@design/side-panel/model/default-side-panel-config';

@Injectable({ providedIn: 'root' })
export class SidePanelService {
  sidePanelRef?: ComponentRef<SidePanelComponent>;
  sidePanelContentRef?: ComponentRef<unknown>;

  constructor(
    private applicationRef: ApplicationRef,
    private injector: EnvironmentInjector
  ) {}

  openSidePanel(sidePanelContent: Type<unknown>, componentInputs?: Record<string, unknown>, config?: Partial<ISidePanelConfig>): void {
    this.closePanelIfExists();
    this.sidePanelContentRef = this.getCreatedSidePanelContentRef(sidePanelContent);
    this.appendSidePanelContentInputs(componentInputs);
    this.applicationRef.attachView(this.sidePanelContentRef.hostView);
    this.sidePanelRef = this.getCreatedSidePanelRef();
    document.body.appendChild(this.sidePanelRef.location.nativeElement);
    this.applicationRef.attachView(this.sidePanelRef.hostView);
    this.sidePanelRef.instance.config = { ...defaultConfig, ...config };
  }

  closePanelIfExists(): void {
    if (!this.sidePanelRef || !this.sidePanelContentRef) {
      return;
    }
    this.sidePanelRef.destroy();
    this.sidePanelContentRef.destroy();
    this.sidePanelRef = undefined;
    this.sidePanelContentRef = undefined;
  }

  private getCreatedSidePanelContentRef(sidePanelContent: Type<unknown>): ComponentRef<unknown> {
    return createComponent<unknown>(sidePanelContent, {
      environmentInjector: this.injector,
    });
  }

  private getCreatedSidePanelRef(): ComponentRef<SidePanelComponent> {
    return createComponent(SidePanelComponent, {
      environmentInjector: this.injector,
      projectableNodes: [[this.sidePanelContentRef?.location.nativeElement]],
    });
  }

  private appendSidePanelContentInputs(componentInputs?: Record<string, unknown>): void {
    for (const inputName in componentInputs) {
      this.sidePanelContentRef?.setInput(inputName, componentInputs[inputName]);
    }
  }
}
