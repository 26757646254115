export class LoadPartnerProgram {
  static readonly type = '[PartnerProgram] Load Partner Program';
}

export class LoadPartnerProgramTiersIfNotLoaded {
  static readonly type = '[PartnerProgram] Load Partner Program Tiers If Not Loaded';
}

export class LoadPartnerProgramTiers {
  static readonly type = '[PartnerProgram] Load Partner Program Tiers';
}
