import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { IWalkMeDataResponse } from '@core/walk-me/models/walk-me-data-response.interface';

@Injectable({ providedIn: 'root' })
export class WalkMeApiService {
  private readonly getWalkMeDataUrl = 'api/v1/walk-me/data';

  constructor(private httpClient: HttpClient) {}

  getWalkMeData(): Observable<IWalkMeDataResponse> {
    return this.httpClient.get<IWalkMeDataResponse>(environment.portalApiUrl + this.getWalkMeDataUrl);
  }
}
