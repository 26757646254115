import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

@Injectable({ providedIn: 'root' })
export class VanillaForumsApiService {
  private readonly vanillaForumsLoginUrl = 'api/v1/vanilla-forums/sso-login-url';

  constructor(private httpClient: HttpClient) {}

  generateLoginUrl(): Observable<string> {
    return this.httpClient.get(environment.portalApiUrl + this.vanillaForumsLoginUrl, { responseType: 'text' });
  }
}
