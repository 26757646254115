import { AfterContentInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[itcAutoFocus]',
  standalone: true,
})
export class AutofocusDirective implements AfterContentInit {
  @Input({ required: true, alias: 'itcAutoFocus' }) enabled = false;

  constructor(private elementRef: ElementRef) {}

  ngAfterContentInit(): void {
    if (this.enabled) {
      this.elementRef.nativeElement.focus();
    }
  }
}
