import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from '@app/app.module';
import { environment } from '@env/environment';

if (environment.production) {
  enableProdMode();
}

async function prepareApp(): Promise<ServiceWorkerRegistration | undefined> {
  if (environment.envName === 'localhost') {
    const { worker } = await import('./server-mocks');
    return worker.start({ onUnhandledRequest: 'bypass' });
  }

  return Promise.resolve(undefined);
}

prepareApp().then(() => {
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch(err => console.log(err));
});
