<div
  #content
  role="tooltip"
  [ngClass]="['custom-tooltip', customClass ? customClass : '']"
  [style.transform]="transform"
  [style.left.px]="left"
  [style.right.px]="right"
  [style.top.px]="top"
  [style.bottom.px]="bottom"
  [style.padding]="padding"
>
  <div class="custom-tooltip__content">
    <ng-container *ngTemplateOutlet="contentTemplateRef; context: contentTemplateRefValues" />
    {{ text }}
  </div>
</div>
