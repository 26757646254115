import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class FirstTimeOnboardingService {
  private readonly httpClient = inject(HttpClient);

  markOnboardingAsCompleted(): Observable<void> {
    const url = 'api/v1/onboarding/first-master-users/completed';

    return this.httpClient.post<void>(environment.portalApiUrl + url, null);
  }
}
