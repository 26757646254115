import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TranslationApiService } from '@core/translation/services/translation-api.service';
import { mergeMap, Observable } from 'rxjs';
import { defaultLanguage } from '@core/translation/models/default-language';

@Injectable({ providedIn: 'root' })
export class TranslationService {
  constructor(
    private translateService: TranslateService,
    private translationApiService: TranslationApiService
  ) {}

  initialize(): Observable<void> {
    this.translateService.setDefaultLang(defaultLanguage);
    return this.translationApiService.getLanguage().pipe(mergeMap(language => this.translateService.use(language)));
  }
}
