import { ISsoParams } from '@design/open-sso-link/models/sso-params.interface';

export class SsoParamsResponse {
  isSsoEnabled: true;
  body: ISsoParams;

  constructor(body: ISsoParams) {
    this.isSsoEnabled = true;
    this.body = body;
  }
}
