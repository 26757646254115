import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { PermissionsService } from '@core/permissions/services/permissions.service';
import { Permission } from '@core/permissions/models/permission.enum';

@Directive({
  selector: '[itcPermission]',
})
export class PermissionDirective {
  @Input() set itcPermission(permission: Permission | undefined) {
    if (!permission || (permission && this.permissionService.hasUserPermission(permission))) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      return;
    }

    this.viewContainer.clear();
  }

  constructor(
    private templateRef: TemplateRef<never>,
    private viewContainer: ViewContainerRef,
    private permissionService: PermissionsService
  ) {}
}
