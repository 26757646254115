import { Injectable } from '@angular/core';
import { AuthorizationService } from '@core/authorization/services/authorization.service';
import { UserActivityService } from '@layout/session-timeout/services/user-activity.service';

@Injectable({
  providedIn: 'root',
})
export class BrowserEventsService {
  private readonly options: AddEventListenerOptions = {
    capture: true,
    passive: true,
  };
  private readonly listener = (): void => this.userActivityService.trackActivity();

  constructor(
    private userActivityService: UserActivityService,
    private authorizationService: AuthorizationService
  ) {
    this.authorizationService.onLogin.subscribe(() => this.beginTracking());
    this.authorizationService.onLogout.subscribe(() => this.endTracking());
  }

  private beginTracking(): void {
    this.addListener('click');
    this.addListener('keydown');
    this.addListener('touchstart');
  }

  private endTracking(): void {
    this.removeListener('click');
    this.removeListener('keydown');
    this.removeListener('touchstart');
  }

  private addListener(type: string): void {
    window.addEventListener(type, this.listener, this.options);
  }

  private removeListener(type: string): void {
    window.removeEventListener(type, this.listener, this.options);
  }
}
