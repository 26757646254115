import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { IState } from '@core/locations/models/state.interface';

@Injectable({
  providedIn: 'root',
})
export class StatesService {
  private readonly getStatesUrl = (countryId: number): string => `api/v1/lookups/countries/${countryId}/states`;

  constructor(private httpClient: HttpClient) {}

  getStates(countryId: number): Observable<IState[]> {
    return this.httpClient.get<IState[]>(environment.portalApiUrl + this.getStatesUrl(countryId));
  }
}
