import { Injectable } from '@angular/core';
import { AppInsightsService } from '@core/app-insight/services/app-insigts.service';
import { HttpErrorResponse, HttpRequest } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class LoggerService {
  constructor(private appInsightService: AppInsightsService) {}

  logServerException(errorResponse: HttpErrorResponse, request?: HttpRequest<unknown>): void {
    this.appInsightService.trackException(this.createError(errorResponse), { request: JSON.stringify(request) });
  }

  private createError(errorResponse: HttpErrorResponse): Error {
    if (typeof errorResponse.error !== 'string') {
      return new Error(JSON.stringify(errorResponse.error));
    }

    return new Error(errorResponse.error);
  }
}
