import { AfterViewChecked, ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthorizationService } from '@core/authorization/services/authorization.service';
import { TranslateModule } from '@ngx-translate/core';
import { NgForOf, NgIf, NgOptimizedImage } from '@angular/common';
import { AuthContainerComponent } from '@layout/auth-container/components/auth-container.component';
import { LargeButtonComponent } from '@design/button/components/large-button/large-button.component';

@Component({
  standalone: true,
  selector: 'itc-mobile-stub',
  templateUrl: './mobile-stub.component.html',
  styleUrls: ['./mobile-stub.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TranslateModule, NgForOf, NgIf, NgOptimizedImage, AuthContainerComponent, LargeButtonComponent],
})
export class MobileStubComponent implements AfterViewChecked {
  readonly pointKeys = ['it-complete', 'kaseya-university', 'support-tickets', 'invoices'];

  constructor(
    private router: Router,
    private authorizationService: AuthorizationService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  get isAuthorized(): boolean {
    return this.authorizationService.isAuthorized();
  }

  logoutFromKaseyaOne(): void {
    this.router.navigate(['logout']);
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.markForCheck();
  }
}
