<div class="calendar__wrapper">
  <itc-calendar-input [componentFormControl]="componentFormControl" [label]="label" (toggleCalendar)="isShowCalendar = true" />
  @if (isShowCalendar) {
    <div class="calendar__container">
      <div class="calendar__content">
        <div class="calendar__navigation">
          <div class="calendar__button calendar__button-left" (click)="previousMonth()"></div>
          <div class="calendar__text">
            <div class="calendar__text-month" [class.calendar__text-month--selected]="isMonthView" (click)="isMonthView = true">
              {{ currentDate.date | date: 'MMMM' }}
            </div>
            <itc-years-select [currentDate]="currentDate" (changeDate)="onYearChange($event)" />
          </div>
          <div class="calendar__button calendar__button-right" (click)="nextMonth()"></div>
        </div>
        @if (isMonthView) {
          <itc-months (changeDate)="onMonthChange($event)" />
        } @else {
          <itc-days
            [weeks]="weeks"
            [currentDate]="currentDate"
            [maxDate]="maxDate"
            [minDate]="minDate"
            (changeDate)="onDayChange($event)"
          />
        }
      </div>
      <div class="calendar__footer">
        <itc-regular-button colorScheme="tertiary" [buttonText]="'buttons.clear' | translate" (buttonClick)="onClearClick()" />
      </div>
    </div>
  }
</div>
