import { Store } from '@ngxs/store';
import { inject, Injectable } from '@angular/core';
import { ResetStateFacade } from '@core/store/facades/reset-state.facade';
import { FirstTimeOnboardingCompletedState } from '@layout/first-time-onboarding/store/state/first-time-onboarding-completed.state';
import { LoadIsOnboardingCompletedIfNotLoaded } from '@layout/first-time-onboarding/store/actions/first-time-onboarding-completed.actions';

@Injectable({
  providedIn: 'root',
})
export class FirstTimeOnboardingCompletedFacade {
  readonly store = inject(Store);
  readonly isOnboardingCompleted$ = this.store.select(FirstTimeOnboardingCompletedState.isOnboardingCompleted);
  private readonly resetStateFacade = inject(ResetStateFacade);

  loadIsOnboardingCompletedIfNotLoaded(): void {
    this.store.dispatch(new LoadIsOnboardingCompletedIfNotLoaded());
  }

  clearState(): void {
    this.resetStateFacade.clearState(FirstTimeOnboardingCompletedState);
  }
}
