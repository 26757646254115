import { EnvironmentProviders, inject, makeEnvironmentProviders } from '@angular/core';
import { DEFAULT_LANGUAGE, TranslateLoader, TranslateService, USE_EXTEND } from '@ngx-translate/core';
import { defaultLanguage } from '@core/translation/models/default-language';
import { TranslationFeature } from '@core/translation/models/translation-feature.enum';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { translationRootUrl } from '@core/translation/models/translation-root-url';

export const provideTranslationConfig = (translationFeature: TranslationFeature): EnvironmentProviders => {
  return makeEnvironmentProviders([
    {
      provide: TranslateLoader,
      useFactory: HttpFeatureLoaderFactory(translationFeature),
    },
    { provide: DEFAULT_LANGUAGE, useValue: defaultLanguage, multi: true },
    { provide: USE_EXTEND, useValue: true, multi: true },
    TranslateService,
  ]);
};

function HttpFeatureLoaderFactory(translationFeature: TranslationFeature): () => TranslateHttpLoader {
  return () => new TranslateHttpLoader(inject(HttpClient), `${translationRootUrl}${translationFeature}/`, '.json');
}
