import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { TutorialsFacade } from '@layout/tutorials/facades/tutorials.facade';
import { TutorialsListState } from '@layout/tutorials/models/tutorials-list-state.enum';

@Component({
  selector: 'itc-tutorials',
  templateUrl: './tutorials.component.html',
  styleUrls: ['./tutorials.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TutorialsComponent implements OnInit {
  readonly tutorialsListState = TutorialsListState;
  filteredTutorials$ = this.tutorialsFacade.tutorials$;
  tutorialsListState$ = this.tutorialsFacade.tutorialsListState$;

  constructor(private tutorialsFacade: TutorialsFacade) {}

  ngOnInit(): void {
    this.tutorialsFacade.loadVideoTutorials();
  }
}
