import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputErrorComponent as CustomInputErrorComponent } from '@design/input-error/components/custom-input-error/input-error.component';
import { TranslateModule } from '@ngx-translate/core';
import { InputPopupErrorComponent } from '@design/input-error/components/input-popup-error/input-popup-error.component';

@NgModule({
  declarations: [CustomInputErrorComponent, InputPopupErrorComponent],
  exports: [CustomInputErrorComponent, InputPopupErrorComponent],
  imports: [CommonModule, TranslateModule],
})
export class InputErrorModule {}
