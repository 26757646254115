import { Component } from '@angular/core';
import { AppInsightsService } from '@core/app-insight/services/app-insigts.service';
import { PageTitleService } from '@core/page-title/services/page-title.service';
import { PageViewTracker } from '@core/app-insight/services/page-view-tracker.service';
import { WalkMeService } from '@core/walk-me/services/walk-me.service';
import { ApplicationVersionFacade } from '@core/application-version/facades/application-version.facade';
import { ResetStateFacade } from '@core/store/facades/reset-state.facade';
import { MobileVersionManagerService } from '@layout/mobile-stub/services/mobile-version-manager.service';
import { BrowserEventsService } from '@layout/session-timeout/services/browser-events.service';
import { UserActivityService } from '@layout/session-timeout/services/user-activity.service';

@Component({
  selector: 'itc-app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  isMobileVersionDisabled$ = this.mobileVersionManagerService.isMobileVersionDisabled();

  constructor(
    private browserEventsService: BrowserEventsService,
    private userActivityService: UserActivityService,
    private pageViewTracker: PageViewTracker,
    private appInsights: AppInsightsService,
    private pageTitleService: PageTitleService,
    private mobileVersionManagerService: MobileVersionManagerService,
    private walkMeService: WalkMeService,
    private applicationVersionFacade: ApplicationVersionFacade,
    private resetStateFacade: ResetStateFacade
  ) {}
}
