import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'itc-text-chip',
  templateUrl: './text-chip.component.html',
})
export class TextChipComponent {
  @Input() field: string;
  @Input() name: string;
  @Input() model: Record<string, unknown>;

  @Output() updateModel = new EventEmitter();

  removeChip(): void {
    this.model[this.field] = null;

    this.updateModel.emit(this.model);
  }
}
