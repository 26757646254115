import { TutorialCategory } from '@layout/tutorials/models/tutorial-category.enum';

export class LoadTutorials {
  static readonly type = '[Tutorials] LoadTutorials';
}

export class SelectTutorial {
  static readonly type = '[Tutorials] SelectTutorial';
  constructor(public id: string) {}
}

export class UnselectTutorial {
  static readonly type = '[Tutorials] UnselectTutorial';
}

export class ResetState {
  static readonly type = '[Tutorials] ResetState';
}

export class SelectCategory {
  static readonly type = '[Tutorials] SelectCategory';
  constructor(public category?: TutorialCategory) {}
}

export class SetSearchKey {
  static readonly type = '[Tutorials] SetSearchKey';
  constructor(public searchKey?: string) {}
}

export class LoadSuggestedTutorials {
  static readonly type = '[Tutorials] LoadSuggestedTutorials';
  constructor(public category: TutorialCategory) {}
}
