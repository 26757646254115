import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScrollListenerDirective } from '@design/scroll-listener/directives/scroll-listener.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [ScrollListenerDirective],
  exports: [ScrollListenerDirective],
})
export class ScrollListenerModule {}
