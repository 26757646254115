<div class="main-container">
  @if (isImpersonatedUser) {
    <itc-impersonate-banner />
  }
  <itc-header class="main-container__header" />
  <div class="main-container__content" [class.main-container__content--impersonated]="isImpersonatedUser">
    <div class="main-container__left">
      <itc-left-panel />
    </div>
    <div class="main-container__main">
      <div class="main-container__main-scroll" [class.main-container__main-scroll--screen-width]="fitsScreenWidth()">
        <router-outlet />
      </div>
    </div>
  </div>
</div>
