import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { IProjectDetails } from '@pages/professional-services/models/details/project-details.interface';

@Injectable({
  providedIn: 'root',
})
export class ProfessionalServicesProjectDetailsService {
  private readonly getProjectDetailsUrl = (projectId: string): string => `api/v1/professional-services-dashboard/projects/${projectId}`;

  constructor(private httpClient: HttpClient) {}

  getProfessionalServicesProjectDetails(projectId: string): Observable<IProjectDetails> {
    return this.httpClient.get<IProjectDetails>(environment.portalApiUrl + this.getProjectDetailsUrl(projectId));
  }
}
