import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ILoginAttempt } from '@core/authorization/models/login-attempt.interface';
import { map } from 'rxjs/operators';
import { isNotUndefined } from '@shared/utils/is-not-undefined';
import { filterIsNotUndefined } from '@shared/rxjs-operators/filter-is-not-undefined';

@Injectable({ providedIn: 'root' })
export class LoginAttemptService {
  private loginAttempt$ = new BehaviorSubject<ILoginAttempt | undefined>(undefined);

  setLoginAttempt(loginAttempt: ILoginAttempt): void {
    this.loginAttempt$.next(loginAttempt);
  }

  get isLoginAttemptSet(): Observable<boolean> {
    return this.loginAttempt$.pipe(map(isNotUndefined));
  }

  get onLoginAttempt(): Observable<ILoginAttempt> {
    return this.loginAttempt$.asObservable().pipe(filterIsNotUndefined());
  }
}
