@if (photoUrl) {
  <img class="avatar__photo" [alt]="elementDescriptor" [class.avatar__photo--small]="isSmall" [src]="photoUrl" />
} @else {
  @if (firstName && lastName) {
    <div
      role="img"
      class="avatar__initials-container"
      [attr.aria-label]="elementDescriptor"
      [class.avatar__initials-container--small]="isSmall"
    >
      <span class="avatar__initials">{{ getInitials() | uppercase }}</span>
    </div>
  } @else {
    <img class="avatar__photo" [alt]="elementDescriptor" [class.avatar__photo--small]="isSmall" [src]="defaultPhotoSrc" />
  }
}
