import { Component, DestroyRef, ElementRef, HostListener, Input, OnInit, TemplateRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Subject } from 'rxjs';

@Component({
  selector: 'itc-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  @Input() tooltipDisabled = true;
  @Input() tooltipText: string;
  @Input() menuTemplateRef: TemplateRef<unknown>;
  @Input() closeMenu$?: Subject<void>;
  @Input({ required: true }) ariaLabel: string;

  isMenuVisible = false;

  constructor(
    private elementRef: ElementRef,
    private destroyRef: DestroyRef
  ) {}

  ngOnInit(): void {
    this.closeMenu$?.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.isMenuVisible = false;
    });
  }

  toggleMenuVisibility(): void {
    this.isMenuVisible = !this.isMenuVisible;
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.isMenuVisible = false;
    }
  }
}
