import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { hideToast } from '@core/errors/models/context';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { IOrganizationManagers } from '@layout/organization-managers/models/organization-managers.interface';

@Injectable({
  providedIn: 'root',
})
export class OrganizationManagersService {
  private readonly getOrganizationManagersUrl = 'api/v1/company/managers';

  constructor(private httpClient: HttpClient) {}

  getOrganizationManagers(): Observable<IOrganizationManagers> {
    return this.httpClient.get<IOrganizationManagers>(environment.portalApiUrl + this.getOrganizationManagersUrl, { context: hideToast() });
  }
}
