import { SortOrder } from '@design/table/models/sort-order.enum';

export function sortByString<T>(subscriptionSkus: T[], key: keyof T, sortOrder: SortOrder): T[] {
  return [...subscriptionSkus].sort((previousItem, nextItem) => {
    const previousValue = previousItem[key];
    const nextValue = nextItem[key];
    return sortOrder === SortOrder.Ascending
      ? String(previousValue).localeCompare(String(nextValue))
      : String(nextValue).localeCompare(String(previousValue));
  });
}

export function sortByNumber<T>(subscriptionSkus: T[], key: keyof T, sortOrder: SortOrder): T[] {
  return [...subscriptionSkus].sort((previousItem, nextItem) => {
    const previousValue = previousItem[key];
    const nextValue = nextItem[key];
    return sortOrder === SortOrder.Ascending ? Number(previousValue) - Number(nextValue) : Number(nextValue) - Number(previousValue);
  });
}

export function sortByDate<T>(subscriptionSkus: T[], key: keyof T, sortOrder: SortOrder): T[] {
  return [...subscriptionSkus].sort((previousItem, nextItem) => {
    const previousValue = new Date(previousItem[key] as Date);
    const nextValue = new Date(nextItem[key] as Date);
    return sortOrder === SortOrder.Ascending
      ? previousValue.getTime() - nextValue.getTime()
      : nextValue.getTime() - previousValue.getTime();
  });
}

export const sortFunctionsMapping = {
  item: sortByString,
  totalLicenses: sortByNumber,
  expiresOn: sortByDate,
};
