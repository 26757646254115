import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { FeaturesState } from '@core/features/store/state/features.state';
import { map, Observable } from 'rxjs';
import { Feature } from '@core/features/models/feature.enum';
import { ClearState, LoadFeatures } from '@core/features/store/actions/features.actions';
import { AuthorizationService } from '@core/authorization/services/authorization.service';
import { LoginAttemptService } from '@core/authorization/services/login-attempt.service';
import { filterIsNotUndefined } from '@shared/rxjs-operators/filter-is-not-undefined';

@Injectable({
  providedIn: 'root',
})
export class FeaturesFacade {
  readonly features$ = this.store.select(FeaturesState.features);

  constructor(
    private store: Store,
    private authorizationService: AuthorizationService,
    private loginAttemptService: LoginAttemptService
  ) {
    this.authorizationService.onLogin.subscribe(() => this.loadFeatures());
    this.loginAttemptService.onLoginAttempt.subscribe(({ mfaToken }) => {
      this.loadFeatures(mfaToken);
    });
    this.authorizationService.onLogout.subscribe(() => this.clearState());
  }

  isFeatureEnabled$(feature: Feature): Observable<boolean> {
    return this.store.select(FeaturesState.isFeatureEnabled).pipe(
      map(selector => selector(feature)),
      filterIsNotUndefined()
    );
  }

  loadFeatures(mfaToken?: string): void {
    this.store.dispatch(new LoadFeatures(mfaToken));
  }

  clearState(): void {
    this.store.dispatch(new ClearState());
  }
}
