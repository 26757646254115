import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { TutorialCategory } from '@layout/tutorials/models/tutorial-category.enum';
import { tutorialCategoryTagColorNames } from '@layout/tutorials/models/tutorial-category-tag-color-names';
import { TagColorName } from '@design/tag/models/tag-color-name.enum';
import { tutorialCategoryNameTranslationKeys } from '@layout/tutorials/models/tutorial-category-name-translation-keys';
import { TutorialsFacade } from '@layout/tutorials/facades/tutorials.facade';

@Component({
  selector: 'itc-tutorial-card',
  templateUrl: './tutorial-card.component.html',
  styleUrls: ['./tutorial-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TutorialCardComponent implements OnInit {
  @Input() id: string;
  @Input() title: string;
  @Input() description: string;
  @Input() category: TutorialCategory;
  @Input({ required: true }) videoUrl: string;

  tagColorName: TagColorName;
  categoryTranslationKey: string;
  videoUrlWithoutControls: string;

  constructor(private videoTutorialsFacade: TutorialsFacade) {}

  ngOnInit(): void {
    this.tagColorName = tutorialCategoryTagColorNames[this.category];
    this.categoryTranslationKey = `tutorials.tutorials-categories.${tutorialCategoryNameTranslationKeys[this.category]}`;
    this.videoUrlWithoutControls = this.videoUrl + '&controls=false';
  }

  onCardClick(): void {
    this.videoTutorialsFacade.selectTutorial(this.id);
    this.videoTutorialsFacade.loadSuggestedTutorials(this.category);
  }
}
