import { Component, ChangeDetectionStrategy } from '@angular/core';
import { CollapseButtonComponent } from '@layout/left-panel/components/collapse-button/collapse-button.component';
import { LeftNavigationComponent } from '@layout/left-navigation/components/left-navigation/left-navigation.component';
import { ManagersButtonComponent } from '@layout/left-panel/components/managers-button/managers-button.component';
import { OrganizationManagersComponent } from '@layout/organization-managers/components/organization-managers/organization-managers.component';
import { Permission } from '@core/permissions/models/permission.enum';
import { PermissionsModule } from '@core/permissions/permissions.module';

@Component({
  standalone: true,
  selector: 'itc-left-panel',
  templateUrl: './left-panel.component.html',
  styleUrls: ['./left-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CollapseButtonComponent, LeftNavigationComponent, ManagersButtonComponent, OrganizationManagersComponent, PermissionsModule],
})
export class LeftPanelComponent {
  readonly Permission = Permission;
  isLeftPanelCollapsed = false;

  toggleLeftPanel(): void {
    this.isLeftPanelCollapsed = !this.isLeftPanelCollapsed;
  }
}
