<div class="auth-container">
  <div class="auth-container__background" [class.auth-container__background--dark]="isDark">
    <div class="auth-container__background-top-right-wave"></div>
    <div class="auth-container__background-bottom-left-wave"></div>
    <div #logoContainer>
      <ng-content select="[logo]" />
    </div>
  </div>
  <div class="auth-container__content" [class.auth-container__content--center]="isFormLongerThanScreen">
    <div #contentContainer class="auth-container__content__window">
      <ng-content select="[content]" />
    </div>
  </div>
</div>
