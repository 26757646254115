import { ISortBy } from '@design/table/models/sort-by.interface';
import { IK365SubscriptionSku } from '@pages/subscriptions/models/module-subscriptions/k365-subscriptions-sku-item.interface';
import { IToggleSubscription } from '@pages/subscriptions/models/module-subscriptions/toggle-subscriptions.interface';

export class LoadK365Subscriptions {
  static readonly type = '[K365Subscriptions] LoadK365Subscriptions';
}

export class SearchK365SubscriptionsByName {
  static readonly type = '[K365Subscriptions] SearchK365SubscriptionsByName';
  constructor(public name: string) {}
}

export class K365SkusSorting {
  static readonly type = '[K365Subscriptions] K365SkusSorting';
  constructor(
    public sortBy: ISortBy<keyof IK365SubscriptionSku>,
    public name: string
  ) {}
}

export class ToggleK365Subscription {
  static readonly type = '[K365Subscriptions] ToggleK365Subscription';
  constructor(public toggleSubscription: IToggleSubscription) {}
}

export class ToggleAllK365Subscriptions {
  static readonly type = '[K365Subscriptions] ToggleAllK365Subscriptions';
  constructor(public areAllExpandedSubscriptions: boolean) {}
}

export class ToggleChildSubscription {
  static readonly type = '[K365Subscriptions] ToggleChildSubscription';
  constructor(public toggleSubscription: IToggleSubscription) {}
}
