import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { LauncherModulesState } from '@layout/launcher/store/states/launcher-modules.state';
import { map, Observable } from 'rxjs';
import { ImagePreloaderService } from '@core/image-preloader/services/image-preloader.service';
import { LoadLauncherModules } from '@layout/launcher/store/actions/launcher.actions';
import { AuthorizationService } from '@core/authorization/services/authorization.service';
import { IModuleInstance } from '@layout/launcher/models/module-instance.interface';

@Injectable({
  providedIn: 'root',
})
export class LauncherModulesFacade {
  readonly modules$ = this.store.select(LauncherModulesState.modules);
  readonly discoverableModules$ = this.store.select(LauncherModulesState.discoverableModules);
  readonly loading$ = this.store.select(LauncherModulesState.loading);

  private areLauncherImagesPreloaded = false;

  constructor(
    private authorizationService: AuthorizationService,
    private imagePreloader: ImagePreloaderService,
    private store: Store
  ) {
    this.authorizationService.onLogin.subscribe(() => this.loadModules());
  }

  moduleInstances$(moduleId: number): Observable<IModuleInstance[]> {
    return this.store.select(LauncherModulesState.moduleInstances).pipe(map(selector => selector(moduleId)));
  }

  loadModules(): void {
    this.store.dispatch(new LoadLauncherModules()).subscribe(() => {
      this.preloadLauncherImages();
    });
  }

  private preloadLauncherImages(): void {
    if (this.areLauncherImagesPreloaded) {
      return;
    }

    const modules = this.store.selectSnapshot(LauncherModulesState.modules);
    const discoverableModules = this.store.selectSnapshot(LauncherModulesState.discoverableModules);

    if (modules && discoverableModules) {
      const imageUrls = [...modules.map(module => module.moduleImageUrl), ...discoverableModules.map(module => module.moduleImageUrl)];
      this.imagePreloader.preload(imageUrls);
      this.areLauncherImagesPreloaded = true;
    }
  }
}
