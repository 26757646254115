import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class FirstTimeOnboardingCompletedService {
  private readonly httpClient = inject(HttpClient);

  isOnboardingCompleted(): Observable<boolean> {
    const url = 'api/v1/onboarding/first-master-users/completed';

    return this.httpClient.get<boolean>(environment.portalApiUrl + url);
  }
}
