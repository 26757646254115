import { inject, Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { finalize, tap } from 'rxjs/operators';
import { LoadK365SubscriptionSuites } from '@pages/subscriptions/store/actions/k365-subscription.actions';
import { SubscriptionsService } from '@pages/subscriptions/services/subscriptions.service';
import { IK365SubscriptionSuite } from '@pages/subscriptions/models/k365-subscription/k365-subscription-suite.interface';
import { Observable } from 'rxjs';

interface IK365SubscriptionStateModel {
  subscriptionSuites?: IK365SubscriptionSuite[];
  loading: boolean;
}

@State<IK365SubscriptionStateModel>({
  name: 'k365Subscription',
  defaults: {
    loading: false,
  },
})
@Injectable()
export class K365SubscriptionState {
  private readonly subscriptionsService = inject(SubscriptionsService);

  @Selector()
  static subscriptionSuites(state: IK365SubscriptionStateModel): IK365SubscriptionSuite[] | undefined {
    return state.subscriptionSuites;
  }

  @Selector()
  static loading(state: IK365SubscriptionStateModel): boolean {
    return state.loading;
  }

  @Action(LoadK365SubscriptionSuites, { cancelUncompleted: true })
  loadK365SubscriptionSuites(context: StateContext<IK365SubscriptionStateModel>): Observable<IK365SubscriptionSuite[]> {
    return this.subscriptionsService.getK365SubscriptionSuites().pipe(
      tap(subscriptionSuites => context.patchState({ subscriptionSuites })),
      finalize(() => context.patchState({ loading: false }))
    );
  }
}
