import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { patch } from '@ngxs/store/operators';
import { catchError, finalize, tap } from 'rxjs/operators';
import { LoadUpcomingSessions } from '@pages/professional-services/store/actions/professional-services-upcoming-sessions.actions';
import { IUpcomingSession } from '@pages/professional-services/models/upcoming-sessions/upcoming-session.interface';
import { ProfessionalServicesUpcomingSessionsService } from '@pages/professional-services/services/professional-services-upcoming-sessions.service';

interface IProfessionalServicesUpcomingSessionsStateModel {
  upcomingSessions?: IUpcomingSession[];
  loading: boolean;
  error: boolean;
}

@State<IProfessionalServicesUpcomingSessionsStateModel>({
  name: 'professionalServicesUpcomingSessions',
  defaults: {
    loading: false,
    error: false,
  },
})
@Injectable()
export class ProfessionalServicesUpcomingSessionsState {
  constructor(private professionalServicesUpcomingSessionsService: ProfessionalServicesUpcomingSessionsService) {}

  @Selector()
  static upcomingSessions(state: IProfessionalServicesUpcomingSessionsStateModel): IUpcomingSession[] | undefined {
    return state.upcomingSessions;
  }

  @Selector()
  static loading(state: IProfessionalServicesUpcomingSessionsStateModel): boolean {
    return state.loading;
  }

  @Selector()
  static error(state: IProfessionalServicesUpcomingSessionsStateModel): boolean {
    return state.error;
  }

  @Action(LoadUpcomingSessions)
  loadUpcomingSessions(context: StateContext<IProfessionalServicesUpcomingSessionsStateModel>): Observable<IUpcomingSession[]> {
    context.setState(patch<IProfessionalServicesUpcomingSessionsStateModel>({ loading: true }));

    return this.professionalServicesUpcomingSessionsService.getUpcomingSessions().pipe(
      tap(upcomingSessions =>
        context.setState(
          patch<IProfessionalServicesUpcomingSessionsStateModel>({
            upcomingSessions,
            error: false,
          })
        )
      ),
      catchError(() => {
        context.setState(
          patch<IProfessionalServicesUpcomingSessionsStateModel>({
            upcomingSessions: undefined,
            error: true,
          })
        );
        return EMPTY;
      }),
      finalize(() => context.setState(patch<IProfessionalServicesUpcomingSessionsStateModel>({ loading: false })))
    );
  }
}
