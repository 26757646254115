import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DatepickerComponent } from '@design/datepicker/components/datepicker/datepicker.component';
import { MonthsComponent } from '@design/datepicker/components/months/months.component';
import { DaysComponent } from '@design/datepicker/components/days/days.component';
import { CalendarInputComponent } from '@design/datepicker/components/calendar-input/calendar-input.component';
import { YearsSelectComponent } from '@design/datepicker/components/years-select/years-select.component';
import { InputErrorModule } from '@design/input-error/input-error.module';
import { RegularButtonComponent } from '@design/button/components/regular-button/regular-button.component';

@NgModule({
  declarations: [DatepickerComponent, MonthsComponent, DaysComponent, CalendarInputComponent, YearsSelectComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, TranslateModule, InputErrorModule, RegularButtonComponent],
  exports: [DatepickerComponent, CalendarInputComponent],
})
export class DatepickerModule {}
