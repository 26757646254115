import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { IColorScheme } from '@design/color-scheme.interface';
import { NgClass } from '@angular/common';
import { ProgressSpinnerComponent } from '@design/progress-spinner/components/progress-spinner.component';
import { TranslateModule } from '@ngx-translate/core';
import { getCssVariableValue } from '@shared/utils/css-variable-value';
import { ButtonType } from '@design/button/models/button-type.type';

@Component({
  selector: 'itc-regular-button',
  templateUrl: './regular-button.component.html',
  styleUrls: ['./regular-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, ProgressSpinnerComponent, TranslateModule],
})
export class RegularButtonComponent {
  @Input() colorScheme: IColorScheme = 'primary';
  @Input() disabled = false;
  @Input() buttonText = '';
  @Input() loading = false;
  @Input() testId: string;
  @Input() spinnerSize = 17;
  @Input() isCritical: boolean;
  @Input() type: ButtonType = 'button';
  @Input() alignContentToRight = false;
  @Output() buttonClick = new EventEmitter();

  get spinnerColor(): string {
    switch (this.colorScheme) {
      case 'primary':
      case 'kaseya365':
        return '#ffffff';

      case 'warning':
        return getCssVariableValue('--font-color-dark-blue');

      case 'secondary':
      case 'tertiary':
        return this.isCritical ? getCssVariableValue('--dark-red') : getCssVariableValue('--font-color-dark-blue');

      case 'tertiary-cta':
        return '#166EFA';

      default:
        return 'gray';
    }
  }

  onClick(event: Event): void {
    event.preventDefault();

    if (!this.disabled && !this.loading) {
      this.buttonClick.emit();
    }
  }
}
