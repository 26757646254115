import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { asNonNull } from '@shared/utils/as-non-null';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'itc-custom-dialog',
  templateUrl: './custom-dialog.component.html',
  styleUrls: ['./custom-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class CustomDialogComponent implements AfterViewInit, AfterViewChecked, OnDestroy {
  @Input() testId: string;
  @Input() scrollToTop?: Subject<void>;
  @Input() disableAutofocus = false;

  @ViewChild('header') header: ElementRef<HTMLElement>;
  @ViewChild('main') main: ElementRef<HTMLElement>;
  @ViewChild('actions') actions: ElementRef<HTMLElement>;

  maxMainHeight: number;
  isScrolledToBottom = false;
  isScrolledToTop = false;

  private readonly minimumAllowedMainHeight = 150;
  private readonly verticalPaddings = 160;
  private readonly unsubscribe$ = new Subject<void>();
  private maxMainContentHeight: number;
  private window: Window;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.window = asNonNull(this.document.defaultView);
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.rerenderDialog();
  }

  get hasVerticalScrollbar(): boolean {
    return this.maxScrollPosition > 0;
  }

  get shouldRenderWithScrollbar(): boolean {
    return this.maxMainContentHeight >= this.maxHeightForMain;
  }

  onScroll(): void {
    if (this.currentScrollPosition === 0) {
      this.isScrolledToTop = true;
      return;
    }
    if (this.currentScrollPosition === this.maxScrollPosition) {
      this.isScrolledToBottom = true;
      return;
    }
    this.isScrolledToBottom = false;
    this.isScrolledToTop = false;
  }

  ngAfterViewInit(): void {
    this.maxMainContentHeight = this.getMaxMainContentHeight();
    this.rerenderDialog();
    this.handleScrollToTop();
  }

  ngAfterViewChecked(): void {
    if (!this.main) {
      return;
    }

    const newMaxMainContentHeight = this.getMaxMainContentHeight();

    if (newMaxMainContentHeight !== this.maxMainContentHeight) {
      this.maxMainContentHeight = newMaxMainContentHeight;
      this.rerenderDialog();
      this.handleScrollToTop();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private handleScrollToTop(): void {
    if (this.scrollToTop) {
      this.scrollToTop.pipe(takeUntil(this.unsubscribe$)).subscribe(() => (this.main.nativeElement.scrollTop = 0));
    }
  }

  private rerenderDialog(): void {
    if (this.shouldRenderWithScrollbar || !this.hasVerticalScrollbar || this.maxMainHeightUnderWindowThreshold) {
      this.maxMainHeight = this.maxHeightForMain;
      this.isScrolledToTop = true;
      this.changeDetectorRef.detectChanges();
      return;
    }
  }

  private get currentScrollPosition(): number {
    return this.main.nativeElement.scrollTop;
  }

  private get maxScrollPosition(): number {
    return this.main.nativeElement.scrollHeight - this.main.nativeElement.clientHeight;
  }

  private getMaxMainContentHeight(): number {
    return this.main.nativeElement.clientHeight;
  }

  private get maxHeightForMain(): number {
    const maximumMainHeight = this.window.innerHeight - this.headerHeight - this.actionsHeight - this.verticalPaddings;

    return maximumMainHeight < this.minimumAllowedMainHeight ? this.minimumAllowedMainHeight : maximumMainHeight;
  }

  private get headerHeight(): number {
    return this.header?.nativeElement.offsetHeight || 0;
  }

  private get actionsHeight(): number {
    return this.actions?.nativeElement.offsetHeight || 0;
  }

  private get maxMainHeightUnderWindowThreshold(): boolean {
    return this.window.innerHeight - this.headerHeight - this.actionsHeight - this.maxMainContentHeight - this.verticalPaddings >= 0;
  }
}
