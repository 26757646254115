import { APP_INITIALIZER, NgModule } from '@angular/core';
import { LoginContextGuard } from '@core/authorization/guards/login-context.guard';
import { AuthorizationGuard } from '@core/authorization/guards/authorization.guard';
import { RefreshTokenValidityService } from '@core/authorization/services/refresh-token-validity.service';
import { Observable } from 'rxjs';

@NgModule({
  providers: [
    LoginContextGuard,
    AuthorizationGuard,
    {
      provide: APP_INITIALIZER,
      useFactory: checkTokenExpiryDateFactory,
      deps: [RefreshTokenValidityService],
      multi: true,
    },
  ],
})
export class AuthorizationModule {}

export function checkTokenExpiryDateFactory(refreshTokenValidityService: RefreshTokenValidityService): () => Observable<void> {
  return () => refreshTokenValidityService.checkTokenExpiryDateFactory();
}
