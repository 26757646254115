import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { catchError, finalize, tap } from 'rxjs/operators';
import { EMPTY, Observable } from 'rxjs';
import { SubscriptionsService } from '@pages/subscriptions/services/subscriptions.service';
import { patch } from '@ngxs/store/operators';
import { LoadEmptyK365Subscriptions } from '@pages/subscriptions/store/actions/empty-k365-subscriptions.action';
import { IEmptyK365Subscription } from '@pages/subscriptions/models/module-subscriptions/empty-k356-subscriptions.interface';

interface IEmptyK365SubscriptionsStateModel {
  emptyK365Subscriptions?: IEmptyK365Subscription[];
  loading: boolean;
  error: boolean;
}

@State<IEmptyK365SubscriptionsStateModel>({
  name: 'emptyK365Subscriptions',
  defaults: {
    loading: false,
    error: false,
  },
})
@Injectable()
export class EmptyK365SubscriptionsState {
  constructor(private subscriptionsService: SubscriptionsService) {}

  @Selector()
  static emptyK365Subscriptions(state: IEmptyK365SubscriptionsStateModel): IEmptyK365Subscription[] | undefined {
    return state.emptyK365Subscriptions;
  }

  @Selector()
  static loading(state: IEmptyK365SubscriptionsStateModel): boolean {
    return state.loading;
  }

  @Selector()
  static error(state: IEmptyK365SubscriptionsStateModel): boolean {
    return state.error;
  }

  @Action(LoadEmptyK365Subscriptions)
  loadEmptyK365Subscriptions(context: StateContext<IEmptyK365SubscriptionsStateModel>): Observable<IEmptyK365Subscription[]> {
    context.setState(patch<IEmptyK365SubscriptionsStateModel>({ loading: true }));

    return this.subscriptionsService.getEmptyK365Subscriptions().pipe(
      tap(emptyK365Subscriptions => {
        context.setState(
          patch<IEmptyK365SubscriptionsStateModel>({
            emptyK365Subscriptions,
            error: false,
          })
        );
      }),
      catchError(() => {
        context.setState(
          patch<IEmptyK365SubscriptionsStateModel>({
            emptyK365Subscriptions: undefined,
            error: true,
          })
        );
        return EMPTY;
      }),
      finalize(() => context.setState(patch<IEmptyK365SubscriptionsStateModel>({ loading: false })))
    );
  }
}
