import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { EMPTY, Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { LoadStates } from '@core/locations/store/actions/states.actions';
import { IState } from '@core/locations/models/state.interface';
import { StatesService } from '@core/locations/services/states.service';

export interface IStatesStateModel {
  loadersByCountryId: Record<number, boolean>;
  statesByCountryId: Record<number, IState[]>;
}

@State<IStatesStateModel>({
  name: 'locationStates',
  defaults: {
    loadersByCountryId: {},
    statesByCountryId: {},
  },
})
@Injectable()
export class StatesState {
  constructor(private statesService: StatesService) {}

  @Selector()
  static statesByCountryId(state: IStatesStateModel): Record<number, IState[] | undefined> {
    return state.statesByCountryId;
  }

  @Selector()
  static loadersByCountryId(state: IStatesStateModel): Record<number, boolean> {
    return state.loadersByCountryId;
  }

  @Selector()
  static areStatesLoading(state: IStatesStateModel): boolean {
    return Object.values(state.loadersByCountryId).some(loader => loader);
  }

  @Action(LoadStates)
  loadStates(context: StateContext<IStatesStateModel>, { countryId }: LoadStates): Observable<IState[]> {
    const { statesByCountryId, loadersByCountryId } = context.getState();
    if (statesByCountryId[countryId]) {
      return EMPTY;
    }

    context.patchState({
      loadersByCountryId: {
        ...loadersByCountryId,
        [countryId]: true,
      },
    });
    return this.statesService.getStates(countryId).pipe(
      tap(states => {
        context.patchState({
          statesByCountryId: {
            ...statesByCountryId,
            [countryId]: states,
          },
        });
      }),
      finalize(() => {
        context.patchState({
          loadersByCountryId: {
            ...loadersByCountryId,
            [countryId]: false,
          },
        });
      })
    );
  }
}
