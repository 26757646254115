import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { patch } from '@ngxs/store/operators';
import { catchError, finalize, tap } from 'rxjs/operators';
import { IProjectDetails } from '@pages/professional-services/models/details/project-details.interface';
import { ProfessionalServicesProjectDetailsService } from '@pages/professional-services/services/professional-services-project-details.service';
import { LoadProjectDetails } from '@pages/professional-services/store/actions/professional-services-project-details.actions';

interface IProfessionalServicesProjectDetailsStateModel {
  projectDetails?: IProjectDetails;
  loading: boolean;
  error: boolean;
}

@State<IProfessionalServicesProjectDetailsStateModel>({
  name: 'professionalServicesProjectDetails',
  defaults: {
    loading: false,
    error: false,
  },
})
@Injectable()
export class ProfessionalServicesProjectDetailsState {
  constructor(private professionalServicesProjectDetailsService: ProfessionalServicesProjectDetailsService) {}

  @Selector()
  static projectDetails(state: IProfessionalServicesProjectDetailsStateModel): IProjectDetails | undefined {
    return state.projectDetails;
  }

  @Selector()
  static loading(state: IProfessionalServicesProjectDetailsStateModel): boolean {
    return state.loading;
  }

  @Selector()
  static error(state: IProfessionalServicesProjectDetailsStateModel): boolean {
    return state.error;
  }

  @Action(LoadProjectDetails)
  loadProjectDetails(
    context: StateContext<IProfessionalServicesProjectDetailsStateModel>,
    { projectId }: LoadProjectDetails
  ): Observable<IProjectDetails> {
    context.setState(patch<IProfessionalServicesProjectDetailsStateModel>({ loading: true }));

    return this.professionalServicesProjectDetailsService.getProfessionalServicesProjectDetails(projectId).pipe(
      tap(projectDetails =>
        context.setState(
          patch<IProfessionalServicesProjectDetailsStateModel>({
            projectDetails,
            error: false,
          })
        )
      ),
      catchError(() => {
        context.setState(
          patch<IProfessionalServicesProjectDetailsStateModel>({
            projectDetails: undefined,
            error: true,
          })
        );
        return EMPTY;
      }),
      finalize(() => context.setState(patch<IProfessionalServicesProjectDetailsStateModel>({ loading: false })))
    );
  }
}
