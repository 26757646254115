import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { asNonNull } from '@shared/utils/as-non-null';

@Component({
  standalone: true,
  selector: 'itc-auth-container',
  templateUrl: './auth-container.component.html',
  styleUrls: ['./auth-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthContainerComponent implements AfterViewInit, OnDestroy {
  @Input() isDark = false;
  @ViewChild('contentContainer') contentContainer: ElementRef<HTMLDivElement>;
  @ViewChild('logoContainer') logoContainer: ElementRef<HTMLDivElement>;

  isFormLongerThanScreen: boolean;
  private resizeObserver: ResizeObserver;
  private window: Window;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.window = asNonNull(this.document.defaultView);
  }

  ngAfterViewInit(): void {
    this.registerResizeObserver();
  }

  ngOnDestroy(): void {
    this.resizeObserver.disconnect();
  }

  private registerResizeObserver(): void {
    this.resizeObserver = new ResizeObserver(() => {
      this.onResizeEvent();
    });
    this.resizeObserver.observe(this.document.body);
  }

  private onResizeEvent(): void {
    this.calculateFormFitsScreen();
    this.changeDetectorRef.detectChanges();
  }

  private calculateFormFitsScreen(): void {
    this.isFormLongerThanScreen = this.contentHeight < this.window.innerHeight - this.logoHeight * 2;
  }

  private get contentHeight(): number {
    return (this.contentContainer.nativeElement.firstChild as HTMLElement).offsetHeight;
  }

  private get logoHeight(): number {
    return (this.logoContainer.nativeElement.firstChild as HTMLElement)?.offsetHeight;
  }
}
