import { Action, Selector, State, StateContext } from '@ngxs/store';
import { inject, Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { OrganizationMappingsService } from '@pages/organization-mapping/services/organization-mappings.service';
import { IOrganizationMappings } from '@pages/organization-mapping/models/organization-mappings.interface';
import { LoadOrganizationMappings } from '@pages/organization-mapping/store/actions/organization-mappings.actions';
import { HttpErrorResponse } from '@angular/common/http';

interface IOrganizationMappingsStateModel {
  organizationMappings?: IOrganizationMappings;
  loading: boolean;
  error: boolean;
}

@State<IOrganizationMappingsStateModel>({
  name: 'organizationMappings',
  defaults: {
    loading: false,
    error: false,
  },
})
@Injectable()
export class OrganizationMappingsState {
  private readonly organizationMappingsService = inject(OrganizationMappingsService);

  @Selector()
  static organizationMappings(state: IOrganizationMappingsStateModel): IOrganizationMappings | undefined {
    return state.organizationMappings;
  }

  @Selector()
  static loading(state: IOrganizationMappingsStateModel): boolean {
    return state.loading;
  }

  @Selector()
  static error(state: IOrganizationMappingsStateModel): boolean {
    return state.error;
  }

  @Action(LoadOrganizationMappings)
  loadOrganizationMappings(
    context: StateContext<IOrganizationMappingsStateModel>,
    { organizationMappingRequest }: LoadOrganizationMappings
  ): Observable<IOrganizationMappings> {
    context.patchState({ loading: true });
    return this.organizationMappingsService.getOrganizationMappings(organizationMappingRequest).pipe(
      tap(organizationMappings => {
        context.patchState({ organizationMappings });
      }),
      catchError((errorResponse: HttpErrorResponse) => {
        context.patchState({ error: true });
        return throwError(() => errorResponse);
      }),
      finalize(() => {
        context.patchState({ loading: false });
      })
    );
  }
}
