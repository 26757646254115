<div class="manager">
  <div class="manager__header">
    <itc-avatar [firstName]="manager.firstName" [lastName]="manager.lastName" [photoUrl]="manager.photoUrl" [isSmall]="false" />
    <div class="manager__header-text">
      <div class="manager__header-fullname">{{ manager.firstName }} {{ manager.lastName }}</div>
      <div class="manager__header-job-title">{{ jobTitle }}</div>
    </div>
  </div>
  <div class="manager__info">
    @if (manager.email) {
      <div class="manager__info-section">
        <img class="manager__info-icon" ngSrc="assets/icons/email.svg" height="13" width="13" />
        <a
          tooltipPosition="right-center"
          class="manager__info-text manager__info-text--email"
          [itcTooltip]="manager.email"
          href="mailto:{{ manager.email }}"
        >
          {{ manager.email }}
        </a>
      </div>
    }

    @if (managerPhone) {
      <div class="manager__info-section">
        <img class="manager__info-icon" ngSrc="assets/icons/organization-managers/phone.svg" height="13" width="13" />
        <div class="manager__info-text">{{ managerPhone }}</div>
      </div>
    }
  </div>
</div>
