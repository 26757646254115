import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateTestingModule } from 'ngx-translate-testing';

@Component({
  selector: 'itc-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true,
    },
  ],
  imports: [TranslateTestingModule],
})
export class CheckboxComponent implements ControlValueAccessor, OnChanges {
  @Input() label = '';
  @Input({ required: true }) elementId: string;
  @Input() disabled = false;
  @Input() checked = false;
  @Input() testId: string;

  @Output() change: EventEmitter<boolean> = new EventEmitter();

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.checked) {
      this.onChange(changes.checked.currentValue);
    }
  }

  onChange(_value: unknown): void {}

  onTouch(): void {}

  registerOnChange(fn: (value: unknown) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  writeValue(value: boolean): void {
    this.checked = value;
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
    this.changeDetectorRef.markForCheck();
  }

  @HostListener('click')
  toggle(): void {
    if (this.disabled) {
      return;
    }

    this.checked = !this.checked;
    this.onChange(this.checked);
    this.change.emit(this.checked);
  }
}
