import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';

@Injectable({ providedIn: 'root' })
export class AbsorbApiService {
  private readonly getSsoLoginUriUrl = 'api/v1/absorb/sso-login-uri';

  constructor(private httpClient: HttpClient) {}

  getSsoLoginUri(): Observable<string> {
    return this.httpClient.get(environment.portalApiUrl + this.getSsoLoginUriUrl, { responseType: 'text' });
  }
}
