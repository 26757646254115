<div class="menu__container" (click)="toggleMenuVisibility()">
  <div
    tooltipPosition="bottom-center"
    [ngClass]="{ menu__label: true, 'menu__label-active': isMenuVisible }"
    [itcTooltip]="tooltipText"
    [disableTooltip]="isMenuVisible || tooltipDisabled"
  >
    <ng-content />
  </div>
  @if (isMenuVisible) {
    <div class="menu" role="menu" [attr.aria-label]="ariaLabel">
      <ng-container *ngTemplateOutlet="menuTemplateRef" />
    </div>
  }
</div>
