import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { FormTarget } from '@design/open-sso-link/models/form-target';

@Component({
  selector: 'itc-redirect-form',
  templateUrl: './redirect-form.component.html',
  styleUrls: ['./redirect-form.component.scss'],
})
export class RedirectFormComponent {
  @Input() target: FormTarget = '_blank';

  @ViewChild('redirectForm') redirectForm: ElementRef;
  @ViewChild('SAMLResponseInput') SAMLResponseInput: ElementRef;

  submit(action: string, token: string): void {
    const form = this.redirectForm.nativeElement;
    const input = this.SAMLResponseInput.nativeElement;

    form.action = action;
    input.value = token;
    form.submit();
  }
}
