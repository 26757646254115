export class LoadSynchronizationStatus {
  static readonly type = '[Organization Mapping Synchronization] Load Synchronization Status';
}

export class CancelLoadingSynchronizationStatus {
  static readonly type = '[Organization Mapping Synchronization] Cancel Loading Synchronization Status';
}

export class TriggerSynchronization {
  static readonly type = '[Organization Mapping Synchronization] Trigger Synchronization';
}
