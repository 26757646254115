import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NgOptimizedImage } from '@angular/common';
import { Subject, timer } from 'rxjs';
import { mergeMap, takeUntil } from 'rxjs/operators';
import { ClipboardModule, ClipboardService } from 'ngx-clipboard';

@Component({
  selector: 'itc-copy-button',
  templateUrl: './copy-button.component.html',
  styleUrls: ['./copy-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ClipboardModule, NgOptimizedImage],
})
export class CopyButtonComponent implements OnInit, OnDestroy {
  @Input() valueToCopy: string | number | null;

  @Output() copied = new EventEmitter<void>();

  isCopied = false;

  private readonly timeoutAfterCopyOperation = 1500;
  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private clipboardService: ClipboardService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.listenCopyEvent();
  }

  copyToClipboard(): void {
    if (this.valueToCopy) {
      this.clipboardService.copy(this.valueToCopy.toString());
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private listenCopyEvent(): void {
    this.clipboardService.copyResponse$
      .pipe(
        takeUntil(this.unsubscribe$),
        mergeMap(() => {
          this.isCopied = true;
          this.copied.emit();
          return timer(this.timeoutAfterCopyOperation);
        })
      )
      .subscribe(() => {
        this.isCopied = false;
        this.changeDetectorRef.markForCheck();
      });
  }
}
