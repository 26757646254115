import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { IValidationErrors } from '@design/input/models/validation-errors.interface';

export class InputValidators {
  static required(): (control: AbstractControl) => ValidationErrors {
    return (control: AbstractControl) => {
      const errors: IValidationErrors = {};
      if (Validators.required(control)) {
        errors.required = {
          errorTranslationKey: 'input-error.required',
        };
      }
      return errors;
    };
  }

  static numbersOnly(): (control: AbstractControl) => ValidationErrors {
    return (control: AbstractControl) => {
      const numbersOnlyRegExp = /^[0-9]*$/;
      const isValid = numbersOnlyRegExp.test(control.value);
      const errors: IValidationErrors = {};

      if (!isValid) {
        errors.numbersOnly = {
          errorTranslationKey: 'input-error.numbers-only',
        };
      }
      return errors;
    };
  }

  static maxLength(maxLength: number): (control: AbstractControl) => ValidationErrors {
    return (control: AbstractControl) => {
      const errors: IValidationErrors = {};
      if (Validators.maxLength(maxLength)(control)) {
        errors.maxLength = {
          errorTranslationKey: 'input-error.max-length',
          params: { maxLength },
        };
      }
      return errors;
    };
  }

  static minLength(minLength: number, errorCode?: string): (control: AbstractControl) => ValidationErrors {
    return (control: AbstractControl) => {
      const errors: IValidationErrors = {};
      if (Validators.minLength(minLength)(control)) {
        errors.minLength = {
          errorTranslationKey: errorCode || 'input-error.min-length',
          params: { minLength },
        };
      }
      return errors;
    };
  }

  static max(max: number, errorCode?: string): (control: AbstractControl) => ValidationErrors {
    return (control: AbstractControl) => {
      const errors: IValidationErrors = {};
      if (Validators.max(max)(control)) {
        errors.max = {
          errorTranslationKey: errorCode || 'input-error.max',
          params: { max },
        };
      }
      return errors;
    };
  }

  static min(min: number, errorCode?: string): (control: AbstractControl) => ValidationErrors {
    return (control: AbstractControl) => {
      const errors: IValidationErrors = {};
      if (Validators.min(min)(control)) {
        errors.min = {
          errorTranslationKey: errorCode || 'input-error.min',
          params: { min },
        };
      }
      return errors;
    };
  }

  static range(min: number, max: number, errorCode?: string): (control: AbstractControl) => ValidationErrors {
    return (control: AbstractControl) => {
      const errors: IValidationErrors = {};
      if (Validators.min(min)(control) || Validators.max(max)(control)) {
        errors.range = {
          errorTranslationKey: errorCode || 'input-error.range',
          params: { min, max },
        };
      }
      return errors;
    };
  }

  static email(): (control: AbstractControl) => ValidationErrors {
    return (control: AbstractControl) => {
      const errors: IValidationErrors = {};
      if (Validators.email(control)) {
        errors.email = {
          errorTranslationKey: 'input-error.invalid-email',
        };
      }
      return errors;
    };
  }

  static url(control: AbstractControl): ValidationErrors | null {
    const acceptableProtocols = ['http://', 'https://', 'ftp://'];
    const { value } = control;
    if (!value) {
      return null;
    }
    const isValidUrl = acceptableProtocols.some(protocol => value.startsWith(protocol));
    const errors: IValidationErrors = {};
    if (!isValidUrl) {
      errors.invalidUrl = {
        errorTranslationKey: 'input-error.invalid-url',
      };
    }
    return errors;
  }

  static userName(errorMessageKey?: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: unknown } | null => {
      const errors: IValidationErrors = {};
      const userNameRegExp = /^[a-zA-Z0-9.\-_@]+$/;
      if (Validators.email(control) && !userNameRegExp.test(control.value)) {
        errors.userName = {
          errorTranslationKey: errorMessageKey ?? 'input-error.user-name',
        };
      }
      return errors;
    };
  }

  static userFirstLastName(errorMessageKey?: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: unknown } | null => {
      const errors: IValidationErrors = {};
      const firstLastNameRegExp = new RegExp("^[\\p{L}0-9\\s_'-]+$", 'u');
      if (!firstLastNameRegExp.test(control.value)) {
        errors.userFullName = {
          errorTranslationKey: errorMessageKey ?? 'input-error.user-full-name',
        };
      }
      return errors;
    };
  }

  static notEmptyOrWhiteSpaces(control: AbstractControl): ValidationErrors {
    const errors: IValidationErrors = {};
    if (!control.value?.trim()) {
      errors.customRequired = {
        errorTranslationKey: 'input-error.required',
      };
    }
    return errors;
  }
}
