import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { TooltipModule } from '@design/tooltip/tooltip.module';

@Component({
  standalone: true,
  selector: 'itc-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TooltipModule],
})
export class EmailComponent implements AfterViewInit {
  @Input({ required: true }) email: string;

  @ViewChild('emailUsernameElement') emailUsernameElement: ElementRef<HTMLDivElement>;

  isTruncated?: boolean;

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this.isTruncated = this.emailUsernameElement.nativeElement.offsetWidth < this.emailUsernameElement.nativeElement.scrollWidth;
    this.changeDetectorRef.detectChanges();
  }

  get emailUsername(): string {
    return this.emailParts[0];
  }

  get emailDomain(): string {
    return this.emailParts[1];
  }

  private get emailParts(): string[] {
    return this.email.split('@');
  }
}
