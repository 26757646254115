import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TutorialCategory } from '@layout/tutorials/models/tutorial-category.enum';
import { TutorialsFacade } from '@layout/tutorials/facades/tutorials.facade';
import { tutorialCategories } from '@layout/tutorials/models/tutorial-categories';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'itc-tutorials-categories',
  templateUrl: './tutorials-categories.component.html',
  styleUrls: ['./tutorials-categories.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TutorialsCategoriesComponent implements OnInit, OnDestroy {
  @Input() initialCategory?: TutorialCategory;
  readonly categories = tutorialCategories;
  currentCategory = TutorialCategory.All;
  private readonly unsubscribe$ = new Subject<void>();

  constructor(private tutorialsFacade: TutorialsFacade) {}

  ngOnInit(): void {
    this.initializeCategory();
  }

  changeCategory(category: TutorialCategory): void {
    this.currentCategory = category;
    this.tutorialsFacade.selectCategory(category);
  }

  isActiveCategory(category: TutorialCategory): boolean {
    return this.currentCategory === category;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private initializeCategory(): void {
    if (this.initialCategory) {
      this.currentCategory = this.initialCategory;
      return;
    }

    this.tutorialsFacade.filter$.pipe(first(), takeUntil(this.unsubscribe$)).subscribe(filter => {
      if (filter.category) {
        this.currentCategory = filter.category;
      }
    });
  }
}
