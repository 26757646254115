import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { IModule } from '@layout/launcher/models/module.interface';
import { DialogService } from '@design/dialog/services/dialog.service';
import { InstancePickerDialogComponent } from '@layout/tenant-picker-dialog/components/instance-picker-dialog/instance-picker-dialog.component';
import { TooltipModule } from '@design/tooltip/tooltip.module';
import { TranslateModule } from '@ngx-translate/core';
import { NgOptimizedImage } from '@angular/common';

@Component({
  standalone: true,
  selector: 'itc-launcher-item',
  templateUrl: './launcher-item.component.html',
  styleUrls: ['./launcher-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TooltipModule, TranslateModule, NgOptimizedImage],
})
export class LauncherItemComponent {
  @Input({ required: true }) module: IModule;
  @Input() isTooltipRightAligned = false;
  @Output() openedModule = new EventEmitter<void>();

  constructor(private dialogService: DialogService) {}

  get isMultipleTenants(): boolean {
    return this.module.instances.length > 1;
  }

  onLauncherItemClick(): void {
    if (this.isMultipleTenants) {
      this.openTenantPickerDialog(this.module);
      return;
    }

    this.openModuleLandingPage(this.module.instances[0].landingPageUrl);
  }

  isFullTextDisplayed(element: HTMLSpanElement): boolean {
    return element.clientHeight >= element.scrollHeight;
  }

  private openTenantPickerDialog(module: IModule): void {
    this.dialogService.openDialogWithData<IModule>(InstancePickerDialogComponent, {
      data: { module },
      size: 'sm',
    });
  }

  private openModuleLandingPage(moduleUrl: string): void {
    window.open(moduleUrl);
    this.openedModule.emit();
  }
}
