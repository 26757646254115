import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { Actions, ofActionDispatched, Store } from '@ngxs/store';
import {
  DeleteAllNotifications,
  DeleteNotification,
  LoadZendeskSsoRequestInfo,
  LoadZendeskSsoRequestInfoSuccess,
  MarkAllNotificationsAsViewed,
  StartCheckingNotifications,
  StopCheckingNotifications,
  ToggleNotificationViewability,
} from '@layout/notifications/store/actions/notifications.actions';
import { NotificationsState } from '@layout/notifications/store/state/notifications.state';
import { IZendeskSsoRequestInfo } from '@core/sso/models/zendesk/zendesk-sso-request-info.interface';
import { NotificationSource } from '@layout/notifications/models/notification-source.enum';

@Injectable({ providedIn: 'root' })
export class NotificationsFacade {
  readonly notifications$ = this.store.select(NotificationsState.notifications);
  readonly unreadNotificationsCount$ = this.store.select(NotificationsState.unreadNotificationsCount);
  readonly loading$ = this.store.select(NotificationsState.loading);

  constructor(
    private store: Store,
    private actions$: Actions
  ) {}

  startCheckingNotifications(): void {
    this.store.dispatch(new StartCheckingNotifications());
  }

  stopCheckingNotifications(): void {
    this.store.dispatch(new StopCheckingNotifications());
  }

  loadZendeskSsoRequestInfo(ticketId: string): void {
    this.store.dispatch(new LoadZendeskSsoRequestInfo(ticketId));
  }

  markAllNotificationsAsViewed(): void {
    this.store.dispatch(new MarkAllNotificationsAsViewed());
  }

  toggleNotificationViewability(notificationId: string, source: NotificationSource, viewed: boolean): void {
    this.store.dispatch(new ToggleNotificationViewability(notificationId, source, viewed));
  }

  deleteAllNotifications(): void {
    this.store.dispatch(new DeleteAllNotifications());
  }

  deleteNotification(notificationId: string, source: NotificationSource): void {
    this.store.dispatch(new DeleteNotification(notificationId, source));
  }

  get onLoadZendeskSsoRequestInfoSuccess$(): Observable<IZendeskSsoRequestInfo> {
    return this.actions$
      .pipe(ofActionDispatched(LoadZendeskSsoRequestInfoSuccess))
      .pipe(map(({ zendeskSsoRequestInfo }) => zendeskSsoRequestInfo));
  }
}
