import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { WhatsNewState } from '@layout/whats-new/store/states/whats-new.state';
import { LoadWhatsNewUrl } from '@layout/whats-new/store/actions/whats-new.actions';

@Injectable({
  providedIn: 'root',
})
export class WhatsNewFacade {
  readonly whatsNewPageUrl$ = this.store.select(WhatsNewState.getWhatsNewPageUrl);
  readonly loading$ = this.store.select(WhatsNewState.loading);

  constructor(private store: Store) {}

  loadWhatsNewUrl(): void {
    this.store.dispatch(new LoadWhatsNewUrl());
  }
}
