<div class="tutorials-filter">
  <itc-smart-filter
    class="tutorials-filter__smart-filter"
    [config]="config"
    [model]="model"
    (updateFilter)="onUpdateSearch($event)"
    (clearFilter)="onClearSearch()"
  />
  <div class="tutorials-filter__labels">
    <span class="tutorials-filter__of-label">
      {{
        'tutorials.tutorials-filter.of' | translate: { foundCount: foundTutorialsCount$ | async, totalCount: totalTutorialsCount$ | async }
      }}
    </span>
    <span class="tutorials-filter__result-label">{{ 'tutorials.tutorials-filter.results' | translate }}</span>
  </div>
</div>
