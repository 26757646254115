import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TagColorName } from '@design/tag/models/tag-color-name.enum';
import { TutorialsFacade } from '@layout/tutorials/facades/tutorials.facade';
import { tutorialCategoryNameTranslationKeys } from '@layout/tutorials/models/tutorial-category-name-translation-keys';
import { tutorialCategoryTagColorNames } from '@layout/tutorials/models/tutorial-category-tag-color-names';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { filterIsNotUndefined } from '@shared/rxjs-operators/filter-is-not-undefined';

@Component({
  selector: 'itc-tutorial-preview',
  templateUrl: './tutorial-preview.component.html',
  styleUrls: ['./tutorial-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TutorialPreviewComponent implements OnInit, OnDestroy {
  @Input() autoplay: boolean;
  selectedTutorial$ = this.tutorialsFacade.selectedTutorial$;
  videoUrl: string;

  tagColorName: TagColorName;
  categoryTranslationKey: string;
  private readonly unsubscribe$ = new Subject<void>();

  constructor(private tutorialsFacade: TutorialsFacade) {}

  openVideoLink(videoUrl: string): void {
    window.open(videoUrl);
  }

  ngOnInit(): void {
    this.tutorialsFacade.selectedTutorial$.pipe(filterIsNotUndefined(), takeUntil(this.unsubscribe$)).subscribe(tutorial => {
      this.videoUrl = this.autoplay ? tutorial.videoUrl + '&autoplay=1&muted=1' : tutorial.videoUrl;
      this.tagColorName = tutorialCategoryTagColorNames[tutorial.category];
      this.categoryTranslationKey = `tutorials.tutorials-categories.${tutorialCategoryNameTranslationKeys[tutorial.category]}`;
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
