import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { AuthorizationService } from '@core/authorization/services/authorization.service';

export const canAccessKaseyaStore: CanActivateFn = (route: ActivatedRouteSnapshot) => {
  const router = inject(Router);
  const { targetPage } = route.queryParams;

  if (!targetPage) {
    return router.createUrlTree(['login']);
  }

  if (inject(AuthorizationService).isAuthorized()) {
    return true;
  }

  return router.createUrlTree(['login/store'], { queryParams: { targetPage } });
};
