<itc-menu
  [tooltipText]="'header.tooltips.my-account' | translate"
  [tooltipDisabled]="false"
  [ariaLabel]="'header.tooltips.my-account' | translate"
  [menuTemplateRef]="menuTemplateRef"
>
  <div class="profile__labels">
    <span class="profile__labels-icon"></span>
    <span class="profile__labels-full-name">{{ userFullName }}</span>
  </div>
  <ng-template #menu>
    <div class="profile__items">
      <div class="profile__header">
        <span class="profile__labels-icon profile__labels-icon-blue"></span>
        <div class="profile__labels-menu">
          <span
            #userFullNameElement
            class="profile__full-name"
            tooltipPosition="bottom-right"
            [itcTooltip]="userFullName"
            [disableTooltip]="!isTruncated(userFullNameElement)"
          >
            {{ userFullName }}
          </span>
          <span
            #companyNameElement
            class="profile__company-name"
            tooltipPosition="bottom-right"
            [itcTooltip]="organizationName"
            [disableTooltip]="!isTruncated(companyNameElement)"
          >
            {{ organizationName }}
          </span>
          <itc-email [email]="email" />
        </div>
      </div>
      <div class="profile__item" (click)="navigateTo('my-settings')">
        <div class="profile__item-text">{{ 'profile-menu.my-settings' | translate }}</div>
      </div>
      <div class="profile__item" (click)="navigateTo('logout')">
        <div class="profile__item-text">{{ 'profile-menu.log-out' | translate }}</div>
      </div>
    </div>
  </ng-template>
</itc-menu>
