import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { IUser } from '@pages/first-time-onboarding/models/user.interface';

@Injectable({
  providedIn: 'root',
})
export class InviteUserService {
  private readonly httpClient = inject(HttpClient);

  doesUserExist(userEmail: string): Observable<boolean> {
    const url = 'api/v1/users/existence';

    return this.httpClient.post<boolean>(environment.portalApiUrl + url, { email: userEmail });
  }

  inviteUser(user: IUser): Observable<void> {
    const url = 'api/v1/onboarding/users';

    return this.httpClient.post<void>(environment.portalApiUrl + url, user);
  }
}
