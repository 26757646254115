import {
  ComponentRef,
  Directive,
  ElementRef,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  Renderer2,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { TooltipComponent } from '@design/tooltip/components/tooltip/tooltip.component';
import { asNonUndefined } from '@shared/utils/as-non-undefined';
import { DOCUMENT } from '@angular/common';
import { asNonNull } from '@shared/utils/as-non-null';
import { IElementPosition } from '@design/tooltip/models/element-position.interface';
import { TooltipPosition } from '@design/tooltip/models/tooltip-position';

type position = 'top' | 'right' | 'bottom' | 'left';

@Directive({
  selector: '[itcTooltip]',
})
export class TooltipDirective implements OnDestroy {
  @Input() itcTooltip?: string;
  @Input() tooltipPosition: TooltipPosition = 'bottom-center';
  @Input() tooltipCustomClass: string;
  @Input() disableTooltip = false;
  @Input() hideOnClick = true;
  @Input() contentTemplateRef: TemplateRef<unknown> | null = null;
  @Input() hasLocalContainer = false;
  @Input() contentTemplateRefValues?: Record<string, unknown>;
  @Input() showTooltipOnHover = false;

  private window: Window | null;
  private componentRef?: ComponentRef<TooltipComponent>;
  private positionToRenderMethod = {
    'top-left': (): void => this.renderAtTopLeft(),
    'top-center': (): void => this.renderAtTopCenter(),
    'top-right': (): void => this.renderAtTopRight(),
    'right-top': (): void => this.renderAtRightTop(),
    'right-center': (): void => this.renderAtRightCenter(),
    'right-bottom': (): void => this.renderAtRightBottom(),
    'bottom-right': (): void => this.renderAtBottomRight(),
    'bottom-center': (): void => this.renderAtBottomCenter(),
    'bottom-left': (): void => this.renderAtBottomLeft(),
    'left-bottom': (): void => this.renderAtLeftBottom(),
    'left-center': (): void => this.renderAtLeftCenter(),
    'left-top': (): void => this.renderAtLeftTop(),
  };
  private positionToPadding = {
    top: '0 0 4px 0',
    right: '0 0 0 4px',
    bottom: '4px 0 0 0',
    left: '0 4px 0 0',
  };
  private tooltipContentElement?: TemplateRef<unknown> | null;
  private tooltipAutoPosition: TooltipPosition;
  private excludedTooltipPositions: TooltipPosition[] = [];

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private elementRef: ElementRef,
    private viewContainerRef: ViewContainerRef,
    private renderer: Renderer2
  ) {
    this.window = this.document.defaultView;
  }

  @HostListener('click')
  onMouseClick(): void {
    if (this.hideOnClick) {
      this.destroy();
    }
  }

  @HostListener('mouseenter')
  onMouseEnter(): void {
    if (this.componentRef || this.disableTooltip) {
      return;
    }
    this.createTooltip(this.tooltipPosition);
  }

  @HostListener('mouseleave', ['$event'])
  onMouseLeave(event: MouseEvent): void {
    if (!this.showTooltipOnHover) {
      this.destroy();
      return;
    }
    const isTooltipHovered = this.isMouseOverTooltip(event);
    const isHostElementHovered = this.isMouseOverHost(event);
    if (!isTooltipHovered && !isHostElementHovered) {
      this.destroy();
    }
  }

  ngOnDestroy(): void {
    this.destroy();
  }

  private createTooltip(position: TooltipPosition): void {
    this.componentRef = this.viewContainerRef.createComponent(TooltipComponent);

    if (!this.hasLocalContainer) {
      this.document.body.appendChild(this.componentRef.location.nativeElement);
    }

    if (this.contentTemplateRef) {
      this.setContentTemplateRef();
      this.tooltipContentElement = this.componentRef.location.nativeElement;
      if (this.tooltipContentElement && this.showTooltipOnHover) {
        this.attachListeners();
      }
    }
    if (this.itcTooltip) {
      this.setTooltipText();
    }
    this.setTooltipText();
    this.positionToRenderMethod[position]();
    this.setTooltipPadding();
    this.tooltipInstance.customClass = this.tooltipCustomClass;

    if (this.hasLocalContainer) {
      const element = this.componentRef.location.nativeElement as HTMLElement;
      element.style.position = 'fixed';
      element.style.zIndex = '1000';
    }

    this.componentRef.changeDetectorRef.detectChanges();

    if (!this.isTooltipInViewport()) {
      this.excludedTooltipPositions.push(position);
      this.rerenderTooltip();
    }
  }

  private setContentTemplateRef(): void {
    const componentInstance = asNonUndefined(this.componentRef).instance;
    componentInstance.contentTemplateRef = this.contentTemplateRef;
    componentInstance.contentTemplateRefValues = this.contentTemplateRefValues;
  }

  private setTooltipText(): void {
    asNonUndefined(this.componentRef).instance.text = asNonUndefined(this.itcTooltip);
  }

  private setTooltipPadding(): void {
    this.tooltipInstance.padding = this.positionToPadding[this.sideOfElement];
  }

  private renderAtTopLeft(): void {
    this.tooltipInstance.left = this.elementPosition.left;
    this.stickToTopBorder();
  }

  private renderAtTopCenter(): void {
    this.centerHorizontally();
    this.stickToTopBorder();
  }

  private renderAtTopRight(): void {
    this.tooltipInstance.right = this.windowInnerWidth - this.elementPosition.right;
    this.stickToTopBorder();
  }

  private renderAtRightTop(): void {
    this.stickToRightBorder();
    this.tooltipInstance.top = this.elementPosition.top;
  }

  private renderAtRightCenter(): void {
    this.stickToRightBorder();
    this.centerVertically();
  }

  private renderAtRightBottom(): void {
    this.stickToRightBorder();
    this.tooltipInstance.bottom = this.windowInnerHeight - this.elementPosition.bottom;
  }

  private renderAtBottomLeft(): void {
    this.tooltipInstance.left = this.elementPosition.left;
    this.stickToBottomBorder();
  }

  private renderAtBottomCenter(): void {
    this.centerHorizontally();
    this.stickToBottomBorder();
  }

  private renderAtBottomRight(): void {
    this.tooltipInstance.right = this.windowInnerWidth - this.elementPosition.right;
    this.stickToBottomBorder();
  }

  private renderAtLeftTop(): void {
    this.stickToLeftBorder();
    this.tooltipInstance.top = this.elementPosition.top;
  }

  private renderAtLeftCenter(): void {
    this.stickToLeftBorder();
    this.centerVertically();
  }

  private renderAtLeftBottom(): void {
    this.stickToLeftBorder();
    this.tooltipInstance.bottom = this.windowInnerHeight - this.elementPosition.bottom;
  }

  private stickToLeftBorder(): void {
    this.tooltipInstance.right = this.windowInnerWidth - this.elementPosition.left;
  }

  private stickToRightBorder(): void {
    this.tooltipInstance.left = this.elementPosition.right;
  }

  private stickToTopBorder(): void {
    this.tooltipInstance.bottom = this.windowInnerHeight - this.elementPosition.top;
  }

  private stickToBottomBorder(): void {
    this.tooltipInstance.top = this.elementPosition.bottom;
  }

  private centerVertically(): void {
    const { bottom, top } = this.elementPosition;
    this.tooltipInstance.top = bottom - (bottom - top) / 2;
    this.tooltipInstance.transform = 'translateY(-50%)';
  }

  private centerHorizontally(): void {
    const { left, right } = this.elementPosition;
    this.tooltipInstance.left = right - (right - left) / 2;
    this.tooltipInstance.transform = 'translateX(-50%)';
  }

  private get elementPosition(): IElementPosition {
    return this.elementRef.nativeElement.getBoundingClientRect();
  }

  private destroy(): void {
    if (!this.componentRef) {
      return;
    }
    this.componentRef.destroy();
    this.componentRef = undefined;
    if (!this.tooltipContentElement) {
      return;
    }
    this.deatachListeners();
    this.tooltipContentElement = undefined;
  }

  private get tooltipInstance(): TooltipComponent {
    return asNonUndefined(this.componentRef).instance;
  }

  private get windowInnerHeight(): number {
    return asNonNull(this.window).innerHeight;
  }

  private get windowInnerWidth(): number {
    return asNonNull(this.window).innerWidth;
  }

  private get sideOfElement(): position {
    const tooltipPosition = this.tooltipAutoPosition || this.tooltipPosition;

    return tooltipPosition.split('-')[0] as position;
  }

  private isMouseOverTooltip(event: MouseEvent): boolean {
    if (!this.componentRef) {
      return false;
    }
    const tooltipElement = this.componentRef.location.nativeElement;
    return tooltipElement.contains(event.relatedTarget as Node);
  }

  private isMouseOverHost(event: MouseEvent): boolean {
    const hostElement = this.elementRef.nativeElement;
    return hostElement.contains(event.relatedTarget as Node);
  }

  private onMouseTooltipLeave(event: MouseEvent): void {
    if (!this.isMouseOverHost(event)) {
      this.destroy();
    }
  }

  private attachListeners(): void {
    this.renderer.listen(this.tooltipContentElement, 'mouseleave', (event: MouseEvent) => this.onMouseTooltipLeave(event));
    if (this.hideOnClick) {
      this.renderer.listen(this.tooltipContentElement, 'click', () => this.destroy());
    }
  }

  private deatachListeners(): void {
    this.renderer.listen(this.tooltipContentElement, 'mouseleave', () => {});
    if (this.hideOnClick) {
      this.renderer.listen(this.tooltipContentElement, 'click', () => {});
    }
  }
  private rerenderTooltip(): void {
    if (this.excludedTooltipPositions.length === this.tooltipPositions.length) {
      this.excludedTooltipPositions = [];
      this.destroy();
      return;
    }

    if (!this.tooltipAutoPosition || this.excludedTooltipPositions.includes(this.tooltipAutoPosition)) {
      this.defineTooltipAutoPosition();
    }

    this.destroy();
    this.createTooltip(this.tooltipAutoPosition);
  }

  private isTooltipInViewport(): boolean {
    const { top, bottom, left, right } = this.componentRef!.instance.tooltipContentElement.nativeElement.getBoundingClientRect();

    return top >= 0 && left >= 0 && bottom <= this.windowInnerHeight && right <= this.windowInnerWidth;
  }

  private defineTooltipAutoPosition(): void {
    const possiblePositions = this.tooltipPositions.filter(position => !this.excludedTooltipPositions.includes(position));
    const possibleIndex = Math.floor(Math.random() * possiblePositions.length);

    this.tooltipAutoPosition = possiblePositions[possibleIndex];
  }

  private get tooltipPositions(): TooltipPosition[] {
    return Object.keys(this.positionToRenderMethod) as TooltipPosition[];
  }
}
