import { ChangeDetectionStrategy, Component, ElementRef, TemplateRef, ViewChild } from '@angular/core';

@Component({
  selector: 'itc-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TooltipComponent {
  @ViewChild('content') tooltipContentElement: ElementRef<HTMLDivElement>;
  text?: string;
  contentTemplateRef: TemplateRef<unknown> | null = null;
  contentTemplateRefValues: Record<string, unknown> | undefined;
  left: number;
  right: number;
  bottom: number;
  top: number;
  transform: string;
  padding: string;
  customClass: string;
}
