import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { SuggestedTutorialsHeaderComponent } from '@layout/tutorials/components/suggested-tutorials-header/suggested-tutorials-header.component';
import { TutorialsFilterComponent } from '@layout/tutorials/components/tutorials-filter/tutorials-filter.component';
import { TutorialsFacade } from '@layout/tutorials/facades/tutorials.facade';
import { TutorialCategory } from '@layout/tutorials/models/tutorial-category.enum';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { filterIsNotUndefined } from '@shared/rxjs-operators/filter-is-not-undefined';

@Component({
  selector: 'itc-tutorials-dialog',
  templateUrl: './tutorials-dialog.component.html',
  styleUrls: ['./tutorials-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TutorialsDialogComponent implements AfterViewInit, OnDestroy {
  @Input() initialCategory?: TutorialCategory;
  @Input() autoplay = false;
  @ViewChild(TutorialsFilterComponent, { read: ElementRef }) tutorialsFilter: ElementRef<HTMLDivElement>;
  @ViewChild(SuggestedTutorialsHeaderComponent, { read: ElementRef }) suggestedTutorialsHeader: ElementRef<HTMLDivElement>;
  @ViewChild('header') header: ElementRef<HTMLDivElement>;

  selectedTutorial$ = this.tutorialsFacade.selectedTutorial$;
  tutorialsListHeight: string;
  tutorialPreviewHeight: string;
  suggestedTutorialsHeight: string;
  private readonly unsubscribe$ = new Subject<void>();
  private readonly defaultDialogHeight = 770;

  constructor(
    private ngbActiveModal: NgbActiveModal,
    private tutorialsFacade: TutorialsFacade,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.calculateElementHeights();
  }

  ngAfterViewInit(): void {
    this.calculateElementHeights();
  }

  dismiss(): void {
    this.tutorialsFacade.resetState();
    this.ngbActiveModal.dismiss();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private calculateElementHeights(): void {
    this.tutorialsListHeight = `calc(${this.maxDialogHeight} - ${this.headerHeight} - ${this.tutorialsFilterHeight})`;
    this.selectedTutorial$.pipe(filterIsNotUndefined(), first(), takeUntil(this.unsubscribe$)).subscribe(() => {
      this.changeDetectorRef.detectChanges();
      this.recalculateElementHeights();
      this.changeDetectorRef.detectChanges();
    });
  }

  private recalculateElementHeights(): void {
    this.tutorialPreviewHeight = `calc(${this.maxDialogHeight} - ${this.headerHeight})`;
    this.suggestedTutorialsHeight = this.suggestedTutorialsHeader
      ? `calc(${this.maxDialogHeight} - ${this.headerHeight} - ${this.suggestedTutorialHeaderHeight})`
      : `calc(${this.maxDialogHeight} - ${this.headerHeight})`;
  }

  private get headerHeight(): string {
    return `${this.header.nativeElement.parentElement?.offsetHeight}px`;
  }

  private get tutorialsFilterHeight(): string {
    return `${this.tutorialsFilter ? this.tutorialsFilter.nativeElement.offsetHeight : 0}px`;
  }

  private get suggestedTutorialHeaderHeight(): string {
    return `${this.suggestedTutorialsHeader.nativeElement.offsetHeight}px`;
  }

  private get maxDialogHeight(): string {
    return `${this.isScreenHigher ? this.defaultDialogHeight : this.emptySpaceToFill}px`;
  }

  private get isScreenHigher(): boolean {
    return this.emptySpaceToFill > this.defaultDialogHeight;
  }

  private get emptySpaceToFill(): number {
    const [value] = this.getCssVariable('--dialog-vertical-margins').split('px');
    return window.innerHeight - Number(value);
  }

  private getCssVariable(property: string): string {
    return window.getComputedStyle(document.documentElement).getPropertyValue(property);
  }
}
