import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IModuleInstance } from '@layout/launcher/models/module-instance.interface';
import { ProductThemesFacade } from '@core/product-configuration/facade/product-themes.facade';
import { IModule } from '@layout/launcher/models/module.interface';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'itc-instance-card',
  templateUrl: './instance-card.component.html',
  styleUrls: ['./instance-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InstanceCardComponent {
  @Input() instance: IModuleInstance;
  @Input() module: IModule;

  constructor(
    private productThemesFacade: ProductThemesFacade,
    private ngbActiveModal: NgbActiveModal
  ) {}

  get productIconPath(): string {
    return this.productThemesFacade.getProductIconPath(this.module.moduleId);
  }

  onModuleSelect(instance: IModuleInstance): void {
    window.open(instance.landingPageUrl);
    this.ngbActiveModal.close();
  }
}
