<div class="toast__container" role="alert" [attr.aria-label]="message || title" (mouseenter)="onMouseHover()" (mouseleave)="onMouseLeave()">
  <div class="toast__main">
    <div class="toast__icon-container" [ngClass]="'toast__icon-container--' + type">
      <div class="toast__icon" [ngClass]="'toast__icon--' + type"></div>
    </div>
    <div class="toast__content">
      @if (title) {
        <div class="toast__title">{{ title }}</div>
      }
      @if (message) {
        <div class="toast__description" [innerHTML]="message"></div>
      }

      @if (customBodyTemplate) {
        <ng-container *ngTemplateOutlet="customBodyTemplate" />
      }
    </div>
    @if (options.closeButton) {
      <div class="toast__close">
        <div class="toast__close-icon" (click)="remove()"></div>
      </div>
    }
  </div>
  @if (options.progressBar) {
    <div class="toast__progress-bar" [ngClass]="'toast__progress-bar--' + type" [style.width.%]="progressBarWidth()"></div>
  }
</div>
