import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { EMPTY, Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { LoadCountries, LoadCountriesIfNotLoaded } from '@core/locations/store/actions/countries.actions';
import { ICountry } from '@core/locations/models/country.interface';
import { CountriesService } from '@core/locations/services/countries.service';

export interface ICountriesStateModel {
  loading: boolean;
  countries?: ICountry[];
}

@State<ICountriesStateModel>({
  name: 'locationCountries',
  defaults: {
    loading: false,
  },
})
@Injectable()
export class CountriesState {
  constructor(private countriesService: CountriesService) {}

  @Selector()
  static countries(state: ICountriesStateModel): ICountry[] | undefined {
    return state.countries;
  }

  @Selector()
  static loading(state: ICountriesStateModel): boolean {
    return state.loading;
  }

  @Action(LoadCountriesIfNotLoaded)
  loadCountriesIfNotLoaded(context: StateContext<ICountriesStateModel>): Observable<void> {
    const { countries } = context.getState();
    return countries ? EMPTY : context.dispatch(LoadCountries);
  }

  @Action(LoadCountries)
  loadCountries(context: StateContext<ICountriesStateModel>): Observable<ICountry[]> {
    context.patchState({ loading: true });
    return this.countriesService.getCountries().pipe(
      tap(countries => {
        context.patchState({
          countries,
        });
      }),
      finalize(() => {
        context.patchState({ loading: false });
      })
    );
  }
}
