import { inject, Injectable } from '@angular/core';
import { AuthorizationService } from '@core/authorization/services/authorization.service';
import { K365SetupGuidesAccessService } from '@layout/k365-setup-guides-access/services/k365-setup-guides-access.service';
import { shareReplay, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class K365SetupGuidesAccessFacade {
  readonly k365SetupGuidesAccessService = inject(K365SetupGuidesAccessService);
  readonly authorizationService = inject(AuthorizationService);
  readonly setupGuidesAccess$ = this.authorizationService.onLogin.pipe(
    switchMap(() => this.k365SetupGuidesAccessService.getSetupGuidesAccess()),
    shareReplay({ refCount: true, bufferSize: 1 })
  );
}
