<ng-container [ngSwitch]="tutorialsListState$ | async">
  <div *ngSwitchCase="tutorialsListState.LoadedList" class="tutorials">
    <itc-tutorial-card
      *ngFor="let tutorial of filteredTutorials$ | async"
      [id]="tutorial.id"
      [title]="tutorial.title"
      [description]="tutorial.description"
      [category]="tutorial.category"
      [videoUrl]="tutorial.videoUrl"
    />
  </div>
  <div *ngSwitchCase="tutorialsListState.Loading" class="tutorials__spinner-container">
    <itc-progress-spinner [spinnerSize]="60" />
  </div>
  <ng-container *ngSwitchCase="tutorialsListState.LoadedEmptyList">
    <div class="tutorials__empty-list">
      <img src="assets/icons/tutorials/empty-state.svg" />
      <div class="tutorials__empty-list-title">{{ 'tutorials.empty-list' | translate }}</div>
    </div>
  </ng-container>
  <div *ngSwitchCase="tutorialsListState.LoadingError">
    <div class="tutorials__error">
      <img src="assets/icons/error-loading.svg" class="tutorials__error-image" />
      <div class="tutorials__error-title">{{ 'tutorials.error' | translate }}</div>
    </div>
  </div>
</ng-container>
