import { Injectable } from '@angular/core';
import { StorageService } from '@core/storage/services/storage.service';
import { IAuthorizationData } from '@core/authorization/models/authorization-data.interface';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class RefreshTokenValidityService {
  private readonly authorizationData?: IAuthorizationData;

  constructor(
    private storageService: StorageService,
    private router: Router
  ) {
    this.authorizationData = this.storageService.getAuthorizationData();
  }

  checkTokenExpiryDateFactory(): Observable<void> {
    return new Observable<void>(observer => {
      if (this.isTokenExpired()) {
        this.router.navigate(['/logout']).finally(() => {
          observer.next();
          observer.complete();
        });
      } else {
        observer.next();
        observer.complete();
      }
    });
  }

  private isTokenExpired(): boolean | undefined {
    if (!this.expirationRefreshTokenDateInLocalTime) {
      return undefined;
    }

    return this.expirationRefreshTokenDateInLocalTime < this.currentLocalTime;
  }

  private get currentLocalTime(): Date {
    return new Date();
  }

  private get expirationRefreshTokenDateInLocalTime(): Date | undefined {
    if (!this.authorizationData) {
      return undefined;
    }

    return new Date(this.authorizationData.expirationRefreshTokenDate);
  }
}
