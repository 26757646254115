import { Component, ChangeDetectionStrategy, Output, EventEmitter, Input } from '@angular/core';
import { NgOptimizedImage } from '@angular/common';

@Component({
  standalone: true,
  selector: 'itc-collapse-button',
  templateUrl: './collapse-button.component.html',
  styleUrls: ['./collapse-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgOptimizedImage],
})
export class CollapseButtonComponent {
  @Input() isCollapsed = false;

  @Output() toggleCollapse = new EventEmitter<void>();

  onClick(): void {
    this.toggleCollapse.emit();
  }
}
