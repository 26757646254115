import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { IFilter, IFilterConfig } from '@design/filter/models/filter.interface';
import { FilterTypes } from '@design/filter/models/filter-types.enum';
import { ISearchFilter } from '@design/filter/models/search-filter.interface';
import { IMultiselectSearch } from '@design/filter/models/multiselect-search.interface';
import { IDateRangeFilter } from '@design/filter/models/date-range-filter.interface';
import { IMultiselectFilter } from '@design/filter/models/multiselect-filter.interface';

@Component({
  selector: 'itc-smart-filter',
  templateUrl: './smart-filter.component.html',
  styleUrls: ['./smart-filter.component.scss'],
})
export class SmartFilterComponent {
  @Input() config: IFilterConfig<unknown>;
  @Input() model: Record<string, unknown>;
  @Input() inputTestId: string;

  @Output() updateFilter = new EventEmitter();
  @Output() clearFilter = new EventEmitter();
  @Output() closeFilter = new EventEmitter();
  @Output() multiselectSearch = new EventEmitter<IMultiselectSearch>();

  selectedFilter: string | null;
  filterTypes = FilterTypes;

  @HostListener('keydown.backspace', ['$event'])
  onBackspace(): void {
    if (this.config.search && this.model[this.config.search.field]) {
      this.model[this.config.search.field] = null;
      this.updateFilter.emit(this.model);
      return;
    }

    if (!this.config.filters) {
      return;
    }

    const reverseFilters = this.config.filters.reverse();

    this.clearLastFilter(reverseFilters);
  }

  get isAnyFilterApplied(): boolean {
    return (
      this.isFilterApplied(this.config.search) ||
      (this.config.filters ?? []).filter((filter: IFilter<unknown>) => this.isFilterApplied(filter)).length > 0
    );
  }

  onMultiselectSearch(filterName: string, searchKeyword: string): void {
    this.multiselectSearch.emit({ filterId: filterName, searchKeyword });
  }

  updateModel(model: Record<string, unknown>): void {
    this.updateFilter.emit(model);
    this.onCloseFilter();
  }

  clearAll(): void {
    this.clearFilter.emit();
  }

  openFilter(name: string): void {
    this.selectedFilter = name;
  }

  onCloseFilter(): void {
    this.selectedFilter = null;
    this.closeFilter.emit();
  }

  isFilterApplied(filter: IFilter<unknown> | ISearchFilter<unknown>): boolean {
    switch (filter.type) {
      case FilterTypes.dateRange:
        return (this.model[filter.fieldFrom] && this.model[filter.fieldTo]) as boolean;
      case FilterTypes.multiselect:
        return this.model[filter.field] as boolean;
      case FilterTypes.search:
        return this.model[filter.field] as boolean;
      default:
        return false;
    }
  }

  private clearLastFilter(filters: IFilter<unknown>[]): void {
    for (const filter of filters) {
      if (filter.type == FilterTypes.dateRange) {
        if (!this.model[filter.fieldFrom] || !this.model[filter.fieldTo]) {
          continue;
        }

        this.clearDateRangeFilter(filter);
        break;
      }

      if (filter.type == FilterTypes.multiselect) {
        if (!this.model[filter.field]) {
          continue;
        }

        this.clearMultiSelectFilter(filter);
        break;
      }
    }
  }

  private clearDateRangeFilter(filter: IDateRangeFilter<unknown>): void {
    this.model[filter.fieldFrom] = null;
    this.model[filter.fieldTo] = null;
    this.updateFilter.emit(this.model);
  }

  private clearMultiSelectFilter(filter: IMultiselectFilter<unknown>): void {
    this.model[filter.field] = null;
    this.updateFilter.emit(this.model);
  }
}
