import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { isObservable, Observable, Subject } from 'rxjs';
import { IOption } from '@design/filter/models/option.interface';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'itc-multiselect-chip',
  templateUrl: './multiselect-chip.component.html',
})
export class MultiselectChipComponent implements OnInit, OnChanges, OnDestroy {
  @Input() field: string;
  @Input() name: string;
  @Input() model: Record<string, unknown>;
  @Input() options: IOption[] | Observable<IOption[]>;

  @Output() updateModel = new EventEmitter();

  dataLabel: string;
  private readonly unsubscribe$ = new Subject<void>();

  ngOnChanges(simpleChanges: SimpleChanges): void {
    if (simpleChanges.model && !simpleChanges.model.firstChange) {
      this.initChipData();
    }
  }

  ngOnInit(): void {
    this.initChipData();
  }

  removeChip(): void {
    this.model[this.field] = null;

    this.updateModel.emit(this.model);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private initChipData(): void {
    if (!isObservable(this.options)) {
      this.mapOptionsToDataLabel(this.options);
      return;
    }

    this.options.pipe(takeUntil(this.unsubscribe$)).subscribe(options => {
      this.mapOptionsToDataLabel(options);
    });
  }

  private mapOptionsToDataLabel(options: IOption[]): void {
    const optionIds = this.model[this.field] as (string | number)[];
    this.dataLabel = optionIds.map(id => options?.find(option => option.id === id)?.label).join(', ');
  }
}
