import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ModalComponent } from '@design/modal/components/modal/modal.component';
import { CustomModalComponent } from '@design/modal/components/custom-modal/custom-modal.component';
import { RegularButtonComponent } from '@design/button/components/regular-button/regular-button.component';

@NgModule({
  declarations: [ModalComponent, CustomModalComponent],
  exports: [ModalComponent, CustomModalComponent],
  imports: [CommonModule, TranslateModule, RegularButtonComponent],
})
export class ModalModule {}
