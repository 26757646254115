<div class="custom-large-button">
  <itc-regular-button
    [testId]="testId"
    [colorScheme]="colorScheme"
    [isCritical]="isCritical"
    [disabled]="disabled"
    [buttonText]="buttonText"
    [loading]="loading"
    [spinnerSize]="20"
    [alignContentToRight]="alignContentToRight"
    (buttonClick)="buttonClick.emit()"
  >
    <ng-content />
  </itc-regular-button>
</div>
