import { ISortBy } from '@design/table/models/sort-by.interface';
import { IToggleSubscription } from '@pages/subscriptions/models/module-subscriptions/toggle-subscriptions.interface';
import { SubscriptionsSortBy } from '@pages/subscriptions/models/subscriptions-sort-by.enum';

export class LoadAllSubscriptions {
  static readonly type = '[AllSubscriptions] LoadAllSubscriptions';
}

export class SearchAllSubscriptionsByName {
  static readonly type = '[AllSubscriptions] SearchAllSubscriptionsByName';
  constructor(public name: string) {}
}

export class AllSubscriptionsSkusSorting {
  static readonly type = '[AllSubscriptions] AllSubscriptionsSkusSorting';
  constructor(
    public sortBy: ISortBy<keyof typeof SubscriptionsSortBy>,
    public name: string
  ) {}
}

export class ToggleSubscription {
  static readonly type = '[AllSubscriptions] ToggleSubscription';
  constructor(public toggleSubscription: IToggleSubscription) {}
}

export class ToggleAllSubscriptions {
  static readonly type = '[AllSubscriptions] ToggleAllSubscriptions';
  constructor(public areAllExpandedSubscriptions: boolean) {}
}
