import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { OrganizationMappingSynchronizationStatus } from '@pages/organization-mapping/models/organization-mapping-synchronization-status.enum';

@Injectable({
  providedIn: 'root',
})
export class OrganizationMappingSynchronizationService {
  private readonly httpClient = inject(HttpClient);

  getSynchronizationStatus(): Observable<OrganizationMappingSynchronizationStatus> {
    const url = 'api/v1/organization-mappings/synchronization-status';

    return this.httpClient.get<OrganizationMappingSynchronizationStatus>(environment.portalApiUrl + url);
  }

  triggerSynchronization(): Observable<void> {
    const url = 'api/v1/organization-mappings/synchronizations';

    return this.httpClient.post<void>(environment.portalApiUrl + url, null);
  }
}
