import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { IAuthorizationCodeRequest } from '@core/sso/models/open-id-connect/authorization-code-request.interface';
import { IAuthorizationCodeResponse } from '@core/sso/models/open-id-connect/authorization-code-response.interface';
import { ITenant } from '@core/sso/models/tenant.interface';
import { hideToast } from '@core/errors/models/context';

@Injectable({ providedIn: 'root' })
export class OpenIdConnectApiService {
  private readonly getAuthorizationCodeUrl = 'api/v1/connect/authorize';
  private readonly getTenantsUrl = (clientId: string): string => `api/v1/connect/${clientId}/tenants`;
  private readonly getTenantUrl = (clientId: string, loginHint: string): string => `api/v1/connect/${clientId}/tenants/${loginHint}`;

  constructor(private httpClient: HttpClient) {}

  getAuthorizationCode(authorizationCodeRequest: IAuthorizationCodeRequest): Observable<IAuthorizationCodeResponse> {
    return this.httpClient.post<IAuthorizationCodeResponse>(
      environment.portalApiUrl + this.getAuthorizationCodeUrl,
      authorizationCodeRequest,
      {
        context: hideToast(),
      }
    );
  }

  getTenantByLoginHint(clientId: string, loginHint: string): Observable<ITenant> {
    return this.httpClient.get<ITenant>(environment.portalApiUrl + this.getTenantUrl(clientId, loginHint));
  }

  getTenants(clientId: string): Observable<ITenant[]> {
    return this.httpClient.get<ITenant[]>(environment.portalApiUrl + this.getTenantsUrl(clientId));
  }
}
