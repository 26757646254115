import { Injectable } from '@angular/core';
import { TutorialCategory } from '@layout/tutorials/models/tutorial-category.enum';
import {
  LoadSuggestedTutorials,
  LoadTutorials,
  ResetState,
  SelectCategory,
  SelectTutorial,
  SetSearchKey,
  UnselectTutorial,
} from '@layout/tutorials/store/actions/tutorials.actions';
import { TutorialsState } from '@layout/tutorials/store/states/tutorials.state';
import { Store } from '@ngxs/store';

@Injectable({
  providedIn: 'root',
})
export class TutorialsFacade {
  readonly tutorials$ = this.store.select(TutorialsState.tutorials);
  readonly suggestedTutorialsByCategory$ = this.store.select(TutorialsState.suggestedTutorialsByCategory);
  readonly selectedTutorial$ = this.store.select(TutorialsState.selectedTutorial);
  readonly tutorialsListState$ = this.store.select(TutorialsState.tutorialsListState);
  readonly suggestedTutorialsListState$ = this.store.select(TutorialsState.suggestedTutorialsListState);
  readonly totalTutorialsCount$ = this.store.select(TutorialsState.totalTutorialsCount);
  readonly foundTutorialsCount$ = this.store.select(TutorialsState.foundTutorialsCount);
  readonly filter$ = this.store.select(TutorialsState.filter);

  constructor(private store: Store) {}

  loadVideoTutorials(): void {
    this.store.dispatch(new LoadTutorials());
  }

  selectTutorial(id: string): void {
    this.store.dispatch(new SelectTutorial(id));
  }

  unselectTutorial(): void {
    this.store.dispatch(new UnselectTutorial());
  }

  loadSuggestedTutorials(category: TutorialCategory): void {
    this.store.dispatch(new LoadSuggestedTutorials(category));
  }

  selectCategory(category: TutorialCategory): void {
    this.store.dispatch(new SelectCategory(category));
  }

  setSearchKey(searchKey?: string): void {
    this.store.dispatch(new SetSearchKey(searchKey));
  }

  resetState(): void {
    this.store.dispatch(new ResetState());
  }
}
