import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { ISessionDetails } from '@pages/professional-services/models/details/session-details.interface';

@Injectable({
  providedIn: 'root',
})
export class ProfessionalServicesSessionDetailsService {
  private readonly getProjectDetailsUrl = (projectId: string, sessionId: string): string =>
    `api/v1/professional-services-dashboard/projects/${projectId}/sessions/${sessionId}`;

  constructor(private httpClient: HttpClient) {}

  getProfessionalServicesSessionDetails(projectId: string, sessionId: string): Observable<ISessionDetails> {
    return this.httpClient.get<ISessionDetails>(environment.portalApiUrl + this.getProjectDetailsUrl(projectId, sessionId));
  }
}
