import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { interval, map, Observable, startWith } from 'rxjs';
import { AsyncPipe, DatePipe } from '@angular/common';

@Component({
  standalone: true,
  selector: 'itc-countdown',
  templateUrl: './countdown.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [DatePipe, AsyncPipe],
})
export class CountdownComponent implements OnInit {
  @Input({ required: true }) seconds: number;

  counter$?: Observable<Date>;
  private readonly counterIntervalMs = 1000;

  ngOnInit(): void {
    this.counter$ = interval(this.counterIntervalMs).pipe(
      startWith(new Date(0, 0, 0, 0, 0, this.seconds--)),
      map(result => (typeof result === 'number' ? new Date(0, 0, 0, 0, 0, this.seconds--) : result))
    );
  }
}
