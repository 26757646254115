<div class="notifications">
  <div
    class="notifications__label"
    tooltipPosition="bottom-center"
    [itcTooltip]="'header.tooltips.notifications' | translate"
    [disableTooltip]="areNotificationsVisible"
    [class.notifications__label-active]="areNotificationsVisible"
    (click)="toggleNotificationsVisibility()"
  >
    <div class="notifications__icon"></div>
    @if (unreadNotificationsCount$ | async; as unreadNotificationsCount) {
      @if (unreadNotificationsCount > 0) {
        <span class="notifications__count">
          @if (unreadNotificationsCount > maximumNotificationCount) {
            {{ maximumNotificationCount }}+
          } @else {
            {{ unreadNotificationsCount }}
          }
        </span>
      }
    }
  </div>

  @if (areNotificationsVisible) {
    <div class="notifications__items">
      <div class="notifications__items-header">
        <span class="notifications__items-header-title">{{ 'notifications.title' | translate }}</span>
        @if ((unreadNotificationsCount$ | async) > 0) {
          <div class="notifications__items-header-action" (click)="markAllNotificationsAsViewed()">
            {{ 'notifications.mark-all-as-read' | translate }}
          </div>
        }

        @if ((notifications$ | async)?.length && (unreadNotificationsCount$ | async) === 0) {
          <div class="notifications__items-header-action" (click)="deleteAllNotifications()">
            {{ 'notifications.clear-all' | translate }}
          </div>
        }
      </div>

      @if ((notifications$ | async)?.length) {
        <div class="notifications__items-content">
          @for (notification of notifications$ | async; track notification.id) {
            <div class="notifications__item">
              <div class="notifications__item-read">
                <div class="notifications__item-read-wrapper" (click)="toggleNotificationViewability(notification)">
                  @if (!notification.viewed) {
                    <div class="notifications__item-read-wrapper-indicator"></div>
                  }
                </div>
              </div>
              <div class="notifications__item-content" (click)="openNotification(notification)">
                <div class="notifications__item-content-header">
                  {{ getNotificationTitle(notification) }}
                </div>
                <div class="notifications__item-content-body">
                  {{ getNotificationBody(notification) }}
                </div>
                <div class="notifications__item-content-footer">
                  {{ notification.dateTimeOfOccurrence | date: 'mediumDate' }}
                </div>
              </div>
              <div class="notifications__item-delete" (click)="deleteNotification(notification)"></div>
            </div>
          }
        </div>
      } @else {
        <div class="notifications__items-empty">
          <div class="notifications__items-empty-icon"></div>
          <div class="notifications__items-empty-title">{{ 'notifications.empty.title' | translate }}</div>
          <div class="notifications__items-empty-description">{{ 'notifications.empty.description' | translate }}</div>
        </div>
      }
    </div>
  }
</div>
