import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { catchError, finalize, tap } from 'rxjs/operators';
import { EMPTY, Observable } from 'rxjs';
import { Kaseya365UpgradeService } from '@layout/kaseya-365/services/kaseya-365-upgrade.service';
import { LoadIsKaseya365Customer, LoadIsKaseya365CustomerIfEmpty } from '@layout/kaseya-365/store/actions/kaseya-365-upgrade.actions';

export interface IKaseya365UpgradeStateModel {
  isKaseya365Customer?: boolean;
  loading: boolean;
}

@State<IKaseya365UpgradeStateModel>({
  name: 'kaseya365Upgrade',
})
@Injectable()
export class Kaseya365UpgradeState {
  constructor(private kaseya365UpgradeService: Kaseya365UpgradeService) {}

  @Selector()
  static isKaseya365Customer(state: IKaseya365UpgradeStateModel): boolean | undefined {
    return state.isKaseya365Customer;
  }

  @Selector()
  static loading(state: IKaseya365UpgradeStateModel): boolean {
    return state.loading;
  }

  @Action(LoadIsKaseya365CustomerIfEmpty)
  loadIsKaseya365CustomerIfEmpty(context: StateContext<IKaseya365UpgradeStateModel>): Observable<void> {
    const state = context.getState();
    return state.loading !== undefined ? EMPTY : context.dispatch(new LoadIsKaseya365Customer());
  }

  @Action(LoadIsKaseya365Customer)
  loadIsKaseya365Customer(context: StateContext<IKaseya365UpgradeStateModel>): Observable<boolean> {
    context.patchState({ loading: true });
    return this.kaseya365UpgradeService.getIsKaseya365Customer().pipe(
      tap(isKaseya365Customer => context.patchState({ isKaseya365Customer })),
      catchError(() => {
        context.patchState({ isKaseya365Customer: false });
        return EMPTY;
      }),
      finalize(() => context.patchState({ loading: false }))
    );
  }
}
