<div
  class="launcher-item__product"
  [itcTooltip]="discoverableModule.moduleName"
  [tooltipPosition]="isTooltipRightAligned ? 'bottom-right' : 'bottom-left'"
  [disableTooltip]="isFullTextDisplayed(productName)"
  (click)="onLauncherItemClick(discoverableModule)"
>
  <img
    class="launcher-item__icon"
    height="32"
    width="32"
    [alt]="discoverableModule.moduleName"
    [ngSrc]="discoverableModule.moduleImageUrl || 'assets/icons/products/default.svg'"
  />
  <div class="launcher-item__text">
    <span #productName class="launcher-item__name">
      {{ discoverableModule.moduleName }}
    </span>
    <span class="launcher-item__description">{{ discoverableModule.description }}</span>
  </div>
</div>
