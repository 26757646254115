import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { catchError, finalize, tap } from 'rxjs/operators';
import { EMPTY, Observable } from 'rxjs';
import {
  LoadAnyModuleConfigurations,
  LoadAnyModuleConfigurationsIfEmpty,
} from '@layout/module-management/store/actions/module-configurations.actions';
import { ModuleConfigurationsService } from '@layout/module-management/services/module-configurations.service';
import { isNotUndefined } from '@shared/utils/is-not-undefined';

interface IAnyModuleConfigurationsStateModel {
  anyModuleConfigurations?: boolean;
  loading: boolean;
}

@State<IAnyModuleConfigurationsStateModel>({
  name: 'anyModuleConfigurations',
  defaults: {
    loading: false,
  },
})
@Injectable()
export class ModuleConfigurationsState {
  constructor(private moduleConfigurationsService: ModuleConfigurationsService) {}

  @Selector()
  static anyModuleConfigurations(state: IAnyModuleConfigurationsStateModel): boolean | undefined {
    return state.anyModuleConfigurations;
  }

  @Selector()
  static loading(state: IAnyModuleConfigurationsStateModel): boolean {
    return state.loading;
  }

  @Action(LoadAnyModuleConfigurationsIfEmpty)
  loadAnyModuleConfigurationIfEmpty(context: StateContext<IAnyModuleConfigurationsStateModel>): Observable<void> {
    const state = context.getState();
    return isNotUndefined(state.anyModuleConfigurations) ? EMPTY : context.dispatch(new LoadAnyModuleConfigurations());
  }

  @Action(LoadAnyModuleConfigurations)
  loadAnyModuleConfigurations(context: StateContext<IAnyModuleConfigurationsStateModel>): Observable<boolean> {
    context.patchState({ loading: true });
    return this.moduleConfigurationsService.getAnyModuleConfigurations().pipe(
      tap(anyModuleConfigurations => context.patchState({ anyModuleConfigurations })),
      catchError(() => {
        context.patchState({ anyModuleConfigurations: undefined });
        return EMPTY;
      }),
      finalize(() => context.patchState({ loading: false }))
    );
  }
}
