import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class ProfessionalServicesHasAnyProjectsService {
  constructor(private httpClient: HttpClient) {}

  getProfessionalServicesHasAnyProjects(): Observable<boolean> {
    const getHasAnyProjectsUrl = 'api/v1/professional-services-dashboard/projects/any';

    return this.httpClient.get<boolean>(environment.portalApiUrl + getHasAnyProjectsUrl);
  }
}
