import { inject, Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { finalize, tap } from 'rxjs/operators';
import { EMPTY, Observable } from 'rxjs';
import { FirstTimeOnboardingService } from '@pages/first-time-onboarding/services/first-time-onboarding.service';
import { MarkOnboardingAsCompleted, OnboardingCompleted } from '@pages/first-time-onboarding/store/actions/first-time-onboarding.actions';

interface IFirstTimeOnboardingStateModel {
  completeOnboardingLoading: boolean;
}
@State<IFirstTimeOnboardingStateModel>({
  name: 'firstTimeOnboarding',
  defaults: {
    completeOnboardingLoading: false,
  },
})
@Injectable()
export class FirstTimeOnboardingState {
  private readonly firstTimeOnboardingService = inject(FirstTimeOnboardingService);

  @Selector()
  static completeOnboardingLoading(state: IFirstTimeOnboardingStateModel): boolean {
    return state.completeOnboardingLoading;
  }

  @Action(MarkOnboardingAsCompleted)
  markOnboardingAsCompleted(context: StateContext<IFirstTimeOnboardingStateModel>): Observable<void> {
    if (context.getState().completeOnboardingLoading) {
      return EMPTY;
    }

    context.patchState({ completeOnboardingLoading: true });

    return this.firstTimeOnboardingService.markOnboardingAsCompleted().pipe(
      tap(() => context.dispatch(new OnboardingCompleted())),
      finalize(() => context.patchState({ completeOnboardingLoading: false }))
    );
  }
}
