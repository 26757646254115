import { inject, Injectable, OnDestroy } from '@angular/core';
import { ActivatedRouteSnapshot, CanMatchFn, Route, Router, UrlTree } from '@angular/router';
import { PermissionsService } from '@core/permissions/services/permissions.service';
import { Subject } from 'rxjs';

@Injectable()
export class PermissionAccessGuard implements OnDestroy {
  private unsubscribe$ = new Subject<void>();

  constructor(
    private router: Router,
    private permissionService: PermissionsService
  ) {}

  canMatch(route: Route): UrlTree | boolean {
    if (!route.data?.permission) {
      return true;
    }

    return this.hasUserPermissionToRoute(route);
  }

  private hasUserPermissionToRoute(route: ActivatedRouteSnapshot | Route): UrlTree | boolean {
    if (!this.permissionService.hasUserPermission(route.data?.permission)) {
      return this.router.createUrlTree(['access-denied']);
    }

    return true;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}

export const canMatchPermission: CanMatchFn = (route: Route) => {
  return inject(PermissionAccessGuard).canMatch(route);
};
