import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { StateReset, StateResetAll } from 'ngxs-reset-plugin';
import { AuthorizationService } from '@core/authorization/services/authorization.service';
import { ClearState } from '@core/store/actions/reset-state.actions';
import { StateClass } from 'ngxs-reset-plugin/lib/internals';

@Injectable({
  providedIn: 'root',
})
export class ResetStateFacade {
  constructor(
    private store: Store,
    private authorizationService: AuthorizationService
  ) {
    this.authorizationService.onLogout.subscribe(() => {
      this.clearAllStates();
    });
  }

  clearState(...states: StateClass[]): void {
    states.forEach(state => {
      const action = new ClearState(state.name);
      this.store.dispatch([action, new StateReset(state)]);
    });
  }

  private clearAllStates(): void {
    this.store.dispatch(new StateResetAll());
  }
}
