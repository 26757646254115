import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TutorialsFacade } from '@layout/tutorials/facades/tutorials.facade';

@Component({
  selector: 'itc-suggested-tutorials-header',
  templateUrl: './suggested-tutorials-header.component.html',
  styleUrls: ['./suggested-tutorials-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SuggestedTutorialsHeaderComponent {
  constructor(private tutorialsFacade: TutorialsFacade) {}

  onBackButtonClick(): void {
    this.tutorialsFacade.unselectTutorial();
  }
}
