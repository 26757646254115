<label class="input__container" [for]="elementId" [attr.aria-labelledby]="ariaLabeledBy" [attr.aria-label]="ariaLabel">
  @if (label) {
    <div class="input__label" [class.input__label--readonly]="readonly">
      {{ label | translate }}
      @if (isRequired) {
        <span class="input__label-asterisk">*</span>
      }
    </div>
  }
  <div class="input__field-container">
    <input
      #input
      class="input__field"
      [class.input__field--copyable]="isCopyButtonVisible"
      [class.input__field--resetable]="isResetButtonVisible"
      [class.input__field--invalid]="isInvalid"
      [class.input__field--loading]="loading"
      [id]="elementId"
      [attr.data-test-id]="testId"
      [type]="type"
      [name]="name || elementId"
      [placeholder]="placeholder | translate"
      [autocomplete]="autocomplete"
      [itcAutoFocus]="autoFocus"
      [itcRemoveSpaces]="itcRemoveSpaces"
      [formControl]="componentFormControl"
      [readonly]="readonly"
      [mask]="mask"
      [thousandSeparator]="thousandSeparator"
      [prefix]="getPrefix(input)"
      [tabindex]="tabIndex"
      (click)="onClick($event)"
      (blur)="blur.emit()"
    />
    @if (loading) {
      <div class="input__field-spinner" [attr.data-test-id]="spinnerTestId">
        <itc-progress-spinner [spinnerColor]="'#4F64EE'" [spinnerSize]="18" />
      </div>
    }
    @if (isResetButtonVisible && isResetAvailable) {
      <img class="input__reset-icon" ngSrc="assets/icons/redo-regular.svg" width="14" height="14" (click)="onReset()" />
    }
    @if (isCopyButtonVisible) {
      <div class="input__right-icon-container">
        <itc-copy-button [valueToCopy]="componentFormControl.value" (click)="copy.emit()" />
      </div>
    }
  </div>
  @if (isInvalid) {
    @if (!isPopupError) {
      <div class="input__error">
        <itc-input-error [componentFormControl]="componentFormControl" [isSmallFontSize]="isSmallFontSize" />
      </div>
    } @else {
      <div class="input__error-popup">
        <itc-input-popup-error [componentFormControl]="componentFormControl" />
      </div>
    }
  }
</label>
