import { Component, DestroyRef, OnInit, signal, TemplateRef, ViewEncapsulation } from '@angular/core';
import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { IToastPayloadWithConfiguration } from '@design/toast/models/toast-payload-with-configuration.interface';
import { ToastType } from '@design/toast/models/toast-type.enum';
import { asNonUndefined } from '@shared/utils/as-non-undefined';
import { interval, Subscription } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'itc-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('flyInOut', [
      state(
        'inactive',
        style({
          opacity: 0,
          transform: 'translate3d(-100%, 0, 0)',
        })
      ),
      state(
        'active',
        style({
          opacity: 1,
          transform: 'none',
        })
      ),
      state(
        'removed',
        style({
          opacity: 0,
          transform: 'translate3d(-100%, 0, 0)',
        })
      ),
      transition(
        'inactive => active',
        animate(
          '300ms ease-out',
          keyframes([
            style({
              transform: 'translate3d(-100%, 0, 0)',
              opacity: 0,
            }),
            style({
              transform: 'translate3d(-50%, 0, 0)',
              opacity: 0.5,
            }),
            style({
              transform: 'translate3d(0, 0, 0)',
              opacity: 1,
            }),
          ])
        )
      ),
      transition(
        'active => removed',
        animate(
          '300ms ease-out',
          keyframes([
            style({
              transform: 'translate3d(0, 0, 0)',
              opacity: 1,
            }),
            style({
              transform: 'translate3d(-50%, 0, 0)',
              opacity: 0.5,
            }),
            style({
              transform: 'translate3d(-100%, 0, 0)',
              opacity: 0,
            }),
          ])
        )
      ),
    ]),
  ],
  imports: [NgClass, NgTemplateOutlet],
})
export class ToastComponent extends Toast implements OnInit {
  type?: ToastType;
  timeOutMs?: number;
  pausedTimeMs?: number;
  customBodyTemplate?: TemplateRef<unknown>;
  progressIntervalSubscription?: Subscription;
  progressBarWidth = signal(-1);

  private hideTimeMs?: number;

  constructor(
    private destroyRef: DestroyRef,
    protected override toastrService: ToastrService,
    public override toastPackage: ToastPackage
  ) {
    super(toastrService, toastPackage);
    const { timeOutMs, type, customBodyTemplate } = this.toastPackage.config.payload as IToastPayloadWithConfiguration;
    this.type = type;
    this.timeOutMs = timeOutMs;
    this.customBodyTemplate = customBodyTemplate;
  }

  ngOnInit(): void {
    if (!this.timeOutMs) {
      return;
    }

    this.hideTimeMs = this.nowMs + this.timeOutMs;
    this.startInterval();
    this.toastPackage.toastRef
      .timeoutReset()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.resetInterval();
      });
  }

  updateProgressWidth(): void {
    const remainingMs = asNonUndefined(this.hideTimeMs) - this.nowMs;
    this.progressBarWidth.set((remainingMs / asNonUndefined(this.timeOutMs)) * 100);

    if (this.progressBarWidth() <= 0) {
      this.remove();
    }
  }

  onMouseHover(): void {
    if (!this.hideTimeMs || !this.timeOutMs) {
      return;
    }

    this.progressIntervalSubscription?.unsubscribe();
    this.pausedTimeMs = this.hideTimeMs - this.nowMs - this.timeOutMs;
  }

  onMouseLeave(): void {
    if (!this.pausedTimeMs || !this.timeOutMs) {
      return;
    }

    this.hideTimeMs = this.nowMs + this.timeOutMs + this.pausedTimeMs;
    this.pausedTimeMs = undefined;
    this.startInterval();
  }

  private resetInterval(): void {
    this.progressIntervalSubscription?.unsubscribe();
    this.hideTimeMs = this.nowMs + asNonUndefined(this.timeOutMs);
    this.startInterval();
  }

  private startInterval(): void {
    this.progressIntervalSubscription = interval(10)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.updateProgressWidth());
  }

  private get nowMs(): number {
    return new Date().getTime();
  }
}
