import { NgModule } from '@angular/core';
import { OpenSsoLinkDirective } from '@design/open-sso-link/directives/open-sso-link.directive';
import { RedirectFormComponent } from '@design/open-sso-link/components/redirect-form.component';
import { OpenSsoDeepLinkDirective } from '@design/open-sso-link/directives/open-sso-deep-link.directive';

@NgModule({
  declarations: [RedirectFormComponent, OpenSsoLinkDirective, OpenSsoDeepLinkDirective],
  exports: [RedirectFormComponent, OpenSsoLinkDirective, OpenSsoDeepLinkDirective],
})
export class OpenSsoLinkModule {}
