import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { CustomDialogComponent } from '@design/dialog/components/custom-dialog/custom-dialog.component';
import { CdkScrollableModule } from '@angular/cdk/scrolling';

@NgModule({
  declarations: [CustomDialogComponent],
  exports: [CustomDialogComponent],
  imports: [CommonModule, TranslateModule, CdkScrollableModule],
})
export class DialogModule {}
