import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IK365OnboardingSubscription } from '@pages/k365-setup-guides/models/k365-onboarding-subscription.interface';
import { environment } from '@env/environment';

@Injectable({ providedIn: 'root' })
export class K365OnboardingSubscriptionsService {
  private readonly httpClient = inject(HttpClient);

  getK365OnboardingSubscriptions(): Observable<IK365OnboardingSubscription[]> {
    const url = 'api/v1/onboarding/subscriptions/kaseya-365';

    return this.httpClient.get<IK365OnboardingSubscription[]>(environment.portalApiUrl + url);
  }

  markK365OnboardingSubscriptionAsCompleted(subscriptionId: string): Observable<void> {
    const url = `api/v1/onboarding/subscriptions/${subscriptionId}/completed`;

    return this.httpClient.post<void>(environment.portalApiUrl + url, null);
  }
}
