import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { Country } from '@core/authorization/models/country.enum';
import { CurrentUserFacade } from '@core/authorization/facades/current-user.facade';

@Directive({
  selector: '[itcCountryAccess]',
})
export class CountryAccessDirective {
  @Input() set itcCountryAccess(countries: Country[] | null) {
    if (!countries) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      return;
    }

    if (this.currentUserFacade.isUserFromCountries(countries)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      return;
    }

    this.viewContainer.clear();
  }

  constructor(
    private templateRef: TemplateRef<never>,
    private viewContainer: ViewContainerRef,
    private currentUserFacade: CurrentUserFacade
  ) {}
}
