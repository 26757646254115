import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { TooltipModule } from '@design/tooltip/tooltip.module';
import { NgOptimizedImage } from '@angular/common';

@Component({
  standalone: true,
  selector: 'itc-navigation-link',
  templateUrl: './navigation-link.component.html',
  styleUrls: ['./navigation-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  imports: [RouterLinkActive, TooltipModule, RouterLink, NgOptimizedImage],
})
export class NavigationLinkComponent {
  @Input({ required: true }) iconSrc: string;
  @Input({ required: true }) linkName: string;
  @Input() routerLink?: string;
  @Input() testId?: string;
  @Input() isTooltipEnabled = false;
}
