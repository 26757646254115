<button
  class="button"
  [class.button--reversed]="alignContentToRight"
  [tabindex]="disabled ? -1 : 0"
  [attr.data-test-id]="testId"
  [class.loading]="loading"
  [class.disabled]="disabled"
  [class.button--critical]="isCritical"
  [ngClass]="'button--' + colorScheme"
  [type]="type"
  (click)="onClick($event)"
  (keydown.enter)="onClick($event)"
>
  @if (loading) {
    <div class="button__spinner">
      <itc-progress-spinner [spinnerColor]="spinnerColor" [spinnerSize]="spinnerSize" />
    </div>
  }
  <div [hidden]="loading">
    <ng-content />
  </div>
  {{ buttonText | translate }}
</button>
