import { Component } from '@angular/core';
import { AuthContainerComponent } from '@layout/auth-container/components/auth-container.component';
import { RouterOutlet } from '@angular/router';
import { CopyrightComponent } from '@layout/copyright/components/copyright.component';

@Component({
  standalone: true,
  selector: 'itc-auth-view',
  templateUrl: './auth-view.component.html',
  styleUrls: ['./auth-view.component.scss'],
  imports: [AuthContainerComponent, RouterOutlet, CopyrightComponent],
})
export class AuthViewComponent {}
