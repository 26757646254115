import { ChangeDetectionStrategy, Component, ElementRef, HostListener } from '@angular/core';
import { TooltipModule } from '@design/tooltip/tooltip.module';
import { TranslateModule } from '@ngx-translate/core';
import { LauncherContentComponent } from '@layout/launcher/components/launcher-content/launcher-content.component';

@Component({
  standalone: true,
  selector: 'itc-launcher',
  templateUrl: './launcher.component.html',
  styleUrls: ['./launcher.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TooltipModule, TranslateModule, LauncherContentComponent],
})
export class LauncherComponent {
  isWidgetVisible = false;

  constructor(private elementRef: ElementRef) {}

  toggleWidget(): void {
    this.isWidgetVisible = !this.isWidgetVisible;
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.isWidgetVisible = false;
    }
  }
}
