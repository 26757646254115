import { Injectable } from '@angular/core';
import { Month } from '@design/datepicker/models/month.enum';
import { Day } from '@design/datepicker/models/day.enum';

@Injectable({
  providedIn: 'root',
})
export class DateTimeLabelsService {
  getShortWeekdays(): string[] {
    return Object.values(Day).map(day => `date-time.day.${day}.short`);
  }

  getShortMonths(): string[] {
    return Object.values(Month).map(month => `date-time.month.${month}.short`);
  }
}
