import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { ICountry } from '@core/locations/models/country.interface';

@Injectable({
  providedIn: 'root',
})
export class CountriesService {
  private readonly getCountriesUrl = 'api/v1/lookups/countries';

  constructor(private httpClient: HttpClient) {}

  getCountries(): Observable<ICountry[]> {
    return this.httpClient.get<ICountry[]>(environment.portalApiUrl + this.getCountriesUrl);
  }
}
