import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StorageService } from '@core/storage/services/storage.service';
import { mfaTokenContext } from '@core/authorization/models/mfa-token-context';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private storageService: StorageService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const mfaToken = request.context.get(mfaTokenContext);

    if (mfaToken) {
      return next.handle(this.getRequestWithAuthorizationHeader(request, mfaToken));
    }

    const authorizationData = this.storageService.getAuthorizationData();

    if (authorizationData?.accessToken) {
      return next.handle(this.getRequestWithAuthorizationHeader(request, authorizationData.accessToken));
    }

    return next.handle(request);
  }

  private getRequestWithAuthorizationHeader(request: HttpRequest<unknown>, token: string): HttpRequest<unknown> {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
}
