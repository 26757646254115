import { ChangeDetectionStrategy, Component } from '@angular/core';
import { IFilterConfig } from '@design/filter/models/filter.interface';
import { FilterTypes } from '@design/filter/models/filter-types.enum';
import { map } from 'rxjs/operators';
import { TutorialsFacade } from '@layout/tutorials/facades/tutorials.facade';
import { IIpAddressesQueryParams } from '@layout/tutorials/models/ip-addresses-query-params.interface';

@Component({
  selector: 'itc-tutorials-filter',
  templateUrl: './tutorials-filter.component.html',
  styleUrls: ['./tutorials-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TutorialsFilterComponent {
  totalTutorialsCount$ = this.tutorialsFacade.totalTutorialsCount$.pipe(map(value => value ?? '-'));
  foundTutorialsCount$ = this.tutorialsFacade.foundTutorialsCount$.pipe(map(value => value ?? '-'));
  model: IIpAddressesQueryParams = {
    searchKey: undefined,
  };
  config: IFilterConfig<IIpAddressesQueryParams> = {
    search: {
      type: FilterTypes.search,
      name: 'tutorials.tutorials-filter.search-placeholder',
      field: 'searchKey',
    },
  };

  constructor(private tutorialsFacade: TutorialsFacade) {}

  onUpdateSearch(queryParams: IIpAddressesQueryParams): void {
    this.tutorialsFacade.setSearchKey(queryParams.searchKey);
  }

  onClearSearch(): void {
    this.tutorialsFacade.setSearchKey(undefined);
    this.model = {
      searchKey: undefined,
    };
  }
}
