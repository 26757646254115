import { Route } from '@angular/router';
import { canMatchK365SubscriptionTabFeature } from '@pages/subscriptions/guards/k365-subscription-tab-feature.guard';
import { Feature } from '@core/features/models/feature.enum';

export const subscriptionsRoutes: Route[] = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'kaseya-365',
  },
  {
    path: 'kaseya-365',
    loadComponent: () =>
      import('@pages/subscriptions/components/k365-subscription-tab/k365-subscription-tab.component').then(
        component => component.K365SubscriptionTabComponent
      ),
    data: {
      feature: Feature.Kaseya365SubscriptionTabV2,
    },
    canMatch: [canMatchK365SubscriptionTabFeature],
  },
  {
    path: 'kaseya-365',
    loadComponent: () =>
      import('@pages/subscriptions/components/k365-subscriptions/k365-subscriptions.component').then(
        component => component.K365SubscriptionsComponent
      ),
  },
  {
    path: 'it-complete',
    loadComponent: () =>
      import('@pages/subscriptions/components/it-complete-subscriptions/it-complete-subscriptions.component').then(
        component => component.ItCompleteSubscriptionsComponent
      ),
  },
];
