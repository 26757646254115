import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { DateTime } from '@design/datepicker/services/date-time';
import { InputValidators } from '@design/input/validators/input.validators';
import { IDateFormGroup } from '@design/filter/models/date-form-group.interface';

@Component({
  selector: 'itc-date-range-filter',
  templateUrl: './date-range-filter.component.html',
  styleUrls: ['./date-range-filter.component.scss'],
})
export class DateRangeFilterComponent implements OnInit {
  @Input() fieldFrom: string;
  @Input() fieldTo: string;
  @Input() name: string;
  @Input() model: Record<string, unknown>;

  @Output() updateModel = new EventEmitter();
  @Output() closeFilter = new EventEmitter();

  clearDates$ = new Subject<void>();
  dateForm: FormGroup<IDateFormGroup>;
  initialState = {
    startDate: null,
    endDate: null,
  };

  constructor(private elementRef: ElementRef) {}

  ngOnInit(): void {
    this.dateForm = new FormGroup({
      startDate: new FormControl<string | null>(this.initialState.startDate, InputValidators.required()),
      endDate: new FormControl<string | null>(this.initialState.endDate, InputValidators.required()),
    });
  }

  get startDateControl(): FormControl {
    return this.dateForm.controls.startDate;
  }

  get endDateControl(): FormControl {
    return this.dateForm.controls.endDate;
  }

  applyFilter(): void {
    if (this.startDateControl.invalid) {
      this.startDateControl.markAsDirty();
      this.startDateControl.updateValueAndValidity();
    }

    if (this.endDateControl.invalid) {
      this.endDateControl.markAsDirty();
      this.endDateControl.updateValueAndValidity();
    }

    if (this.startDateControl.valid && this.endDateControl.valid) {
      this.model[this.fieldFrom] = new DateTime(this.startDateControl.value).toUTCExcludingTimezone();
      this.model[this.fieldTo] = new DateTime(this.endDateControl.value).toUTCExcludingTimezone();
      this.updateModel.emit(this.model);
    }
  }

  clearDates(): void {
    this.dateForm.reset(this.initialState);
    this.clearDates$.next();
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.closeFilter.emit();
    }
  }
}
