import { inject, Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { finalize, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { IK365OnboardingSubscription } from '@pages/k365-setup-guides/models/k365-onboarding-subscription.interface';
import { K365OnboardingSubscriptionsService } from '@pages/k365-setup-guides/services/k365-onboarding-subscriptions.service';
import {
  LoadK365OnboardingSubscriptions,
  MarkK365OnboardingSubscriptionAsCompleted,
  SelectK365OnboardingSubscription,
  SetSelectedK365OnboardingSubscription,
} from '@pages/k365-setup-guides/store/actions/k365-onboarding-subscriptions.actions';
import { first } from 'lodash-es';
import { ToastService } from '@design/toast/services/toast.service';

interface IK365OnboardingSubscriptionsStateModel {
  subscriptions?: IK365OnboardingSubscription[];
  selectedSubscription?: IK365OnboardingSubscription;
  loading: boolean;
  markAsCompletedLoading: boolean;
}
@State<IK365OnboardingSubscriptionsStateModel>({
  name: 'k365OnboardingSubscriptions',
  defaults: {
    loading: false,
    markAsCompletedLoading: false,
  },
})
@Injectable()
export class K365OnboardingSubscriptionsState {
  private readonly k365OnboardingSubscriptionsService = inject(K365OnboardingSubscriptionsService);
  private readonly toastService = inject(ToastService);

  @Selector()
  static subscriptions(state: IK365OnboardingSubscriptionsStateModel): IK365OnboardingSubscription[] | undefined {
    return state.subscriptions;
  }

  @Selector()
  static selectedSubscription(state: IK365OnboardingSubscriptionsStateModel): IK365OnboardingSubscription | undefined {
    return state.selectedSubscription;
  }

  @Selector()
  static loading(state: IK365OnboardingSubscriptionsStateModel): boolean {
    return state.loading;
  }

  @Selector()
  static markAsCompletedLoading(state: IK365OnboardingSubscriptionsStateModel): boolean {
    return state.markAsCompletedLoading;
  }

  @Action(SelectK365OnboardingSubscription)
  selectK365OnboardingSubscription(
    context: StateContext<IK365OnboardingSubscriptionsStateModel>,
    { subscription }: SelectK365OnboardingSubscription
  ): void {
    context.patchState({ selectedSubscription: subscription });
  }

  @Action(SetSelectedK365OnboardingSubscription)
  setSelectedK365OnboardingSubscription(context: StateContext<IK365OnboardingSubscriptionsStateModel>): void {
    const { selectedSubscription, subscriptions } = context.getState();

    if (!subscriptions) {
      return context.patchState({ selectedSubscription: undefined });
    }

    if (!selectedSubscription) {
      context.patchState({ selectedSubscription: first(subscriptions) });
      return;
    }

    context.patchState({ selectedSubscription: subscriptions.find(({ id }) => id === selectedSubscription.id) || first(subscriptions) });
  }

  @Action(LoadK365OnboardingSubscriptions, { cancelUncompleted: true })
  loadK365OnboardingSubscriptions(
    context: StateContext<IK365OnboardingSubscriptionsStateModel>
  ): Observable<IK365OnboardingSubscription[]> {
    context.patchState({ loading: true });

    return this.k365OnboardingSubscriptionsService.getK365OnboardingSubscriptions().pipe(
      tap(subscriptions => context.patchState({ subscriptions })),
      tap(() => context.dispatch(new SetSelectedK365OnboardingSubscription())),
      finalize(() => context.patchState({ loading: false }))
    );
  }

  @Action(MarkK365OnboardingSubscriptionAsCompleted, { cancelUncompleted: true })
  markK365OnboardingSubscriptionAsCompleted(
    context: StateContext<IK365OnboardingSubscriptionsStateModel>,
    { subscriptionId }: MarkK365OnboardingSubscriptionAsCompleted
  ): Observable<void> {
    context.patchState({ markAsCompletedLoading: true });

    return this.k365OnboardingSubscriptionsService.markK365OnboardingSubscriptionAsCompleted(subscriptionId).pipe(
      tap(() => context.dispatch(new LoadK365OnboardingSubscriptions())),
      tap(() => this.toastService.showSuccessToast('kaseya-365-setup-guides.details.success-toast')),
      finalize(() => context.patchState({ markAsCompletedLoading: false }))
    );
  }
}
