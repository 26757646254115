import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { IAllSubscriptions } from '@pages/subscriptions/models/module-subscriptions/all-subscriptions.interface';
import { IK365Subscriptions } from '@pages/subscriptions/models/module-subscriptions/k365-subscriptions.interface';
import { IEmptyK365Subscription } from '@pages/subscriptions/models/module-subscriptions/empty-k356-subscriptions.interface';
import { IITCompleteCategorySubscriptions } from '@pages/subscriptions//models/module-subscriptions/it-complete-category-subscriptions.interface';
import { IK365SubscriptionSuite } from '@pages/subscriptions/models/k365-subscription/k365-subscription-suite.interface';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionsService {
  private readonly getModulesSubscriptionsUrl = 'api/v1/subscriptions/all';
  private readonly getK365SubscriptionsUrl = 'api/v1/subscriptions/kaseya-365';
  private readonly getITCompleteSubscriptionsUrl = 'api/v2/subscriptions/it-complete';
  private readonly getEmptyK365SubscriptionsUrl = 'api/v1/subscriptions/functions';

  constructor(private httpClient: HttpClient) {}

  getAllSubscriptions(): Observable<IAllSubscriptions[]> {
    return this.httpClient.get<IAllSubscriptions[]>(environment.portalApiUrl + this.getModulesSubscriptionsUrl);
  }

  getITCompleteSubscriptions(): Observable<IITCompleteCategorySubscriptions[]> {
    return this.httpClient.get<IITCompleteCategorySubscriptions[]>(environment.portalApiUrl + this.getITCompleteSubscriptionsUrl);
  }

  getK365Subscriptions(): Observable<IK365Subscriptions[]> {
    return this.httpClient.get<IK365Subscriptions[]>(environment.portalApiUrl + this.getK365SubscriptionsUrl);
  }

  getEmptyK365Subscriptions(): Observable<IEmptyK365Subscription[]> {
    return this.httpClient.get<IEmptyK365Subscription[]>(environment.portalApiUrl + this.getEmptyK365SubscriptionsUrl);
  }

  getK365SubscriptionSuites(): Observable<IK365SubscriptionSuite[]> {
    const url = 'api/v2/subscriptions/kaseya-365';

    return this.httpClient.get<IK365SubscriptionSuite[]>(environment.portalApiUrl + url);
  }
}
