<div class="filter__container">
  <div class="filter__content">
    <div class="filter__chips">
      @for (filter of config.filters; track filter.type) {
        @if (isFilterApplied(filter)) {
          @switch (filter.type) {
            @case (filterTypes.multiselect) {
              <itc-multiselect-chip
                [field]="filter.field"
                [name]="filter.name"
                [model]="model"
                [options]="filter.options"
                (updateModel)="updateModel($event)"
              />
            }
            @case (filterTypes.dateRange) {
              <itc-date-range-chip
                [fieldFrom]="filter.fieldFrom"
                [fieldTo]="filter.fieldTo"
                [name]="filter.name"
                [model]="model"
                (updateModel)="updateModel($event)"
              />
            }
          }
        }
      }

      @if (isFilterApplied(config.search)) {
        <itc-text-chip [name]="config.search.name" [field]="config.search.field" [model]="model" (updateModel)="updateModel($event)" />
      }

      <div class="filter__container-input">
        <itc-input-filter
          [config]="config"
          [model]="model"
          [inputTestId]="inputTestId"
          (selectFilter)="openFilter($event)"
          (updateModel)="updateModel($event)"
        />
        @if (selectedFilter) {
          <div class="filter__menu">
            @for (filter of config.filters; track filter) {
              @if (filter.name === selectedFilter) {
                @switch (filter.type) {
                  @case (filterTypes.multiselect) {
                    <itc-multiselect-filter
                      [options]="filter.options"
                      [loading$]="filter.loadingOptions"
                      [field]="filter.field"
                      [name]="filter.name"
                      [model]="model"
                      [isSearch]="filter.isSearch"
                      (updateModel)="updateModel($event)"
                      (closeFilter)="onCloseFilter()"
                      (search)="onMultiselectSearch(filter.field, $event)"
                    />
                  }
                  @case (filterTypes.dateRange) {
                    <itc-date-range-filter
                      [fieldFrom]="filter.fieldFrom"
                      [fieldTo]="filter.fieldTo"
                      [name]="filter.name"
                      [model]="model"
                      (updateModel)="updateModel($event)"
                      (closeFilter)="onCloseFilter()"
                    />
                  }
                }
              }
            }
          </div>
        }
      </div>
    </div>
    @if (isAnyFilterApplied) {
      <div class="filter__clear" (click)="clearAll()"></div>
    }
  </div>
</div>
