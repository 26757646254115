import { combineLatest, Observable, skip, startWith, takeWhile, timer } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

export function nonFlickerLoader<T>(data$: Observable<T>, delay = 1000, duration = 1000): Observable<T | undefined> {
  const loading$ = timer(delay, duration).pipe(
    map(counter => !counter),
    takeWhile<boolean>(Boolean, true),
    startWith(false)
  );

  return combineLatest([data$.pipe(startWith(undefined)), loading$]).pipe(
    takeWhile(([data, loading]) => data === undefined || loading, true),
    map(([data, loading]) => (loading ? undefined : data)),
    skip(1),
    distinctUntilChanged()
  );
}
