<div
  class="custom-tag"
  role="status"
  [attr.aria-label]="text"
  [style.background-color]="colors.backgroundColor"
  [class.custom-tag--small]="isSmall"
>
  <ng-content />
  <div class="custom-tag__text" [style.color]="colors.color">
    {{ text }}
  </div>
</div>
