import { Injectable } from '@angular/core';
import { INotification } from '@layout/notifications/models/notification.interface';
import { TranslateService } from '@ngx-translate/core';
import { NotificationSource } from '@layout/notifications/models/notification-source.enum';
import { NotificationType } from '@layout/notifications/models/notification-type.enum';

@Injectable({
  providedIn: 'root',
})
export class NotificationTemplateService {
  constructor(private translateService: TranslateService) {}

  getNotificationTitle({ source, type, zendeskTicketWasChanged, zendeskTicketFieldWasChanged }: INotification): string {
    if (source === NotificationSource.zendesk) {
      if (type === NotificationType.zendeskTicketWasChanged) {
        const interpolateParams = { ticketId: zendeskTicketWasChanged?.ticketId };
        return this.translateService.instant('notifications.zendesk-notification-title', interpolateParams);
      }
      if (type === NotificationType.zendeskTicketFieldWasChanged) {
        const interpolateParams = { ticketId: zendeskTicketFieldWasChanged?.ticketId };
        return this.translateService.instant('notifications.zendesk-notification-title', interpolateParams);
      }
    }
    return this.translateService.instant('notifications.default-notification-title');
  }

  getNotificationBody({ source, type, zendeskTicketWasChanged, zendeskTicketFieldWasChanged }: INotification): string {
    if (source === NotificationSource.zendesk) {
      if (type === NotificationType.zendeskTicketWasChanged) {
        return zendeskTicketWasChanged?.subject ?? '';
      }
      if (type === NotificationType.zendeskTicketFieldWasChanged) {
        return zendeskTicketFieldWasChanged?.subject ?? '';
      }
    }
    return this.translateService.instant('notifications.default-notification-body', { source });
  }
}
