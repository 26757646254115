import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { IUpcomingSession } from '@pages/professional-services/models/upcoming-sessions/upcoming-session.interface';

@Injectable({
  providedIn: 'root',
})
export class ProfessionalServicesUpcomingSessionsService {
  constructor(private httpClient: HttpClient) {}

  getUpcomingSessions(): Observable<IUpcomingSession[]> {
    const url = 'api/v1/professional-services-dashboard/upcoming-sessions';

    return this.httpClient.get<IUpcomingSession[]>(environment.portalApiUrl + url);
  }
}
