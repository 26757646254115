import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { hideToast } from '@core/errors/models/context';
import { environment } from '@env/environment';
import { IPartnerProgram } from '@layout/partner-program/models/partner-program-response.interface';
import { IPartnerProgramTier } from '@layout/partner-program/models/partner-program-tier.interface';

@Injectable({
  providedIn: 'root',
})
export class PartnerProgramService {
  private readonly httpClient = inject(HttpClient);

  getPartnerProgram(): Observable<IPartnerProgram> {
    const getPartnerProgramUrl = 'api/v1/partner-program';

    return this.httpClient.get<IPartnerProgram>(environment.portalApiUrl + getPartnerProgramUrl, {
      context: hideToast(),
    });
  }

  getPartnerProgramTiers(): Observable<IPartnerProgramTier[]> {
    const getPartnerProgramTiersUrl = 'api/v1/partner-program/tiers';

    return this.httpClient.get<IPartnerProgramTier[]>(environment.portalApiUrl + getPartnerProgramTiersUrl, {
      context: hideToast(),
    });
  }
}
