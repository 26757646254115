export enum Permission {
  BillingAndSubscriptions = 6,
  AccountManagers = 7,
  Insights = 9,
  Zendesk = 18,
  zendeskForOrganization = 19,
  Notifications = 17,
  IpAccessControl = 22,
  CyberInsurance = 32,
  PartnerProgram = 33,
  RemoteItSecurityManagement = 35,
  CooperBots = 41,
  AbsorbLearningSummary = 42,
  AbsorbUserCourses = 43,
  PushNotifications = 44,
  UserManagementView = 45,
  UserProfileRead = 46,
  ThirdPartyIdentityProviderRead = 48,
  FeaturesSetupRead = 49,
  CentralizedConfigurationRead = 51,
  LauncherModules = 52,
  VideoTutorialsRead = 56,
  ExecutiveReports = 58,
  VideoTutorialsEdit = 59,
  ProfessionalServicesDashboard = 60,
  Salesforce = 61,
  OrganizationMappingsView = 63,
  OrganizationMappingsModify = 64,
  OnboardingFirstMasterUserView = 65,
  OnboardingFirstMasterUserModify = 66,
  OnboardingSubscriptionsView = 67,
  OnboardingSubscriptionsModify = 68,
  KaseyaCommunity = 69,
  KaseyaSwag = 70,
}
