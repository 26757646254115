import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FilterTypes } from '@design/filter/models/filter-types.enum';
import { IFilterConfig } from '@design/filter/models/filter.interface';
import { BehaviorSubject, Observable } from 'rxjs';
import { IModule } from '@layout/launcher/models/module.interface';
import { map } from 'rxjs/operators';
import { IModuleInstance } from '@layout/launcher/models/module-instance.interface';
import { IIpAddressesQueryParams } from '@layout/tenant-picker-dialog/models/ip-addresses-query-params.interface';

@Component({
  selector: 'itc-instance-picker-dialog',
  templateUrl: './instance-picker-dialog.component.html',
  styleUrls: ['./instance-picker-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class InstancePickerDialogComponent implements OnInit, AfterViewInit {
  @Input() module: IModule;

  @ViewChild('search', { read: ElementRef }) search: ElementRef<HTMLElement>;
  @ViewChild('tenantsContainer') tenantsContainer: ElementRef<HTMLElement>;

  readonly model: IIpAddressesQueryParams = {
    searchKey: undefined,
  };
  readonly config: IFilterConfig<IIpAddressesQueryParams> = {
    search: {
      type: FilterTypes.search,
      name: 'tenant-picker-dialog.search',
      field: 'searchKey',
    },
  };

  tenantsContainerHeight: string;
  isScrolledToBottom = true;
  isScrolledToTop = true;

  instances$: Observable<IModuleInstance[]>;
  searchTerm$ = new BehaviorSubject<string | undefined>(undefined);

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.instances$ = this.searchTerm$.pipe(map(searchTerm => this.getSearchedModules(searchTerm)));
  }

  ngAfterViewInit(): void {
    this.tenantsContainerHeight = `calc(100% - ${this.search.nativeElement.clientHeight}px)`;
    this.changeDetectorRef.detectChanges();
    this.renderBottomLineIfScrollbarIsPresent();
  }

  onUpdateSearch(queryParams: IIpAddressesQueryParams): void {
    this.searchTerm$.next(queryParams.searchKey);
  }

  onClearSearch(): void {
    this.model.searchKey = undefined;
    this.searchTerm$.next(undefined);
  }

  private renderBottomLineIfScrollbarIsPresent(): void {
    const { clientHeight, scrollHeight } = this.tenantsContainer.nativeElement;
    if (clientHeight !== scrollHeight) {
      this.isScrolledToBottom = false;
    }
  }

  private getSearchedModules(searchTerm?: string): IModuleInstance[] {
    if (!searchTerm) {
      return this.module.instances;
    }
    return this.module.instances.filter(instance => instance.name.toLowerCase().includes(searchTerm.toLowerCase()));
  }
}
