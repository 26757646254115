import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TagColorName } from '@design/tag/models/tag-color-name.enum';
import { ITagColors } from '@design/tag/models/tag-colors.interface';
import { tagColorThemes } from '@design/tag/models/tag-color-themes';

@Component({
  selector: 'itc-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class TagComponent {
  @Input({ required: true }) text: string;
  @Input() isSmall = false;
  @Input() set color(color: TagColorName) {
    this.colors = tagColorThemes[color];
  }

  colors: ITagColors;
}
