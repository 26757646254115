import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { TenantsState } from '@core/sso/store/states/tenants.state';
import { LoadTenants, SelectTenant } from '@core/sso/store/actions/tenants.actions';
import { ITenant } from '@core/sso/models/tenant.interface';

@Injectable({
  providedIn: 'root',
})
export class TenantsFacade {
  readonly tenants$ = this.store.select(TenantsState.getTenants);
  readonly selectedTenant$ = this.store.select(TenantsState.getSelectedTenant);

  constructor(private store: Store) {}

  loadTenants(clientId: string): void {
    this.store.dispatch(new LoadTenants(clientId));
  }

  selectTenant(selectedTenant: ITenant): void {
    this.store.dispatch(new SelectTenant(selectedTenant));
  }
}
