<div class="input__container">
  <input
    class="input"
    [attr.aria-label]="(isFiltering ? 'filter.placeholder' : 'filter.placeholder-without-filter') | translate"
    [placeholder]="(isFiltering ? 'filter.placeholder' : 'filter.placeholder-without-filter') | translate"
    [attr.data-test-id]="inputTestId"
    [(ngModel)]="searchText"
    (keydown.enter)="search()"
    (click)="toggleSuggestions(true, $event)"
  />
  @if (isOpenSuggestions && isFiltering) {
    <itc-suggestions
      class="input__suggestions"
      [suggestions]="availableFilters"
      (selectFilter)="openFilter($event)"
      (closeSuggestions)="toggleSuggestions(false)"
    />
  }
</div>
