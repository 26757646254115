const dayInMillisecond = 1000 * 60 * 60 * 24;

export class DateTime {
  date: Date;

  constructor(date: Date) {
    this.date = new Date(date);
  }

  static today(): DateTime {
    const date = new Date();
    return new DateTime(new Date(date.getFullYear(), date.getMonth(), date.getDate()));
  }

  copy(): DateTime {
    return new DateTime(new Date(this.date.getTime()));
  }

  toUTCExcludingTimezone(): string {
    const utcDate = this.subtractMinutes(this.date.getTimezoneOffset());
    return utcDate.date.toISOString();
  }

  subtractDays(amount: number): DateTime {
    this.date.setDate(this.date.getDate() - amount);
    return this;
  }

  addDays(amount: number): DateTime {
    this.date.setDate(this.date.getDate() + amount);
    return this;
  }

  addMinutes(amount: number): DateTime {
    this.date.setMinutes(this.date.getMinutes() + amount);
    return this;
  }

  subtractMinutes(amount: number): DateTime {
    this.date.setMinutes(this.date.getMinutes() - amount);
    return this;
  }

  subtractMonths(amount: number): DateTime {
    this.date.setMonth(this.date.getMonth() - amount);
    return this;
  }

  addMonths(amount: number): DateTime {
    this.date.setMonth(this.date.getMonth() + amount);
    return this;
  }

  subtractYear(amount: number): DateTime {
    this.date.setFullYear(this.date.getFullYear() - amount);
    return this;
  }

  addYear(amount: number): DateTime {
    this.date.setFullYear(this.date.getFullYear() + amount);
    return this;
  }

  changeDayInDate(day: number): DateTime {
    this.date.setDate(day);
    return this;
  }

  changeMonthInDate(month: number): DateTime {
    this.date.setMonth(month);
    return this;
  }

  changeYearInDate(year: number): DateTime {
    this.date.setFullYear(year);
    return this;
  }

  getMonthFirstDay(): number {
    return this.getMonthFirstDate().date.getDay();
  }

  getMonthLastDay(): number {
    const lastDayOfTheMonth = this.getMonthLastDate().date.getDate();
    return this.changeDayInDate(lastDayOfTheMonth).date.getDay();
  }

  getMonthFirstDate(): DateTime {
    return this.changeDayInDate(1);
  }

  getMonthLastDate(): DateTime {
    return new DateTime(new Date(this.date.getFullYear(), this.date.getMonth() + 1, 0));
  }

  getIsTheSameMonth(dateTime: DateTime): boolean {
    return this.date.getMonth() === dateTime.date.getMonth();
  }

  getIsBefore(dateTime: DateTime): boolean {
    return this.date.getTime() < dateTime.date.getTime();
  }

  getIsAfter(dateTime: DateTime): boolean {
    return this.date.getTime() > dateTime.date.getTime();
  }

  getIsTheSameDate(dateTime: DateTime): boolean {
    return (
      this.date.getDate() === dateTime.date.getDate() &&
      this.date.getMonth() === dateTime.date.getMonth() &&
      this.date.getFullYear() === dateTime.date.getFullYear()
    );
  }

  get daysFromToday(): number {
    const differenceInDays = this.date.getTime() - DateTime.today().date.getTime();
    return Math.floor(differenceInDays / dayInMillisecond);
  }
}
