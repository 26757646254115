import { Component, Input } from '@angular/core';
import { IValidationError } from '@design/input/models/validation-errors.interface';
import { asNonNull } from '@shared/utils/as-non-null';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'itc-input-popup-error',
  templateUrl: './input-popup-error.component.html',
  styleUrls: ['./input-popup-error.component.scss'],
})
export class InputPopupErrorComponent {
  @Input() componentFormControl: FormControl;

  get error(): IValidationError {
    const errors = asNonNull(this.componentFormControl.errors);
    const errorKeys = Object.keys(errors);
    const firstErrorKey = errorKeys.find(errorKey => errors[errorKey]);

    return firstErrorKey ? errors[firstErrorKey] : null;
  }
}
