import { inject } from '@angular/core';
import { CanMatchFn, Router, UrlTree } from '@angular/router';
import { map, Observable, of } from 'rxjs';
import { PermissionsFacade } from '@core/permissions/facades/permissions.facade';
import { Feature } from '@core/features/models/feature.enum';
import { FeaturesFacade } from '@core/features/facades/features.facade';
import { switchMap } from 'rxjs/operators';
import { FirstTimeOnboardingCompletedFacade } from '@layout/first-time-onboarding/facades/first-time-onboarding-completed.facade';
import { filterIsNotUndefined } from '@shared/rxjs-operators/filter-is-not-undefined';

export const canMatchFirstTimeOnboardingCompleted: CanMatchFn = (): Observable<boolean | UrlTree> => {
  const firstTimeOnboardingCompletedFacade = inject(FirstTimeOnboardingCompletedFacade);
  const permissionsFacade = inject(PermissionsFacade);
  const featuresFacade = inject(FeaturesFacade);
  const router = inject(Router);

  if (!permissionsFacade.hasAccessToOnboardingFirstMasterUser) {
    return of(true);
  }

  return featuresFacade.isFeatureEnabled$(Feature.OnboardingFirstMasterUser).pipe(
    switchMap(isFirstTimeOnboardingFeatureEnabled => {
      if (!isFirstTimeOnboardingFeatureEnabled) {
        return of(true);
      }

      firstTimeOnboardingCompletedFacade.loadIsOnboardingCompletedIfNotLoaded();

      return firstTimeOnboardingCompletedFacade.isOnboardingCompleted$.pipe(
        filterIsNotUndefined(),
        map(isCompleted => {
          return isCompleted || router.createUrlTree(['onboarding']);
        })
      );
    })
  );
};

export const canMatchFirstTimeOnboardingAccess: CanMatchFn = (): Observable<boolean | UrlTree> => {
  const firstTimeOnboardingCompletedFacade = inject(FirstTimeOnboardingCompletedFacade);
  const router = inject(Router);

  firstTimeOnboardingCompletedFacade.loadIsOnboardingCompletedIfNotLoaded();

  return firstTimeOnboardingCompletedFacade.isOnboardingCompleted$.pipe(
    filterIsNotUndefined(),
    map(isCompleted => {
      return !isCompleted || router.createUrlTree(['']);
    })
  );
};
