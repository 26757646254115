import { Action, Selector, State, StateContext } from '@ngxs/store';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { IConnectedModulesResponse } from '@pages/organization-mapping/models/connected-modules-response.interface';
import { LoadConnectedModules } from '@pages/organization-mapping/store/actions/connected-modules.actions';
import { ConnectedModulesService } from '@pages/organization-mapping/services/connected-modules.service';
import { IConnectedModule } from '@pages/organization-mapping/models/connected-module.interface';
import { OrganizationMappingConfigurationStatus } from '@pages/organization-mapping/models/organization-mapping-configuration-status.enum';

interface IConnectedModulesStateModel {
  connectedModules?: IConnectedModulesResponse;
}

@State<IConnectedModulesStateModel>({
  name: 'organizationMappingConnectedModules',
})
@Injectable()
export class ConnectedModulesState {
  private readonly connectedModulesService = inject(ConnectedModulesService);

  @Selector()
  static configurationStatus(state: IConnectedModulesStateModel): OrganizationMappingConfigurationStatus | undefined {
    return state.connectedModules?.configurationStatus;
  }

  @Selector()
  static connectedModules(state: IConnectedModulesStateModel): IConnectedModule[] | undefined {
    return state.connectedModules?.modules;
  }

  @Action(LoadConnectedModules)
  loadConnectedModules(context: StateContext<IConnectedModulesStateModel>): Observable<IConnectedModulesResponse | undefined> {
    return this.connectedModulesService.getConnectedModules().pipe(
      tap(connectedModules => {
        context.patchState({ connectedModules });
      })
    );
  }
}
