import { Directive, HostListener, Input, Optional } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[itcRemoveSpaces]',
})
export class RemoveSpacesDirective {
  @Input('itcRemoveSpaces') enabled = false;

  constructor(@Optional() private control: NgControl) {}

  @HostListener('keydown', ['$event']) keyEvent(event: KeyboardEvent): string | boolean {
    if (this.enabled && this.control) {
      return this.checkEmptySpace(event.key);
    }
    return event.key;
  }

  @HostListener('paste', ['$event']) blockPaste(): void {
    if (this.enabled && this.control) {
      this.removeSpacesFor(this.control);
    }
  }

  private checkEmptySpace(value: string): boolean {
    const regexp = /\s/g;
    return !regexp.test(value);
  }

  private removeSpacesFor(control: NgControl): void {
    const value = control.control?.value;
    const newValue = value.replace(/\s/g, '');

    if (value !== newValue) {
      control.control?.setValue(newValue);
    }
  }
}
