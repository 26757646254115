import { Injectable } from '@angular/core';
import { Product } from '@core/product-configuration/models/product.enum';
import { DefaultProductTheme, ProductThemes } from '@core/product-configuration/models/product-themes';
import { IProductTheme } from '@core/product-configuration/models/product-theme.interface';

@Injectable({
  providedIn: 'root',
})
export class ProductThemesFacade {
  getProductBackgroundColor(productId: Product): string {
    return this.getProductTheme(productId).bgColor;
  }

  getProductIconPath(productId: Product): string {
    return `assets/icons/products/${this.getProductTheme(productId).iconName}`;
  }

  private getProductTheme(productId: Product): IProductTheme {
    return ProductThemes[productId] ?? DefaultProductTheme;
  }
}
