import { DestroyRef, Injectable } from '@angular/core';
import { IAuthorizationData } from '@core/authorization/models/authorization-data.interface';
import { filter, fromEvent, Observable, ReplaySubject, Subject } from 'rxjs';
import { StorageService } from '@core/storage/services/storage.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { LocalStorageKey } from '@core/storage/models/local-storage-key.enum';

@Injectable({
  providedIn: 'root',
})
export class AuthorizationService {
  private loginObservable = new ReplaySubject<IAuthorizationData>(1);
  private logoutObservable = new Subject<void>();

  constructor(
    private storageService: StorageService,
    private destroyRef: DestroyRef
  ) {
    this.subscribeToStorageAuthorizationDataChanges();
    if (this.authorizationData) {
      this.loginObservable.next(this.authorizationData);
      return;
    }

    this.logOut();
  }

  get onLogin(): Observable<IAuthorizationData> {
    return this.loginObservable;
  }

  get onLogout(): Observable<void> {
    return this.logoutObservable;
  }

  isAuthorized(): boolean {
    return !!this.authorizationData;
  }

  isImpersonatedUser(): boolean {
    if (!this.authorizationData) {
      return false;
    }

    return this.authorizationData.isImpersonated;
  }

  logIn(authorizationData: IAuthorizationData): void {
    if (authorizationData) {
      this.setAuthorizationData(authorizationData);
      this.loginObservable.next(authorizationData);
    }
  }

  logOut(): void {
    this.storageService.clearAuthorizationData();
    this.logoutObservable.next();
  }

  setAuthorizationData(authorizationData: IAuthorizationData): void {
    this.storageService.setAuthorizationData(authorizationData);
  }

  private subscribeToStorageAuthorizationDataChanges(): void {
    fromEvent<StorageEvent>(window, 'storage')
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter(({ key, url }) => url.includes(location.origin) && key === LocalStorageKey.AuthorizationData)
      )
      .subscribe(({ newValue: newAuthorizationData, oldValue: oldAuthorizationData }) => {
        const loggedOutFromAnotherTab = !newAuthorizationData && oldAuthorizationData;
        const loggedInFromAnotherTab = newAuthorizationData && !oldAuthorizationData;

        if (loggedOutFromAnotherTab || loggedInFromAnotherTab) {
          window.location.reload();
        }
      });
  }

  private get authorizationData(): IAuthorizationData | undefined {
    return this.storageService.getAuthorizationData();
  }
}
