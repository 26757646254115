import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TooltipDirective } from '@design/tooltip/directives/tooltip.directive';
import { TooltipComponent } from '@design/tooltip/components/tooltip/tooltip.component';
import { TooltipWrapperComponent } from '@design/tooltip/components/tooltip-wrapper/tooltip-wrapper.component';

@NgModule({
  declarations: [TooltipComponent, TooltipDirective, TooltipWrapperComponent],
  imports: [CommonModule, TranslateModule, ReactiveFormsModule],
  exports: [TooltipComponent, TooltipWrapperComponent, TooltipDirective],
})
export class TooltipModule {}
