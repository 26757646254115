import { inject } from '@angular/core';
import { CanMatchFn, Router, UrlTree } from '@angular/router';
import { map, Observable } from 'rxjs';
import { K365SetupGuidesAccessFacade } from '@layout/k365-setup-guides-access/facades/k365-setup-guides-access.facade';

export const canMatchSetupGuidesAccess: CanMatchFn = (): Observable<boolean | UrlTree> => {
  const k365SetupGuidesAccessFacade = inject(K365SetupGuidesAccessFacade);
  const router = inject(Router);

  return k365SetupGuidesAccessFacade.setupGuidesAccess$.pipe(map(isAccessible => isAccessible || router.createUrlTree([''])));
};
