import { ChangeDetectorRef, Directive, EmbeddedViewRef, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Feature } from '@core/features/models/feature.enum';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FeaturesFacade } from '@core/features/facades/features.facade';

@Directive({
  standalone: true,
  selector: '[itcFeature]',
})
export class FeaturesDirective implements OnInit, OnDestroy {
  @Input() itcFeature?: Feature;

  private readonly unsubscribe$ = new Subject<void>();
  private embeddedViewRef: EmbeddedViewRef<never>;

  constructor(
    private templateRef: TemplateRef<never>,
    private viewContainer: ViewContainerRef,
    private featuresFacade: FeaturesFacade,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    if (!this.itcFeature) {
      this.createOrReattachEmbeddedVew();
      return;
    }

    this.checkFeatureAccess(this.itcFeature);
  }

  private checkFeatureAccess(feature: Feature): void {
    this.featuresFacade.features$.pipe(takeUntil(this.unsubscribe$)).subscribe(features => {
      if (!features) {
        return;
      }

      if (features[feature]) {
        this.createOrReattachEmbeddedVew();
        this.changeDetectorRef.markForCheck();
        return;
      }

      this.viewContainer.clear();
    });
  }

  private createOrReattachEmbeddedVew(): void {
    if (this.embeddedViewRef) {
      this.embeddedViewRef.reattach();
      return;
    }

    this.embeddedViewRef = this.viewContainer.createEmbeddedView(this.templateRef);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
