export enum Product {
  vsa = 1,
  traverse = 2,
  itGlue = 3,
  myGlue = 4,
  networkGlue = 5,
  bms = 6,
  vorex = 7,
  myItProcess = 8,
  passly = 9,
  darkWebId = 10,
  bullPhishId = 11,
  graphus = 12,
  rocketCyber = 13,
  unitrends = 14,
  endpointBackup = 15,
  spanningMicrosoft365 = 16,
  spanningGSuite = 17,
  spanningSalesforce = 18,
  complianceManager = 19,
  networkDetectivePro = 20,
  vulScan = 21,
  cyberHawk = 22,
  rapidFireTools = 23,
  cooper = 24,
  vsax = 25,
  dattoAutotask = 26,
  uniview = 27,
  connectBooster = 28,
  quoteManager = 29,
  dattoNetworking = 30,
  dattoPortal = 31,
  dattoEndpointDetectionAndResponse = 32,
  dattoRMM = 33,
  dattoContinuityForMicrosoftAzure = 34,
  dattoBCDR = 35,
  dattoCloudContinuityForPCs = 36,
  dattoWorkplace = 37,
  dattoSaasProtection = 38,
  dattoSaasDefense = 39,
  poweredServices = 40,
  dattoFileProtection = 41,
  audIT = 42,
  securePayments = 43,
  endpointBackupWithDisasterRecovery = 44,
  vPenTest = 45,
  unitrendsEndpointBackup = 49,
  dattoEndpointBackup = 50,
  kaseyaMDM = 51,
  saasAlerts = 55,
  eirBackup = 56,
}
