import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { TagColorName } from '@design/tag/models/tag-color-name.enum';
import { NavigationLinkComponent } from '@layout/left-navigation/components/navigation-link/navigation-link.component';
import { TranslateModule } from '@ngx-translate/core';
import { TagComponent } from '@design/tag/components/tag.component';

@Component({
  standalone: true,
  selector: 'itc-navigation-tagged-link',
  templateUrl: './navigation-tagged-link.component.html',
  styleUrls: ['./navigation-tagged-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  imports: [NavigationLinkComponent, TranslateModule, TagComponent],
})
export class NavigationTaggedLinkComponent {
  @Input({ required: true }) routerLink: string;
  @Input({ required: true }) iconSrc: string;
  @Input({ required: true }) linkName: string;
  @Input() isTooltipEnabled = false;
  @Input() tagTextTranslationKey = 'navigation.tags.new';
  @Input() tagColorName = TagColorName.New;

  readonly TagColorName = TagColorName;
}
