<div class="suggested-tutorials">
  @switch (suggestedTutorialsListState$ | async) {
    @case (tutorialsListState.LoadedList) {
      @if (suggestedTutorialsByCategory$ | async; as suggestedTutorialsByCategory) {
        @for (categoryTutorials of suggestedTutorialsByCategory | keyvalue; track categoryTutorials) {
          <itc-suggested-tutorials-by-category
            [category]="asTutorialCategory(categoryTutorials.key)"
            [tutorials]="categoryTutorials.value"
            [selectedTutorialId]="selectedTutorial.id"
          />
        }
      }
    }

    @case (tutorialsListState.Loading) {
      <div class="suggested-tutorials__spinner-container">
        <itc-progress-spinner [spinnerSize]="30" />
      </div>
    }

    @case (tutorialsListState.LoadedEmptyList) {
      <div class="suggested-tutorials__empty-list">
        <img src="assets/icons/tutorials/empty-state.svg" class="suggested-tutorials__empty-list-image" />
        <div class="suggested-tutorials__empty-list-title">{{ 'tutorials.suggested-tutorials.empty-list' | translate }}</div>
      </div>
    }

    @case (tutorialsListState.LoadingError) {
      <div class="suggested-tutorials__error">
        <img src="assets/icons/error-loading.svg" class="suggested-tutorials__error-image" />
        <div class="suggested-tutorials__error-title">{{ 'tutorials.suggested-tutorials.error' | translate }}</div>
      </div>
    }
  }
</div>
