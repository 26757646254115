import { Product } from '@core/product-configuration/models/product.enum';
import { IProductTheme } from '@core/product-configuration/models/product-theme.interface';
import { getCssVariableValue } from '@shared/utils/css-variable-value';

export const DefaultProductTheme: IProductTheme = {
  bgColor: 'rgba(79, 100, 238, 0.12)',
  color: getCssVariableValue('--primary-color'),
  iconName: 'default.svg',
};

export const ProductThemes: { [key in Product]: IProductTheme } = {
  [Product.vsa]: {
    bgColor: 'rgba(0, 89, 184, 0.12)',
    color: '#0059B8',
    iconName: 'vsa.svg',
  },
  [Product.traverse]: {
    bgColor: 'rgba(11, 152, 137, 0.12)',
    color: '#0B9889',
    iconName: 'traverse.svg',
  },
  [Product.itGlue]: {
    bgColor: 'rgba(152, 0, 138, 0.12)',
    color: '#98008A',
    iconName: 'it-glue.svg',
  },
  [Product.myGlue]: {
    bgColor: 'rgba(8, 0, 107, 0.12)',
    color: '#08006B',
    iconName: 'my-glue.svg',
  },
  [Product.networkGlue]: {
    bgColor: 'rgba(0, 82, 109, 0.12)',
    color: '#00526D',
    iconName: 'network-glue.svg',
  },
  [Product.bms]: {
    bgColor: 'rgba(21, 116, 168, 0.12)',
    color: '#1574A8',
    iconName: 'bms.svg',
  },
  [Product.vorex]: {
    bgColor: 'rgba(224, 50, 69, 0.12)',
    color: '#E03245',
    iconName: 'vorex.svg',
  },
  [Product.myItProcess]: {
    bgColor: 'rgba(0, 0, 0, 0.12)',
    color: '#000000',
    iconName: 'my-it-process.svg',
  },
  [Product.passly]: {
    bgColor: 'rgba(0, 133, 194, 0.12)',
    color: '#0085C2',
    iconName: 'passly.svg',
  },
  [Product.darkWebId]: {
    bgColor: 'rgba(91, 7, 160, 0.12)',
    color: '#5B07A0',
    iconName: 'dark-web.svg',
  },
  [Product.bullPhishId]: {
    bgColor: 'rgba(34, 52, 157, 0.12)',
    color: '#22349D',
    iconName: 'bullphish.svg',
  },
  [Product.graphus]: {
    bgColor: 'rgba(187, 3, 106, 0.12)',
    color: '#BB036A',
    iconName: 'graphus.svg',
  },
  [Product.rocketCyber]: {
    bgColor: 'rgba(10, 0.128, 84, 0.12)',
    color: '#0A8054',
    iconName: 'rocket-cyber.svg',
  },
  [Product.unitrends]: {
    bgColor: 'rgba(0, 0, 0, 0.12)',
    color: '#000000',
    iconName: 'unitrends.svg',
  },
  [Product.endpointBackup]: {
    bgColor: 'rgba(6, 139, 24, 0.12)',
    color: '#068B18',
    iconName: 'endpoint-backup.svg',
  },
  [Product.spanningMicrosoft365]: {
    bgColor: 'rgba(216, 28, 3, 0.12)',
    color: '#D81C03',
    iconName: '365-backup.svg',
  },
  [Product.spanningGSuite]: {
    bgColor: 'rgba(4, 47, 67, 0.12)',
    color: '#042F43',
    iconName: 'spanning.svg',
  },
  [Product.spanningSalesforce]: {
    bgColor: 'rgba(0, 96, 0.125, 0.12)',
    color: '#00607D',
    iconName: 'salesforce-backup.svg',
  },
  [Product.complianceManager]: {
    bgColor: 'rgba(28, 65, 161, 0.12)',
    color: '#1C41A1',
    iconName: 'compliance-manager.svg',
  },
  [Product.networkDetectivePro]: {
    bgColor: 'rgba(168, 97, 0, 0.12)',
    color: '#A86100',
    iconName: 'network-detective.svg',
  },
  [Product.vulScan]: {
    bgColor: 'rgba(0, 101, 108, 0.12)',
    color: '#00656C',
    iconName: 'vul-scan.svg',
  },
  [Product.cyberHawk]: {
    bgColor: 'rgba(190, 11, 17, 0.12)',
    color: '#BE0B11',
    iconName: 'cyber-hawk.svg',
  },
  [Product.rapidFireTools]: {
    bgColor: 'rgba(223, 101, 31, 0.12)',
    color: '#DF651F',
    iconName: 'rapid-fire-tools.svg',
  },
  [Product.cooper]: {
    bgColor: 'rgba(79, 100, 238, 0.12)',
    color: getCssVariableValue('--primary-color'),
    iconName: 'cooper.svg',
  },
  [Product.vsax]: {
    bgColor: 'rgba(79, 100, 238, 0.12)',
    color: getCssVariableValue('--primary-color'),
    iconName: 'vsax.svg',
  },
  [Product.dattoAutotask]: {
    bgColor: 'rgba(55, 106, 148, 0.12)',
    color: '#376A94',
    iconName: 'datto-autotask.svg',
  },
  [Product.uniview]: {
    bgColor: 'rgba(67, 74, 84, 0.12)',
    color: '#434A54',
    iconName: 'uniview.svg',
  },
  [Product.connectBooster]: {
    bgColor: 'rgba(34, 89, 168, 0.12)',
    color: '#2259A8',
    iconName: 'connect-booster.svg',
  },
  [Product.quoteManager]: {
    bgColor: 'rgba(85, 185, 164, 0.12)',
    color: '#55B9A4',
    iconName: 'quote-manager.svg',
  },
  [Product.dattoNetworking]: {
    bgColor: 'rgba(25, 158, 217, 0.12)',
    color: '#199ED9',
    iconName: 'datto-network-manager.svg',
  },
  [Product.dattoEndpointDetectionAndResponse]: {
    bgColor: 'rgba(12, 140, 255, 0.12)',
    color: '#0C8CFF',
    iconName: 'datto-edr.svg',
  },
  [Product.dattoRMM]: {
    bgColor: 'rgba(60, 115, 140, 0.12)',
    color: '#3C738C',
    iconName: 'datto-rmm.svg',
  },
  [Product.dattoContinuityForMicrosoftAzure]: {
    bgColor: 'rgba(25, 158, 217, 0.12)',
    color: '#199ED9',
    iconName: 'datto-dcma.svg',
  },
  [Product.dattoBCDR]: {
    bgColor: 'rgba(25, 158, 217, 0.12)',
    color: '#199ED9',
    iconName: 'datto-bcdr.svg',
  },
  [Product.dattoCloudContinuityForPCs]: {
    bgColor: 'rgba(25, 158, 217, 0.12)',
    color: '#199ED9',
    iconName: 'datto-cc4pc.svg',
  },
  [Product.dattoWorkplace]: {
    bgColor: 'rgba(61, 126, 168, 0.12)',
    color: '#3D7EA8',
    iconName: 'datto-workplace.svg',
  },
  [Product.dattoSaasProtection]: {
    bgColor: 'rgba(25, 158, 217, 0.12)',
    color: '#199ED9',
    iconName: 'datto-saas-protection.svg',
  },
  [Product.dattoSaasDefense]: {
    bgColor: 'rgba(97, 34, 170, 0.12)',
    color: '#6122AA',
    iconName: 'datto-saas-defense.svg',
  },
  [Product.poweredServices]: {
    bgColor: 'rgba(86, 185, 233, 0.12)',
    color: '#56B9E9',
    iconName: 'powered-services.svg',
  },
  [Product.dattoFileProtection]: {
    bgColor: 'rgba(61, 126, 168, 0.12)',
    color: '#3D7EA8',
    iconName: 'datto-file-protection.svg',
  },
  [Product.dattoPortal]: {
    bgColor: 'rgba(25, 158, 217, 0.12)',
    color: '#199ED9',
    iconName: 'datto-portal.svg',
  },
  [Product.audIT]: {
    bgColor: 'rgba(0, 118, 59, 0.12)',
    color: '#00763B',
    iconName: 'audit.svg',
  },
  [Product.securePayments]: {
    bgColor: 'rgba(53, 185, 156, 0.12)',
    color: '#35B99C',
    iconName: 'secure-payments.svg',
  },
  [Product.endpointBackupWithDisasterRecovery]: {
    bgColor: 'rgba(25, 158, 217, 0.12)',
    color: '#199ED9',
    iconName: 'datto-cc4pc.svg',
  },
  [Product.vPenTest]: {
    bgColor: 'rgba(235, 49, 49, 0.12)',
    color: '#EB3131',
    iconName: 'v-pen-test.svg',
  },
  [Product.unitrendsEndpointBackup]: {
    bgColor: 'rgba(0, 0, 0, 0.12)',
    color: '#000000',
    iconName: 'unitrends-endpoint-backup.svg',
  },
  [Product.dattoEndpointBackup]: {
    bgColor: 'rgba(0, 128, 198, 0.12)',
    color: '#0080C6',
    iconName: 'datto-endpoint-backup.svg',
  },
  [Product.kaseyaMDM]: {
    bgColor: 'rgba(214, 168, 51, 0.12)',
    color: '#D6A833',
    iconName: 'kaseya-mdm.svg',
  },
  [Product.saasAlerts]: {
    bgColor: 'rgba(73, 121, 56, 0.12)',
    color: '#497938',
    iconName: 'saas-alerts.svg',
  },
  [Product.eirBackup]: {
    bgColor: 'rgba(67, 74, 84, 0.12)',
    color: '#434A54',
    iconName: 'eir-backup.svg',
  },
};
