<div #content class="launcher__content" [style.height]="launcherHeight">
  <div class="launcher__header">
    <div class="launcher__header-title">
      {{ 'launcher.my-it-complete' | translate }}
    </div>
    <div class="launcher__header-icon" (click)="toggleWidget()"></div>
  </div>

  @if ((loading$ | async) === false) {
    <div class="launcher__sections">
      @if (modules$ | async; as modules) {
        <div class="launcher__section">
          @if (modules.length) {
            <div class="launcher__section-main">
              @for (module of modules; track module.moduleId; let index = $index) {
                <itc-launcher-item [isTooltipRightAligned]="isInRightColumn(index)" [module]="module" (openedModule)="toggleWidget()" />
              }
            </div>
          } @else {
            <div class="launcher__no-modules">
              <img class="launcher__no-modules-image" src="assets/icons/launcher/no-modules.svg" />
              <div class="launcher__no-modules-title">{{ 'launcher.no-modules.title' | translate }}</div>
              <div class="launcher__no-modules-description">
                <span>{{ 'launcher.no-modules.description' | translate }}</span>
                <a class="launcher__no-modules-link" target="_blank" [href]="knowledgeBaseLink">
                  {{ 'launcher.no-modules.here' | translate }}
                </a>
                <span>.</span>
              </div>
            </div>
          }
        </div>
      }

      @if (discoverableModules$ | async; as discoverableModules) {
        @if (discoverableModules.length) {
          <div class="launcher__section">
            <div class="launcher__section-header">
              {{ 'launcher.discover-more' | translate }}
            </div>
            <div class="launcher__section-main launcher__section-more">
              @for (discoverableModule of discoverableModules; track discoverableModule.moduleId) {
                <itc-discoverable-launcher-item [discoverableModule]="discoverableModule" (openedModule)="toggleWidget()" />
              }
            </div>
          </div>
        }
      }
    </div>
  } @else {
    <div class="launcher__spinner">
      <itc-progress-spinner [spinnerSize]="28" />
    </div>
  }
</div>
