<div class="tutorials-categories">
  <span
    *ngFor="let category of categories"
    class="tutorials-categories__category"
    [class.tutorials-categories__category--active]="isActiveCategory(category.category)"
    (click)="changeCategory(category.category)"
  >
    {{ category.categoryKey | translate }}
  </span>
</div>
