import { Action, Actions, ofActionDispatched, Selector, State, StateContext } from '@ngxs/store';
import { inject, Injectable } from '@angular/core';
import { concatMap, finalize, interval, Observable, takeUntil, tap } from 'rxjs';
import { K365SetupGuidesService } from '@pages/k365-setup-guides/services/k365-setup-guides.service';
import { IK365SubscriptionModule } from '@pages/k365-setup-guides/models/k365-subscription-module.interface';
import {
  LoadK365SubscriptionModules,
  PollK365SubscriptionModules,
  StopPollingK365SubscriptionModules,
} from '@pages/k365-setup-guides/store/actions/k365-subscription-modules.actions';

interface IK365SubscriptionModulesStateModel {
  modules?: IK365SubscriptionModule[];
  loading: boolean;
}

@State<IK365SubscriptionModulesStateModel>({
  name: 'k365SubscriptionModules',
  defaults: {
    loading: false,
  },
})
@Injectable()
export class K365SubscriptionModulesState {
  private readonly k365SetupGuidesService = inject(K365SetupGuidesService);
  private readonly actions$ = inject(Actions);
  private readonly pollingIntervalMs = 5000;

  @Selector()
  static modules(state: IK365SubscriptionModulesStateModel): IK365SubscriptionModule[] | undefined {
    return state.modules;
  }

  @Selector()
  static loading(state: IK365SubscriptionModulesStateModel): boolean {
    return state.loading;
  }

  @Action(LoadK365SubscriptionModules, { cancelUncompleted: true })
  loadK365SubscriptionModules(
    context: StateContext<IK365SubscriptionModulesStateModel>,
    { subscriptionId }: LoadK365SubscriptionModules
  ): Observable<IK365SubscriptionModule[]> {
    context.patchState({ loading: true });

    return this.k365SetupGuidesService.getK365SubscriptionModules(subscriptionId).pipe(
      tap(modules => context.patchState({ modules })),
      tap(() => context.dispatch(new PollK365SubscriptionModules(subscriptionId))),
      finalize(() => context.patchState({ loading: false }))
    );
  }

  @Action(PollK365SubscriptionModules)
  pollK365SubscriptionModules(
    context: StateContext<IK365SubscriptionModulesStateModel>,
    { subscriptionId }: PollK365SubscriptionModules
  ): Observable<IK365SubscriptionModule[]> {
    return interval(this.pollingIntervalMs).pipe(
      takeUntil(this.actions$.pipe(ofActionDispatched(StopPollingK365SubscriptionModules))),
      concatMap(() => this.k365SetupGuidesService.getK365SubscriptionModules(subscriptionId)),
      tap(modules => context.patchState({ modules }))
    );
  }
}
