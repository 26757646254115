<div *ngIf="selectedTutorial$ | async as selectedTutorial" class="tutorial-preview">
  <iframe
    *ngIf="selectedTutorial.videoUrl"
    class="tutorial-preview__video"
    title="vimeo-player"
    mozallowfullscreen
    webkitallowfullscreen
    allowfullscreen
    [src]="videoUrl | safe"
  ></iframe>
  <div class="tutorial-preview__header">
    <div class="tutorial-preview__left-section">
      <div class="tutorial-preview__header-title-with-tag">
        <div class="tutorial-preview__title">{{ selectedTutorial.title }}</div>
        <itc-tag class="tutorial-preview__category" [text]="categoryTranslationKey | translate" [color]="tagColorName" />
      </div>
      <div *ngIf="selectedTutorial.dateOfCreation" class="tutorial-preview__date">
        {{ selectedTutorial.dateOfCreation | date: 'mediumDate' }}
      </div>
    </div>
    <button class="tutorial-preview__open-in-vimeo-button" (click)="openVideoLink(selectedTutorial.videoUrl)">
      {{ 'tutorials.tutorial-preview.open-in-vimeo' | translate }}
    </button>
  </div>
  <div *ngIf="selectedTutorial.description" class="tutorial-preview__description">{{ selectedTutorial.description }}</div>
</div>
