import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'itc-custom-modal',
  templateUrl: './custom-modal.component.html',
  styleUrls: ['./custom-modal.component.scss'],
})
export class CustomModalComponent {
  @Input() isCloseButtonVisible = true;

  constructor(private activeModal: NgbActiveModal) {}

  close(isConfirmed: boolean): void {
    this.activeModal.close(isConfirmed);
  }
}
