<div class="days">
  @for (dayName of daysNames; track dayName) {
    <div class="day">{{ dayName | translate }}</div>
  }
</div>
<div class="weeks">
  @for (week of weeks; track week) {
    <div class="week">
      @for (day of week; track day) {
        <div
          class="date"
          [ngClass]="{
            today: day.today,
            selected: day.selected,
            'another-month': !isSelectedMonth(day.date),
            disabled: isDisabledDays(day.date),
          }"
          (click)="selectDate($event, day.date)"
        >
          <span class="number">{{ day.date.date | date: 'd' }}</span>
        </div>
      }
    </div>
  }
</div>
