import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { finalize, tap } from 'rxjs/operators';
import { LoadManagers } from '@layout/organization-managers/store/actions/organization-managers.actions';
import { OrganizationManagersService } from '@layout/organization-managers/services/organization-managers.service';
import { IManager } from '@layout/organization-managers/models/manager.interface';
import { IOrganizationManagers } from '@layout/organization-managers/models/organization-managers.interface';
import { Observable } from 'rxjs';

interface IOrganizationManagersStateModel {
  data: {
    accountManager: IManager | null;
    relationshipManager: IManager | null;
  };
  loading: boolean;
}

@State<IOrganizationManagersStateModel>({
  name: 'organizationManagers',
  defaults: {
    data: {
      accountManager: null,
      relationshipManager: null,
    },
    loading: false,
  },
})
@Injectable()
export class OrganizationManagersState {
  constructor(private organizationManagersService: OrganizationManagersService) {}

  @Selector()
  static accountManager(state: IOrganizationManagersStateModel): IManager | null {
    return state.data.accountManager;
  }

  @Selector()
  static relationshipManager(state: IOrganizationManagersStateModel): IManager | null {
    return state.data.relationshipManager;
  }

  @Selector()
  static loading(state: IOrganizationManagersStateModel): boolean {
    return state.loading;
  }

  @Action(LoadManagers)
  loadManagers(context: StateContext<IOrganizationManagersStateModel>): Observable<IOrganizationManagers> {
    const { accountManager, relationshipManager } = context.getState().data;

    if (!accountManager && !relationshipManager) {
      context.patchState({ loading: true });
    }

    return this.organizationManagersService.getOrganizationManagers().pipe(
      finalize(() => context.patchState({ loading: false })),
      tap((managers: IOrganizationManagers) => context.patchState({ data: managers }))
    );
  }
}
