import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TutorialsFacade } from '@layout/tutorials/facades/tutorials.facade';
import { ITutorial } from '@layout/tutorials/models/tutorial.interface';
import { TutorialsListState } from '@layout/tutorials/models/tutorials-list-state.enum';
import { TutorialCategory } from '@layout/tutorials/models/tutorial-category.enum';

@Component({
  selector: 'itc-suggested-tutorials',
  templateUrl: './suggested-tutorials.component.html',
  styleUrls: ['./suggested-tutorials.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SuggestedTutorialsComponent {
  @Input() selectedTutorial: ITutorial;

  readonly tutorialsListState = TutorialsListState;
  suggestedTutorialsByCategory$ = this.tutorialsFacade.suggestedTutorialsByCategory$;
  suggestedTutorialsListState$ = this.tutorialsFacade.suggestedTutorialsListState$;

  constructor(private tutorialsFacade: TutorialsFacade) {}

  asTutorialCategory(tutorialCategory: string): TutorialCategory {
    return Number(tutorialCategory) as TutorialCategory;
  }
}
