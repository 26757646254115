import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class K365SetupGuidesAccessService {
  private readonly http = inject(HttpClient);

  getSetupGuidesAccess(): Observable<boolean> {
    const url = 'api/v1/onboarding/subscriptions/kaseya-365/any';

    return this.http.get<boolean>(environment.portalApiUrl + url);
  }
}
