import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { SidePanelComponent } from '@design/side-panel/components/side-panel/side-panel.component';
import { TranslateModule } from '@ngx-translate/core';
import { SidePanelTemplateComponent } from '@design/side-panel/components/side-panel-template/side-panel-template.component';
import { HasScrollbarDirective } from '@design/scroll-listener/directives/has-scrollbar.directive';
import { ScrollListenerModule } from '@design/scroll-listener/scroll-listener.module';

@NgModule({
  declarations: [SidePanelComponent, SidePanelTemplateComponent],
  imports: [CommonModule, NgOptimizedImage, TranslateModule, HasScrollbarDirective, ScrollListenerModule],
  exports: [SidePanelComponent, SidePanelTemplateComponent],
})
export class SidePanelModule {}
