import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { defaultLanguage } from '@core/translation/models/default-language';

@Injectable()
export class TranslationApiService {
  getLanguage(): Observable<string> {
    return of(defaultLanguage);
  }
}
