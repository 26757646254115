import { Injectable } from '@angular/core';
import { AuthorizationService } from '@core/authorization/services/authorization.service';
import { FeaturesFacade } from '@core/features/facades/features.facade';
import { environment } from '@env/environment';
import { IAuthorizationData } from '@core/authorization/models/authorization-data.interface';
import { IWalkMeData } from '@core/walk-me/models/walk-me-data.interface';
import { catchError, take, takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, of } from 'rxjs';
import { WalkMeApiService } from '@core/walk-me/services/walk-me-api.service';
import { ApplicationVersionFacade } from '@core/application-version/facades/application-version.facade';
import { ICustomWindow } from '@core/walk-me/models/custom-window.type';
import { Feature } from '@core/features/models/feature.enum';

@Injectable({ providedIn: 'root' })
export class WalkMeService {
  private window = window as unknown as ICustomWindow;

  constructor(
    private authorizationService: AuthorizationService,
    private featuresFacade: FeaturesFacade,
    private applicationVersionFacade: ApplicationVersionFacade,
    private translateService: TranslateService,
    private walkMeApiService: WalkMeApiService
  ) {
    this.authorizationService.onLogin.subscribe(authorizationData => this.initWalkMe(authorizationData));
    this.authorizationService.onLogout.subscribe(() => this.removeWalkMe());
  }

  private initWalkMe(authorizationData: IAuthorizationData): void {
    this.featuresFacade
      .isFeatureEnabled$(Feature.WalkMe)
      .pipe(take(1), takeUntil(this.authorizationService.onLogout))
      .subscribe(isEnabled => {
        if (isEnabled) {
          this.setWalkMeVariables(authorizationData);
          this.createWalkMeSnippet();
          this.setWalkMeConfig();
        }
      });
  }

  private setWalkMeVariables(authorizationData: IAuthorizationData): void {
    const nowDate = new Date();
    const [utcDate] = nowDate.toISOString().split('T');
    const utcTime = this.getUTCTime(nowDate);

    combineLatest([
      this.applicationVersionFacade.applicationVersion$,
      this.walkMeApiService.getWalkMeData().pipe(
        catchError(() => {
          return of(null);
        })
      ),
    ]).subscribe(([applicationVersion, walkMeDataResponse]) => {
      this.window.walkMeData = {
        userId: authorizationData.userTrackingId,
        tenantId: authorizationData.organizationTrackingId,
        utcDate,
        utcTime,
        applicationVersionNumber: applicationVersion,
        firstName: authorizationData.userFirstName,
        lastName: authorizationData.userLastName,
        emailAddress: walkMeDataResponse?.encryptedEmailAddress,
        companyName: authorizationData.organizationName,
        role: walkMeDataResponse?.role,
        featureAccess: authorizationData.permissions.join('|'),
        locale: this.translateService.currentLang,
        userCreationDaysElapsed: walkMeDataResponse?.userCreationDaysElapsed,
        databaseCreationDaysElapsed: walkMeDataResponse?.organizationCreationDaysElapsed,
        usersCount: walkMeDataResponse?.usersCount,
        isRootUser: walkMeDataResponse?.isRootUser,
        salesforceId: walkMeDataResponse?.salesforceId,
        isKaseya365: walkMeDataResponse?.isKaseya365,
        isAccountTrial: walkMeDataResponse?.isAccountTrial,
      } as IWalkMeData;
    });
  }

  private createWalkMeSnippet(): void {
    const walkMeScript: HTMLScriptElement = document.createElement('script');
    walkMeScript.async = true;
    walkMeScript.src = environment.walkMeSnippetUrl;
    document.head.prepend(walkMeScript);
  }

  private setWalkMeConfig(): void {
    this.window._walkmeConfig = { smartLoad: true };
  }

  private removeWalkMe(): void {
    this.window._walkMe?.removeWalkMe();
    this.window.walkMeData = undefined;
  }

  private getUTCTime(date: Date): string {
    return date
      .toLocaleTimeString(undefined, {
        timeStyle: 'short',
        hour12: false,
        timeZone: 'UTC',
      })
      .replace(':', '');
  }
}
