import { K365OnboardingSubscriptionsState } from '@pages/k365-setup-guides/store/states/k365-onboarding-subscriptions.state';
import { K365SubscriptionActivationModulesState } from '@pages/k365-setup-guides/store/states/k365-subscription-activation-modules.state';
import { K365SubscriptionModulesState } from '@pages/k365-setup-guides/store/states/k365-subscription-modules.state';
import { K365SubscriptionResourcesState } from '@pages/k365-setup-guides/store/states/k365-subscription-resources.state';

export const k365SetupGuidesStates = [
  K365OnboardingSubscriptionsState,
  K365SubscriptionActivationModulesState,
  K365SubscriptionModulesState,
  K365SubscriptionResourcesState,
];
