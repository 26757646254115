import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { environment } from '@env/environment';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  selector: 'itc-copyright',
  templateUrl: './copyright.component.html',
  styleUrls: ['./copyright.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TranslateModule],
})
export class CopyrightComponent {
  @Input() isDark = false;

  readonly helpAndSupportLink = environment.helpAndSupportLink;

  get year(): number {
    return new Date().getFullYear();
  }
}
