import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { PermissionsFacade } from '@core/permissions/facades/permissions.facade';
import { PartnerProgramActiveState } from '@layout/partner-program-active/store/states/partner-program-active.state';
import {
  LoadPartnerProgramActive,
  SetPartnerProgramInactive,
} from '@layout/partner-program-active/store/actions/partner-program-active.actions';

@Injectable({
  providedIn: 'root',
})
export class PartnerProgramActiveFacade {
  readonly isPartnerProgramActive$ = this.store.select(PartnerProgramActiveState.isPartnerProgramActive);

  constructor(
    private store: Store,
    private permissionsFacade: PermissionsFacade
  ) {}

  loadPartnerProgramActive(): void {
    if (this.permissionsFacade.hasAccessToPartnerProgram) {
      this.store.dispatch(new LoadPartnerProgramActive());
    } else {
      this.store.dispatch(new SetPartnerProgramInactive());
    }
  }
}
