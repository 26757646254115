import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'itc-chip',
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.scss'],
})
export class ChipComponent {
  @Input() name: string;
  @Input() data: string;

  @Output() removeChip = new EventEmitter();
}
