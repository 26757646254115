import { inject, Injectable } from '@angular/core';
import { CanMatchFn, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LoginAttemptService } from '@core/authorization/services/login-attempt.service';

@Injectable()
export class LoginContextGuard {
  constructor(
    private loginAttemptService: LoginAttemptService,
    private router: Router
  ) {}

  canAccess(): Observable<UrlTree | boolean> {
    return this.loginAttemptService.isLoginAttemptSet.pipe(
      map(loginAttempt => {
        if (loginAttempt) {
          return true;
        }

        return this.router.createUrlTree(['home']);
      })
    );
  }
}

export const canMatchLoginContext: CanMatchFn = () => {
  return inject(LoginContextGuard).canAccess();
};
