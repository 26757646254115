import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { ISessionProjectsRequest } from '@pages/professional-services/models/table/session-projects-request.interface';
import { ISessionProjectsResponse } from '@pages/professional-services/models/table/session-projects-response.interface';

@Injectable({
  providedIn: 'root',
})
export class ProfessionalServicesService {
  constructor(private httpClient: HttpClient) {}

  getProfessionalServicesSessionProjects(sessionProjectsRequest: ISessionProjectsRequest): Observable<ISessionProjectsResponse> {
    const getSessionProjectsUrl = 'api/v1/professional-services-dashboard/projects/search';

    return this.httpClient.post<ISessionProjectsResponse>(environment.portalApiUrl + getSessionProjectsUrl, sessionProjectsRequest);
  }
}
