import { Component, Output, EventEmitter } from '@angular/core';
import { DateTimeLabelsService } from '@design/datepicker/services/date-time-labels.service';

@Component({
  selector: 'itc-months',
  templateUrl: './months.component.html',
  styleUrls: ['./months.component.scss'],
})
export class MonthsComponent {
  @Output() changeDate = new EventEmitter();

  months: string[] = this.dateTimeLabelsService.getShortMonths();

  constructor(private dateTimeLabelsService: DateTimeLabelsService) {}

  selectMonth(event: Event, monthId: number): void {
    event.stopPropagation();

    this.changeDate.emit(monthId);
  }
}
