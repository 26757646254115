import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { CurrentUserFacade } from '@core/authorization/facades/current-user.facade';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  selector: 'itc-impersonate-banner',
  templateUrl: './impersonate-banner.component.html',
  styleUrls: ['./impersonate-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TranslateModule],
})
export class ImpersonateBannerComponent {
  constructor(
    private currentUserFacade: CurrentUserFacade,
    private router: Router
  ) {}

  getUserFullName(): string {
    return this.currentUserFacade.getFullName();
  }

  getOrganizationName(): string {
    return this.currentUserFacade.getOrganizationName();
  }

  logOut(): void {
    this.router.navigateByUrl('logout').then(() => {
      window.self.close();
    });
  }
}
