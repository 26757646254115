import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { IOrganizationMappings } from '@pages/organization-mapping/models/organization-mappings.interface';
import { IOrganizationMappingRequest } from '@pages/organization-mapping/models/organization-mapping-request.interface';

@Injectable({
  providedIn: 'root',
})
export class OrganizationMappingsService {
  private readonly httpClient = inject(HttpClient);

  getOrganizationMappings(organizationMappingRequest: IOrganizationMappingRequest): Observable<IOrganizationMappings> {
    const url = 'api/v1/organization-mappings/search';

    return this.httpClient.post<IOrganizationMappings>(environment.portalApiUrl + url, organizationMappingRequest);
  }
}
