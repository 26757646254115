<div class="calendar-input__container">
  <div class="calendar-input__label">
    {{ label | translate }}
    @if (isRequired) {
      <span class="calendar-input__label-asterisk">*</span>
    }
  </div>
  <div class="calendar-input__wrapper" (click)="toggleCalendar.emit()" (keyup.enter)="toggleCalendar.emit()">
    <input
      class="calendar-input"
      [class.calendar-input--invalid]="isInvalid"
      [value]="displayedValue"
      [formControl]="componentFormControl"
      [readonly]="true"
    />
    <img class="calendar-input__icon" src="assets/icons/calendar-regular.svg" />
  </div>
  @if (isInvalid) {
    <div class="calendar-input__error">
      <itc-input-error [componentFormControl]="componentFormControl" />
    </div>
  }
</div>
