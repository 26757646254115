<div
  class="launcher-item__product"
  [disableTooltip]="isFullTextDisplayed(moduleName)"
  [itcTooltip]="module.moduleName"
  [tooltipPosition]="isTooltipRightAligned ? 'bottom-right' : 'bottom-left'"
  (click)="onLauncherItemClick()"
>
  <img class="launcher-item__icon" height="32" width="32" [ngSrc]="module.moduleImageUrl" [alt]="module.moduleName" />
  <div class="launcher-item__text launcher-item__text--pinned">
    <span #moduleName class="launcher-item__name launcher-item__name--pinned">
      {{ module.moduleName }}
    </span>
  </div>
  @if (isMultipleTenants) {
    <img
      class="launcher-item__group-icon"
      ngSrc="assets/icons/group.svg"
      height="14"
      width="14"
      [itcTooltip]="'launcher.choose-instance' | translate"
      [tooltipPosition]="isTooltipRightAligned ? 'top-right' : 'top-left'"
      [alt]="'launcher.choose-instance' | translate"
    />
  }
</div>
