import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { IColorScheme } from '@design/color-scheme.interface';
import { RegularButtonComponent } from '@design/button/components/regular-button/regular-button.component';
import { ButtonType } from '@design/button/models/button-type.type';

@Component({
  selector: 'itc-large-button',
  templateUrl: './large-button.component.html',
  styleUrls: ['./large-button.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [RegularButtonComponent],
})
export class LargeButtonComponent {
  @Input() colorScheme: IColorScheme = 'primary';
  @Input() disabled: boolean;
  @Input() buttonText: string;
  @Input() loading: boolean;
  @Input() testId: string;
  @Input() isCritical: boolean;
  @Input() type?: ButtonType;
  @Input() alignContentToRight?: boolean;

  @Output() buttonClick = new EventEmitter();
}
