<div class="custom-dialog__container" [attr.data-test-id]="testId" [attr.ngbAutofocus]="disableAutofocus ?? null">
  <div
    #header
    class="custom-dialog__header"
    [class.custom-dialog__header--shadowed]="!isScrolledToTop && shouldRenderWithScrollbar && hasVerticalScrollbar"
  >
    <ng-content select="[header]" />
  </div>
  <div #main class="custom-dialog__main" cdkScrollable [style.max-height.px]="maxMainHeight" (scroll)="onScroll()">
    <ng-content select="[main]" />
  </div>
  <div
    #actions
    class="custom-dialog__actions"
    [class.custom-dialog__actions--shadowed]="!isScrolledToBottom && shouldRenderWithScrollbar && hasVerticalScrollbar"
  >
    <ng-content select="[actions]" />
  </div>
</div>
