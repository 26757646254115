import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { isObservable, Observable, Subject } from 'rxjs';
import { IOption } from '@design/filter/models/option.interface';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

export interface IExtendedOption extends IOption {
  checked: boolean;
}

@Component({
  selector: 'itc-multiselect-filter',
  templateUrl: './multiselect-filter.component.html',
  styleUrls: ['./multiselect-filter.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MultiselectFilterComponent implements OnInit, OnDestroy {
  @Input() options: IOption[] | Observable<IOption[]>;
  @Input() loading$?: Observable<boolean>;
  @Input() field: string;
  @Input() name: string;
  @Input() model: Record<string, (string | number)[]>;
  @Input() isSearch = false;

  @Output() updateModel = new EventEmitter();
  @Output() closeFilter = new EventEmitter();
  @Output() search: Observable<string>;

  searchInput = new FormControl('', { nonNullable: true });
  checkboxOptions: IExtendedOption[];
  private unsubscribe$ = new Subject<void>();

  constructor(
    private elementRef: ElementRef,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.search = this.searchInput.valueChanges.pipe(debounceTime(500), distinctUntilChanged());
  }

  ngOnInit(): void {
    if (isObservable(this.options)) {
      this.options.pipe(takeUntil(this.unsubscribe$)).subscribe(options => {
        this.checkboxOptions = this.mapOptionsToCheckboxOptions(options);
        this.changeDetectorRef.markForCheck();
      });
    } else {
      this.checkboxOptions = this.mapOptionsToCheckboxOptions(this.options);
    }
  }

  get selectedOptions(): IExtendedOption[] {
    return this.checkboxOptions.filter(item => item.checked);
  }

  get selectedOptionsIds(): (number | string)[] {
    return this.selectedOptions.map(checkbox => checkbox.id);
  }

  change(isChecked: boolean, checkbox: { checked: boolean }): void {
    checkbox.checked = isChecked;
  }

  selectAll(): void {
    this.checkboxOptions = this.checkboxOptions?.map(option => ({ ...option, checked: true }));
  }

  applyFilter(): void {
    if (!this.selectedOptionsIds?.length) {
      return;
    }

    this.model[this.field] = this.selectedOptionsIds;
    this.updateModel.emit(this.model);
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.closeFilter.emit();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private mapOptionsToCheckboxOptions(options: IOption[]): IExtendedOption[] {
    return options.map(option => {
      return {
        ...option,
        checked: false,
      };
    });
  }
}
