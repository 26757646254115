import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { IDialogOptions } from '@design/dialog/models/dialog-options.interface';
import { Injectable } from '@angular/core';
import { IDialogWithDataOptions } from '@design/dialog/models/dialog-with-data-options.interface';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  private readonly defaultOptions: IDialogOptions = {
    size: 'sm',
    windowClass: 'custom-dialog',
    keyboard: false,
  };

  constructor(private modalService: NgbModal) {}

  openDialog(component: unknown, options?: IDialogOptions): NgbModalRef {
    return this.modalService.open(component, {
      ...this.defaultOptions,
      ...options,
    });
  }

  openDialogWithData<T>(component: unknown, options: IDialogWithDataOptions<T>): NgbModalRef {
    const modal = this.openDialog(component, options);
    this.appendDialogData<T>(modal, options.data);
    return modal;
  }

  private appendDialogData<T>(modal: NgbModalRef, data: Record<string, T>): void {
    for (const property in data) {
      modal.componentInstance[property] = data[property];
    }
  }
}
