import { filter, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { isNotUndefined } from '@shared/utils/is-not-undefined';

export function filterIsNotUndefined() {
  return function <T>(source: Observable<T | undefined>): Observable<T> {
    return source.pipe(
      filter(isNotUndefined),
      map(value => value as T)
    );
  };
}
