import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { LoadManagers } from '@layout/organization-managers/store/actions/organization-managers.actions';
import { OrganizationManagersState } from '@layout/organization-managers/store/states/organization-managers.state';

@Injectable({ providedIn: 'root' })
export class OrganizationManagersFacade {
  readonly accountManager$ = this.store.select(OrganizationManagersState.accountManager);
  readonly relationshipManager$ = this.store.select(OrganizationManagersState.relationshipManager);
  readonly loading$ = this.store.select(OrganizationManagersState.loading);

  constructor(private store: Store) {}

  loadManagers(): void {
    this.store.dispatch(new LoadManagers());
  }
}
