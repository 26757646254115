import { NgModule } from '@angular/core';
import { PermissionDirective } from '@core/permissions/directives/permission.directive';
import { PermissionAccessGuard } from '@core/permissions/guards/permission-access.guard';

@NgModule({
  declarations: [PermissionDirective],
  exports: [PermissionDirective],
  providers: [PermissionAccessGuard],
})
export class PermissionsModule {}
