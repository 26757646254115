import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { patch } from '@ngxs/store/operators';
import { catchError, finalize, tap } from 'rxjs/operators';
import { IProjectsCount } from '@pages/professional-services/models/projects-widget/projects-count.interface';
import { ProfessionalServicesProjectsCountService } from '@pages/professional-services/services/professional-services-projects-count.service';
import { LoadProjectsCount } from '@pages/professional-services/store/actions/professional-services-projects-count.actions';

interface IProfessionalServicesProjectsCountStateModel {
  projectsCount?: IProjectsCount;
  loading: boolean;
}

@State<IProfessionalServicesProjectsCountStateModel>({
  name: 'professionalServicesProjectsCount',
  defaults: {
    loading: false,
  },
})
@Injectable()
export class ProfessionalServicesProjectsCountState {
  constructor(private professionalServicesProjectsCountService: ProfessionalServicesProjectsCountService) {}

  @Selector()
  static completedCount(state: IProfessionalServicesProjectsCountStateModel): number | undefined {
    return state.projectsCount?.completedCount;
  }

  @Selector()
  static inProgressCount(state: IProfessionalServicesProjectsCountStateModel): number | undefined {
    return state.projectsCount?.inProgressCount;
  }

  @Selector()
  static onHoldCount(state: IProfessionalServicesProjectsCountStateModel): number | undefined {
    return state.projectsCount?.onHoldCount;
  }

  @Selector()
  static notStartedCount(state: IProfessionalServicesProjectsCountStateModel): number | undefined {
    return state.projectsCount?.notStartedCount;
  }

  @Selector()
  static loading(state: IProfessionalServicesProjectsCountStateModel): boolean {
    return state.loading;
  }

  @Action(LoadProjectsCount)
  loadProjectsCount(context: StateContext<IProfessionalServicesProjectsCountStateModel>): Observable<IProjectsCount> {
    context.setState(patch<IProfessionalServicesProjectsCountStateModel>({ loading: true }));

    return this.professionalServicesProjectsCountService.getProjectsCount().pipe(
      tap(projectsCount =>
        context.setState(
          patch<IProfessionalServicesProjectsCountStateModel>({
            projectsCount,
          })
        )
      ),
      catchError(() => {
        context.setState(
          patch<IProfessionalServicesProjectsCountStateModel>({
            projectsCount: undefined,
          })
        );
        return EMPTY;
      }),
      finalize(() => context.setState(patch<IProfessionalServicesProjectsCountStateModel>({ loading: false })))
    );
  }
}
