import { Component, Input, Output, EventEmitter, OnInit, HostListener, ElementRef } from '@angular/core';
import { range } from 'lodash-es';
import { DateTime } from '@design/datepicker/services/date-time';

@Component({
  selector: 'itc-years-select',
  templateUrl: './years-select.component.html',
  styleUrls: ['./years-select.component.scss'],
})
export class YearsSelectComponent implements OnInit {
  @Input({ required: true }) currentDate: DateTime;
  @Output() changeDate = new EventEmitter();

  years: number[];
  isOpenSelect = false;

  constructor(private elementRef: ElementRef) {}

  ngOnInit(): void {
    this.years = this.getYearsList();
  }

  selectYear(event: Event, year: number): void {
    event.stopPropagation();

    this.changeDate.emit(year);
    this.isOpenSelect = false;
  }

  @HostListener('document:click', ['$event'])
  clickOut(event: Event): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.isOpenSelect = false;
    }
  }

  private getYearsList(): number[] {
    const yearStart = DateTime.today().subtractYear(10).date.getFullYear();
    const yearEnd = DateTime.today().addYear(11).date.getFullYear();

    return range(yearStart, yearEnd);
  }
}
