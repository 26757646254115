import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ILauncherModules } from '@layout/launcher/models/launcher-modules.interface';

@Injectable({
  providedIn: 'root',
})
export class LauncherService {
  private readonly launcherModulesUrl = 'api/v1/user/launcher-modules';

  constructor(private httpClient: HttpClient) {}

  getLauncherModules(): Observable<ILauncherModules> {
    return this.httpClient.get<ILauncherModules>(environment.portalApiUrl + this.launcherModulesUrl);
  }
}
