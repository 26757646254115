import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { catchError, finalize, tap } from 'rxjs/operators';
import { EMPTY, Observable } from 'rxjs';
import { IAllSubscriptions } from '@pages/subscriptions/models/module-subscriptions/all-subscriptions.interface';
import { SubscriptionsService } from '@pages/subscriptions/services/subscriptions.service';
import {
  LoadAllSubscriptions,
  SearchAllSubscriptionsByName,
  AllSubscriptionsSkusSorting,
  ToggleAllSubscriptions,
  ToggleSubscription,
} from '@pages/subscriptions/store/actions/all-subscriptions.actions';
import { patch } from '@ngxs/store/operators';
import { asNonUndefined } from '@shared/utils/as-non-undefined';
import { sortFunctionsMapping } from '@pages/subscriptions/utils/sort';

interface IAllSubscriptionsStateModel {
  allSubscriptions?: IAllSubscriptions[];
  filteredAllSubscriptions?: IAllSubscriptions[];
  loading: boolean;
  error: boolean;
  isSearchApplied?: boolean;
}

@State<IAllSubscriptionsStateModel>({
  name: 'modulesSubscriptions',
  defaults: {
    loading: false,
    error: false,
  },
})
@Injectable()
export class AllSubscriptionsState {
  constructor(private subscriptionsService: SubscriptionsService) {}

  @Selector()
  static allFilteredSubscriptions(state: IAllSubscriptionsStateModel): IAllSubscriptions[] | undefined {
    return state.filteredAllSubscriptions;
  }

  @Selector()
  static allSubscriptions(state: IAllSubscriptionsStateModel): IAllSubscriptions[] | undefined {
    return state.allSubscriptions;
  }

  @Selector()
  static loading(state: IAllSubscriptionsStateModel): boolean {
    return state.loading;
  }

  @Selector()
  static error(state: IAllSubscriptionsStateModel): boolean {
    return state.error;
  }

  @Selector()
  static isSearchApplied(state: IAllSubscriptionsStateModel): boolean | undefined {
    return state.isSearchApplied;
  }

  @Action(LoadAllSubscriptions)
  loadAllSubscriptions(context: StateContext<IAllSubscriptionsStateModel>): Observable<IAllSubscriptions[]> {
    context.setState(
      patch<IAllSubscriptionsStateModel>({
        loading: true,
        error: false,
        isSearchApplied: false,
        allSubscriptions: undefined,
        filteredAllSubscriptions: undefined,
      })
    );

    return this.subscriptionsService.getAllSubscriptions().pipe(
      tap(subscriptions => {
        const allSubscriptions = subscriptions.map((subscription, subscriptionIndex) => ({
          ...subscription,
          isExpanded: !subscriptionIndex,
        }));
        context.setState(
          patch<IAllSubscriptionsStateModel>({
            allSubscriptions,
            filteredAllSubscriptions: allSubscriptions,
            error: false,
          })
        );
      }),
      catchError(() => {
        context.setState(
          patch<IAllSubscriptionsStateModel>({
            allSubscriptions: undefined,
            filteredAllSubscriptions: undefined,
            error: true,
          })
        );
        return EMPTY;
      }),
      finalize(() => context.setState(patch<IAllSubscriptionsStateModel>({ loading: false })))
    );
  }

  @Action(SearchAllSubscriptionsByName)
  searchAllSubscriptionsByName(context: StateContext<IAllSubscriptionsStateModel>, { name }: SearchAllSubscriptionsByName): void {
    context.setState(
      patch<IAllSubscriptionsStateModel>({
        isSearchApplied: false,
      })
    );

    const { allSubscriptions } = context.getState();
    if (!allSubscriptions) {
      return;
    }

    if (!name) {
      context.setState(
        patch<IAllSubscriptionsStateModel>({
          filteredAllSubscriptions: allSubscriptions,
        })
      );
      return;
    }

    const filteredModulesSubscriptions = allSubscriptions.filter(moduleSubscriptions =>
      moduleSubscriptions.name.toLowerCase().includes(name.toLowerCase())
    );
    context.setState(
      patch<IAllSubscriptionsStateModel>({
        filteredAllSubscriptions: filteredModulesSubscriptions,
        isSearchApplied: true,
      })
    );
  }

  @Action(ToggleSubscription)
  toggleSubscription(
    context: StateContext<IAllSubscriptionsStateModel>,
    { toggleSubscription: { expanded, name } }: ToggleSubscription
  ): void {
    const { filteredAllSubscriptions } = context.getState();
    if (!filteredAllSubscriptions) {
      return;
    }

    const toggleItCompleteSubscriptions = filteredAllSubscriptions.map(subscription => {
      return {
        ...subscription,
        isExpanded: name === subscription.name ? expanded : subscription.isExpanded,
      };
    });
    context.setState(
      patch<IAllSubscriptionsStateModel>({
        filteredAllSubscriptions: toggleItCompleteSubscriptions,
        loading: false,
      })
    );
  }

  @Action(ToggleAllSubscriptions)
  toggleAllSubscriptions(
    context: StateContext<IAllSubscriptionsStateModel>,
    { areAllExpandedSubscriptions }: ToggleAllSubscriptions
  ): void {
    const { filteredAllSubscriptions } = context.getState();
    if (!filteredAllSubscriptions) {
      return;
    }

    const toggleItCompleteSubscriptions = filteredAllSubscriptions.map(subscription => {
      return {
        ...subscription,
        isExpanded: areAllExpandedSubscriptions,
      };
    });
    context.setState(
      patch<IAllSubscriptionsStateModel>({
        filteredAllSubscriptions: toggleItCompleteSubscriptions,
        loading: false,
      })
    );
  }

  @Action(AllSubscriptionsSkusSorting)
  allSubscriptionsSkusSorting(context: StateContext<IAllSubscriptionsStateModel>, { sortBy, name }: AllSubscriptionsSkusSorting): void {
    const filteredAllSubscriptions = asNonUndefined(context.getState().filteredAllSubscriptions);
    const sortedAllSubscriptions = filteredAllSubscriptions.map(subscription => {
      return subscription.name === name
        ? {
            ...subscription,
            skus: sortFunctionsMapping[sortBy.sortField](subscription.skus, sortBy.sortField, sortBy.sortOrder),
          }
        : subscription;
    });
    context.setState(
      patch<IAllSubscriptionsStateModel>({
        filteredAllSubscriptions: sortedAllSubscriptions,
      })
    );
  }
}
