import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { catchError, finalize, tap } from 'rxjs/operators';
import { EMPTY, Observable } from 'rxjs';
import { SubscriptionsService } from '@pages/subscriptions/services/subscriptions.service';
import { patch } from '@ngxs/store/operators';
import {
  LoadITCompleteSubscriptions,
  SearchITCompleteSubscriptionsByName,
  ITCompleteSkusSorting,
  ToggleAllITCompleteSubscriptions,
  ToggleITCompleteSubscription,
  ToggleChildSubscription,
} from '@pages/subscriptions/store/actions/it-complete-subscription.action';
import { asNonUndefined } from '@shared/utils/as-non-undefined';
import { sortFunctionsMapping } from '@pages/subscriptions/utils/sort';
import { IITCompleteCategorySubscriptions } from '@pages/subscriptions/models/module-subscriptions/it-complete-category-subscriptions.interface';

interface IITCompleteSubscriptionsStateModel {
  itCompleteSubscriptions?: IITCompleteCategorySubscriptions[];
  filteredItCompleteSubscriptions?: IITCompleteCategorySubscriptions[];
  loading: boolean;
  error: boolean;
  isSearchApplied?: boolean;
}

@State<IITCompleteSubscriptionsStateModel>({
  name: 'itCompleteSubscriptions',
  defaults: {
    loading: false,
    error: false,
  },
})
@Injectable()
export class ITCompleteSubscriptionsState {
  constructor(private subscriptionsService: SubscriptionsService) {}

  @Selector()
  static itCompleteSubscriptions(state: IITCompleteSubscriptionsStateModel): IITCompleteCategorySubscriptions[] | undefined {
    return state.filteredItCompleteSubscriptions;
  }

  @Selector()
  static allITCompleteSubscriptions(state: IITCompleteSubscriptionsStateModel): IITCompleteCategorySubscriptions[] | undefined {
    return state.itCompleteSubscriptions;
  }

  @Selector()
  static loading(state: IITCompleteSubscriptionsStateModel): boolean {
    return state.loading;
  }

  @Selector()
  static error(state: IITCompleteSubscriptionsStateModel): boolean {
    return state.error;
  }

  @Selector()
  static isSearchApplied(state: IITCompleteSubscriptionsStateModel): boolean | undefined {
    return state.isSearchApplied;
  }

  @Action(LoadITCompleteSubscriptions)
  loadITCompleteSubscriptions(context: StateContext<IITCompleteSubscriptionsStateModel>): Observable<IITCompleteCategorySubscriptions[]> {
    context.setState(
      patch<IITCompleteSubscriptionsStateModel>({
        loading: true,
        error: false,
        isSearchApplied: false,
        itCompleteSubscriptions: undefined,
        filteredItCompleteSubscriptions: undefined,
      })
    );

    return this.subscriptionsService.getITCompleteSubscriptions().pipe(
      tap(subscriptions => {
        const itCompleteSubscriptions = this.mappingITCompleteSubsciptions(subscriptions);
        context.setState(
          patch<IITCompleteSubscriptionsStateModel>({
            itCompleteSubscriptions,
            filteredItCompleteSubscriptions: itCompleteSubscriptions,
            error: false,
          })
        );
      }),
      catchError(() => {
        context.setState(
          patch<IITCompleteSubscriptionsStateModel>({
            itCompleteSubscriptions: undefined,
            filteredItCompleteSubscriptions: undefined,
            error: true,
          })
        );
        return EMPTY;
      }),
      finalize(() => context.setState(patch<IITCompleteSubscriptionsStateModel>({ loading: false })))
    );
  }

  @Action(SearchITCompleteSubscriptionsByName)
  searchITCompleteSubscriptionsByName(
    context: StateContext<IITCompleteSubscriptionsStateModel>,
    { name }: SearchITCompleteSubscriptionsByName
  ): void {
    context.setState(
      patch<IITCompleteSubscriptionsStateModel>({
        isSearchApplied: false,
      })
    );

    const { itCompleteSubscriptions } = context.getState();
    if (!itCompleteSubscriptions) {
      return;
    }

    if (!name) {
      context.setState(
        patch<IITCompleteSubscriptionsStateModel>({
          filteredItCompleteSubscriptions: itCompleteSubscriptions,
        })
      );
      return;
    }

    const filteredItCompleteSubscriptions = itCompleteSubscriptions
      .map(subscription => {
        return {
          ...subscription,
          items: subscription.items.filter(item => item.name.toLowerCase().includes(name.toLowerCase())),
        };
      })
      .filter(subscription => subscription.items.length);
    context.setState(
      patch<IITCompleteSubscriptionsStateModel>({
        filteredItCompleteSubscriptions,
        isSearchApplied: true,
      })
    );
  }

  @Action(ToggleITCompleteSubscription)
  toggleITCompleteSubscription(
    context: StateContext<IITCompleteSubscriptionsStateModel>,
    { toggleSubscription: { expanded, name } }: ToggleITCompleteSubscription
  ): void {
    const { filteredItCompleteSubscriptions } = context.getState();
    if (!filteredItCompleteSubscriptions) {
      return;
    }

    const toggleItCompleteSubscriptions = filteredItCompleteSubscriptions.map(subscription => {
      return {
        ...subscription,
        isExpanded: name === subscription.categoryName ? expanded : subscription.isExpanded,
      };
    });
    context.setState(
      patch<IITCompleteSubscriptionsStateModel>({
        filteredItCompleteSubscriptions: toggleItCompleteSubscriptions,
        loading: false,
      })
    );
  }

  @Action(ToggleChildSubscription)
  toggleChildSubscription(
    context: StateContext<IITCompleteSubscriptionsStateModel>,
    { toggleSubscription: { expanded, name, subscriptionName } }: ToggleChildSubscription
  ): void {
    const { itCompleteSubscriptions, filteredItCompleteSubscriptions } = context.getState();
    if (!itCompleteSubscriptions || !filteredItCompleteSubscriptions) {
      return;
    }

    const toggleChildSubscriptions = filteredItCompleteSubscriptions.map(subscription => {
      return {
        ...subscription,
        items: subscription.items.map(item => {
          return {
            ...item,
            isExpanded: item.name === name && subscription.categoryName === subscriptionName ? expanded : item.isExpanded,
          };
        }),
      };
    });

    context.setState(
      patch<IITCompleteSubscriptionsStateModel>({
        filteredItCompleteSubscriptions: toggleChildSubscriptions,
      })
    );
  }

  @Action(ToggleAllITCompleteSubscriptions)
  toggleAllITCompleteSubscription(
    context: StateContext<IITCompleteSubscriptionsStateModel>,
    { areAllExpandedSubscriptions }: ToggleAllITCompleteSubscriptions
  ): void {
    const { filteredItCompleteSubscriptions } = context.getState();
    if (!filteredItCompleteSubscriptions) {
      return;
    }

    const toggleItCompleteSubscriptions = filteredItCompleteSubscriptions.map(subscription => {
      return {
        ...subscription,
        isExpanded: areAllExpandedSubscriptions,
      };
    });
    context.setState(
      patch<IITCompleteSubscriptionsStateModel>({
        filteredItCompleteSubscriptions: toggleItCompleteSubscriptions,
        loading: false,
      })
    );
  }

  @Action(ITCompleteSkusSorting)
  sortITCompleteSubscriptions(context: StateContext<IITCompleteSubscriptionsStateModel>, { sortBy, name }: ITCompleteSkusSorting): void {
    const filteredItCompleteSubscriptions = asNonUndefined(context.getState().filteredItCompleteSubscriptions);
    const sortedItCompleteSubscriptions = filteredItCompleteSubscriptions.map(subscription => {
      return {
        ...subscription,
        items: subscription.items.map(item =>
          item.name === name
            ? {
                ...item,
                skus: sortFunctionsMapping[sortBy.sortField](item.skus, sortBy.sortField, sortBy.sortOrder),
              }
            : item
        ),
      };
    });
    context.setState(
      patch<IITCompleteSubscriptionsStateModel>({
        filteredItCompleteSubscriptions: sortedItCompleteSubscriptions,
      })
    );
  }

  private mappingITCompleteSubsciptions(subscriptions: IITCompleteCategorySubscriptions[]): IITCompleteCategorySubscriptions[] {
    return subscriptions.map((subscription, subscriptionIndex) => {
      return {
        ...subscription,
        isExpanded: !subscriptionIndex,
        items: subscription.items.map((item, itemIndex) => {
          return {
            ...item,
            isExpanded: !subscriptionIndex && !itemIndex,
          };
        }),
      };
    });
  }
}
