import { APP_INITIALIZER, inject, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslationService } from '@core/translation/services/translation.service';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { Observable } from 'rxjs';
import { TranslationApiService } from '@core/translation/services/translation-api.service';
import { translationRootUrl } from '@core/translation/models/translation-root-url';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
      },
    }),
  ],
  providers: [
    TranslationApiService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeTranslations,
      deps: [TranslationService],
      multi: true,
    },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class TranslationModule {}

export function initializeTranslations(translationService: TranslationService): () => Observable<void> {
  return () => translationService.initialize();
}

export function HttpLoaderFactory(): TranslateHttpLoader {
  return new TranslateHttpLoader(inject(HttpClient), translationRootUrl);
}
