import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { patch } from '@ngxs/store/operators';
import { catchError, finalize, tap } from 'rxjs/operators';
import { ProfessionalServicesRecentSessionsService } from '@pages/professional-services/services/professional-services-recent-sessions.service';
import { LoadRecentSessions } from '@pages/professional-services/store/actions/professional-services-recent-sessions.actions';
import { IRecentSession } from '@pages/professional-services/models/recent-sessions/recent-session.interface';

interface IProfessionalServicesRecentSessionsStateModel {
  recentSessions?: IRecentSession[];
  loading: boolean;
  error: boolean;
}

@State<IProfessionalServicesRecentSessionsStateModel>({
  name: 'professionalServicesRecentSessions',
  defaults: {
    loading: false,
    error: false,
  },
})
@Injectable()
export class ProfessionalServicesRecentSessionsState {
  constructor(private professionalServicesRecentSessionsService: ProfessionalServicesRecentSessionsService) {}

  @Selector()
  static recentSessions(state: IProfessionalServicesRecentSessionsStateModel): IRecentSession[] | undefined {
    return state.recentSessions;
  }

  @Selector()
  static loading(state: IProfessionalServicesRecentSessionsStateModel): boolean {
    return state.loading;
  }

  @Selector()
  static error(state: IProfessionalServicesRecentSessionsStateModel): boolean {
    return state.error;
  }

  @Action(LoadRecentSessions)
  loadProjectsCount(context: StateContext<IProfessionalServicesRecentSessionsStateModel>): Observable<IRecentSession[]> {
    context.setState(patch<IProfessionalServicesRecentSessionsStateModel>({ loading: true }));

    return this.professionalServicesRecentSessionsService.getRecentSessions().pipe(
      tap(recentSessions =>
        context.setState(
          patch<IProfessionalServicesRecentSessionsStateModel>({
            recentSessions,
            error: false,
          })
        )
      ),
      catchError(() => {
        context.setState(
          patch<IProfessionalServicesRecentSessionsStateModel>({
            recentSessions: undefined,
            error: true,
          })
        );
        return EMPTY;
      }),
      finalize(() => context.setState(patch<IProfessionalServicesRecentSessionsStateModel>({ loading: false })))
    );
  }
}
