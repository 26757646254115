import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { catchError, finalize, tap } from 'rxjs/operators';
import { EMPTY, Observable } from 'rxjs';
import { SubscriptionsService } from '@pages/subscriptions/services/subscriptions.service';
import { patch } from '@ngxs/store/operators';
import { IK365Subscriptions } from '@pages/subscriptions/models/module-subscriptions/k365-subscriptions.interface';
import {
  LoadK365Subscriptions,
  SearchK365SubscriptionsByName,
  K365SkusSorting,
  ToggleAllK365Subscriptions,
  ToggleK365Subscription,
  ToggleChildSubscription,
} from '@pages/subscriptions/store/actions/k365-subscriptions.action';
import { IK365SubscriptionFunction } from '@pages/subscriptions/models/module-subscriptions/k365-subscriptions-function.interface';
import { asNonUndefined } from '@shared/utils/as-non-undefined';
import { sortByNumber, sortByString } from '@pages/subscriptions/utils/sort';
interface IK365SubscriptionsStateModel {
  k365Subscriptions?: IK365Subscriptions[];
  filteredK365Subscriptions?: IK365Subscriptions[];
  loading: boolean;
  error: boolean;
  isSearchApplied?: boolean;
}

@State<IK365SubscriptionsStateModel>({
  name: 'k365Subscriptions',
  defaults: {
    loading: false,
    error: false,
  },
})
@Injectable()
export class K365SubscriptionsState {
  constructor(private subscriptionsService: SubscriptionsService) {}

  @Selector()
  static k365Subscriptions(state: IK365SubscriptionsStateModel): IK365Subscriptions[] | undefined {
    return state.filteredK365Subscriptions;
  }

  @Selector()
  static allk365Subscriptions(state: IK365SubscriptionsStateModel): IK365Subscriptions[] | undefined {
    return state.k365Subscriptions;
  }

  @Selector()
  static loading(state: IK365SubscriptionsStateModel): boolean {
    return state.loading;
  }

  @Selector()
  static error(state: IK365SubscriptionsStateModel): boolean {
    return state.error;
  }

  @Selector()
  static isSearchApplied(state: IK365SubscriptionsStateModel): boolean | undefined {
    return state.isSearchApplied;
  }

  @Action(LoadK365Subscriptions)
  loadK365Subscriptions(context: StateContext<IK365SubscriptionsStateModel>): Observable<IK365Subscriptions[]> {
    context.setState(
      patch<IK365SubscriptionsStateModel>({
        loading: true,
        error: false,
        isSearchApplied: false,
        k365Subscriptions: undefined,
        filteredK365Subscriptions: undefined,
      })
    );

    return this.subscriptionsService.getK365Subscriptions().pipe(
      tap(subscriptions => {
        const k365Subscriptions = this.mappingK365Subsciptions(subscriptions);
        context.setState(
          patch<IK365SubscriptionsStateModel>({
            k365Subscriptions,
            filteredK365Subscriptions: k365Subscriptions,
            error: false,
          })
        );
      }),
      catchError(() => {
        context.setState(
          patch<IK365SubscriptionsStateModel>({
            k365Subscriptions: undefined,
            filteredK365Subscriptions: undefined,
            error: true,
          })
        );
        return EMPTY;
      }),
      finalize(() => context.setState(patch<IK365SubscriptionsStateModel>({ loading: false })))
    );
  }

  @Action(SearchK365SubscriptionsByName)
  searchK365SubscriptionsByName(context: StateContext<IK365SubscriptionsStateModel>, { name }: SearchK365SubscriptionsByName): void {
    context.setState(
      patch<IK365SubscriptionsStateModel>({
        isSearchApplied: false,
      })
    );
    const { k365Subscriptions } = context.getState();
    if (!k365Subscriptions) {
      return;
    }

    if (!name) {
      context.setState(
        patch<IK365SubscriptionsStateModel>({
          filteredK365Subscriptions: k365Subscriptions,
        })
      );
      return;
    }
    const filteredK365Subscriptions = k365Subscriptions
      .map(subscription => {
        const combinedGroups = subscription.groups.reduce((accumulator: Record<string, IK365SubscriptionFunction[]>, group) => {
          group.functions.forEach(groupFunction => {
            if (groupFunction.name.toLowerCase().includes(name.toLowerCase())) {
              const groupName = group.name;
              accumulator[groupName] = accumulator[groupName] || [];
              accumulator[groupName].push(groupFunction);
            }
          });
          return accumulator;
        }, {});
        const groups = Object.entries(combinedGroups).map(([groupName, functions]) => {
          return { name: groupName, functions };
        });
        return {
          ...subscription,
          isExpanded: groups.length > 0,
          groups,
        };
      })
      .filter(subscription => subscription.groups.length > 0);

    context.setState(
      patch<IK365SubscriptionsStateModel>({
        filteredK365Subscriptions,
        isSearchApplied: true,
      })
    );
  }

  @Action(ToggleK365Subscription)
  toggleK365Subscription(
    context: StateContext<IK365SubscriptionsStateModel>,
    { toggleSubscription: { expanded, name } }: ToggleK365Subscription
  ): void {
    const { k365Subscriptions, filteredK365Subscriptions } = context.getState();
    if (!k365Subscriptions || !filteredK365Subscriptions) {
      return;
    }

    const toggleK365Subscriptions = filteredK365Subscriptions.map(subscription => {
      return {
        ...subscription,
        isExpanded: name === subscription.name ? expanded : subscription.isExpanded,
      };
    });

    context.setState(
      patch<IK365SubscriptionsStateModel>({
        filteredK365Subscriptions: toggleK365Subscriptions,
        loading: false,
      })
    );
  }

  @Action(ToggleChildSubscription)
  toggleChildSubscription(
    context: StateContext<IK365SubscriptionsStateModel>,
    { toggleSubscription: { expanded, name, subscriptionName } }: ToggleChildSubscription
  ): void {
    const { k365Subscriptions, filteredK365Subscriptions } = context.getState();
    if (!k365Subscriptions || !filteredK365Subscriptions) {
      return;
    }

    const toggleChildSubscriptions = filteredK365Subscriptions.map(subscription => {
      return {
        ...subscription,
        groups: subscription.groups.map(group => {
          return {
            ...group,
            functions: group.functions.map(groupFunction => {
              return {
                ...groupFunction,
                isExpanded: groupFunction.name === name && subscription.name === subscriptionName ? expanded : groupFunction.isExpanded,
              };
            }),
          };
        }),
      };
    });

    context.setState(
      patch<IK365SubscriptionsStateModel>({
        filteredK365Subscriptions: toggleChildSubscriptions,
        loading: false,
      })
    );
  }

  @Action(ToggleAllK365Subscriptions)
  toggleAllK365Subscription(
    context: StateContext<IK365SubscriptionsStateModel>,
    { areAllExpandedSubscriptions }: ToggleAllK365Subscriptions
  ): void {
    const { k365Subscriptions, filteredK365Subscriptions } = context.getState();
    if (!k365Subscriptions || !filteredK365Subscriptions) {
      return;
    }

    const toggleK365Subscriptions = filteredK365Subscriptions.map(subscription => {
      return {
        ...subscription,
        isExpanded: areAllExpandedSubscriptions,
      };
    });

    context.setState(
      patch<IK365SubscriptionsStateModel>({
        filteredK365Subscriptions: toggleK365Subscriptions,
        loading: false,
      })
    );
  }

  @Action(K365SkusSorting)
  sortK365Skus(context: StateContext<IK365SubscriptionsStateModel>, { sortBy, name }: K365SkusSorting): void {
    const filteredK365Subscriptions = asNonUndefined(context.getState().filteredK365Subscriptions);

    const sortedK365Subscriptions = filteredK365Subscriptions.map(subscription => {
      const groups = subscription.groups.map(group => {
        const groupFunctions = group.functions.map(groupFunction => {
          return groupFunction.name === name
            ? {
                ...groupFunction,
                skUs:
                  sortBy.sortField === 'item'
                    ? sortByString(groupFunction.skUs, sortBy.sortField, sortBy.sortOrder)
                    : sortByNumber(groupFunction.skUs, sortBy.sortField, sortBy.sortOrder),
              }
            : groupFunction;
        });
        return {
          ...group,
          functions: groupFunctions,
        };
      });
      return {
        ...subscription,
        groups,
      };
    });

    context.setState(
      patch<IK365SubscriptionsStateModel>({
        filteredK365Subscriptions: sortedK365Subscriptions,
      })
    );
  }

  private mappingK365Subsciptions(subscriptions: IK365Subscriptions[]): IK365Subscriptions[] {
    return subscriptions.map(subscription => {
      return {
        ...subscription,
        isExpanded: false,
        groups: subscription.groups.map(group => {
          return {
            ...group,
            functions: group.functions.map(groupFunction => {
              return {
                ...groupFunction,
                isExpanded: false,
              };
            }),
          };
        }),
      };
    });
  }
}
