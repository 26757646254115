import { Permission } from '@core/permissions/models/permission.enum';
import { PermissionsService } from '@core/permissions/services/permissions.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PermissionsFacade {
  constructor(private permissionsService: PermissionsService) {}

  get hasAccessToAbsorbLearningSummary(): boolean {
    return this.permissionsService.hasUserPermission(Permission.AbsorbLearningSummary);
  }

  get hasAccessToAbsorbUserCourses(): boolean {
    return this.permissionsService.hasUserPermission(Permission.AbsorbUserCourses);
  }

  get hasAccessToRemoteItSecurityManagement(): boolean {
    return this.permissionsService.hasUserPermission(Permission.RemoteItSecurityManagement);
  }

  get hasAccessToPartnerProgram(): boolean {
    return this.permissionsService.hasUserPermission(Permission.PartnerProgram);
  }

  get hasAccessToBillingAndSubscriptions(): boolean {
    return this.permissionsService.hasUserPermission(Permission.BillingAndSubscriptions);
  }

  get hasAccessToCentralizedConfigurationRead(): boolean {
    return this.permissionsService.hasUserPermission(Permission.CentralizedConfigurationRead);
  }

  get hasAccessToOnboardingSubscriptionsView(): boolean {
    return this.permissionsService.hasUserPermission(Permission.OnboardingSubscriptionsView);
  }

  get hasAccessToOnboardingFirstMasterUser(): boolean {
    return this.permissionsService.hasUserPermission(Permission.OnboardingFirstMasterUserView);
  }

  get hasOrganizationMappingsModify(): boolean {
    return this.permissionsService.hasUserPermission(Permission.OrganizationMappingsModify);
  }
}
