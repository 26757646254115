<div class="multiselect-filter">
  <div class="multiselect-filter__title">{{ name | translate }}</div>
  @if (isSearch) {
    <div class="multiselect-filter__search-input-container">
      <img class="multiselect-filter__search-icon" src="assets/icons/search.svg" />
      <itc-input
        class="multiselect-filter__search-text"
        autocomplete="off"
        [elementId]="'search'"
        [placeholder]="'filter.search' | translate"
        [componentFormControl]="searchInput"
        [autoFocus]="true"
      />
    </div>
  }
  @if ((loading$ | async) === true) {
    <div class="multiselect-filter__loader">
      <itc-progress-spinner [spinnerSize]="30" />
    </div>
  }
  @if ((loading$ | async) === false && !checkboxOptions.length) {
    <div class="multiselect-filter__empty">
      {{ 'filter.no-options-found' | translate }}
    </div>
  }
  @if (!loading$ || (loading$ | async) === false) {
    <div class="multiselect-filter__checkboxes-container">
      @for (checkbox of checkboxOptions; track checkbox) {
        <itc-checkbox
          elementId="multiselect-filter"
          class="multiselect-filter__checkbox"
          [checked]="checkbox.checked"
          (change)="change($event, checkbox)"
        >
          <div class="multiselect-filter__option-container">
            @if (checkbox.iconSrc) {
              <img class="multiselect-filter__option-icon" [src]="checkbox.iconSrc" />
            }
            <span class="multiselect-filter__option-label">{{ checkbox.label }}</span>
          </div>
        </itc-checkbox>
      }
    </div>
  }
</div>
<div class="multiselect-filter__buttons">
  <div class="multiselect-filter__link" (click)="selectAll()">{{ 'filter.select-all' | translate }}</div>
  <div class="multiselect-filter__button" (click)="applyFilter()">{{ 'filter.apply' | translate }}</div>
</div>
