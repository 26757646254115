import { ProfessionalServicesProjectsState } from '@pages/professional-services/store/state/professional-services-projects.state';
import { ProfessionalServicesProjectsCountState } from '@pages/professional-services/store/state/professional-services-projects-count.state';
import { ProfessionalServicesRecentSessionsState } from '@pages/professional-services/store/state/professional-services-recent-sessions.state';
import { ProfessionalServicesUpcomingSessionsState } from '@pages/professional-services/store/state/professional-services-upcoming-sessions.state';
import { ProfessionalServicesSessionDetailsState } from '@pages/professional-services/store/state/professional-services-session-details.state';
import { ProfessionalServicesProjectDetailsState } from '@pages/professional-services/store/state/professional-services-project-details.state';
import { ProfessionalServicesHasAnyProjectsState } from '@pages/professional-services/store/state/professional-services-has-any-projects.state';

export const professionalServicesState = [
  ProfessionalServicesProjectsState,
  ProfessionalServicesProjectsCountState,
  ProfessionalServicesRecentSessionsState,
  ProfessionalServicesUpcomingSessionsState,
  ProfessionalServicesProjectDetailsState,
  ProfessionalServicesSessionDetailsState,
  ProfessionalServicesHasAnyProjectsState,
];
