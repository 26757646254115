export class LoadK365SubscriptionModules {
  static readonly type = '[K365SubscriptionModules] Load K365 Subscription Modules';

  constructor(readonly subscriptionId: string) {}
}

export class PollK365SubscriptionModules {
  static readonly type = '[K365SubscriptionModules] Poll K365 Subscription Modules';

  constructor(readonly subscriptionId: string) {}
}

export class StopPollingK365SubscriptionModules {
  static readonly type = '[K365SubscriptionModules] Stop Polling K365 Subscription Modules';
}
