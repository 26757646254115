import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanMatchFn, Route, Router, UrlTree } from '@angular/router';
import { map, Observable } from 'rxjs';
import { IAppRouteData } from '@core/route-data/models/route-data.interface';
import { FeaturesFacade } from '@core/features/facades/features.facade';
import { Feature } from '@core/features/models/feature.enum';

@Injectable({
  providedIn: 'root',
})
export class FeatureAccessGuard {
  constructor(
    private router: Router,
    private featuresFacade: FeaturesFacade
  ) {}

  isFeatureEnabledToAccessRoute(route: ActivatedRouteSnapshot | Route): Observable<UrlTree | boolean> {
    const feature: Feature | undefined = (route.data as IAppRouteData).feature;
    if (!feature) {
      throw new Error("'feature' property needs to be specified in route data to properly use FeatureAccessGuard.");
    }

    return this.featuresFacade
      .isFeatureEnabled$(feature)
      .pipe(map(isFeatureEnabled => isFeatureEnabled || this.router.createUrlTree(['access-denied'])));
  }
}

export const canMatchFeature: CanMatchFn = (route: Route) => {
  return inject(FeatureAccessGuard).isFeatureEnabledToAccessRoute(route);
};
