import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuComponent } from '@design/menu/components/menu.component';
import { TooltipModule } from '@design/tooltip/tooltip.module';

@NgModule({
  declarations: [MenuComponent],
  exports: [MenuComponent],
  imports: [CommonModule, TooltipModule],
})
export class MenuModule {}
