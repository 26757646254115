import { Injectable } from '@angular/core';
import { ISubmitFormOptions } from '@core/sso/models/submit-form-options.interface';

@Injectable({ providedIn: 'root' })
export class SubmitFormService {
  submitForm(options: ISubmitFormOptions): void {
    const form = document.createElement('form');
    form.method = options.method;
    form.target = options.target;
    form.action = options.action;

    options.inputs.forEach(descriptor => {
      const input = document.createElement('input');
      Object.assign(input, descriptor);
      form.appendChild(input);
    });

    document.body.appendChild(form);
    form.submit();
    document.body.removeChild(form);
  }
}
