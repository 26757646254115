import { TutorialCategory } from '@layout/tutorials/models/tutorial-category.enum';
import { tutorialCategoryNameTranslationKeys } from '@layout/tutorials/models/tutorial-category-name-translation-keys';

export const tutorialCategories = [
  {
    category: TutorialCategory.All,
    categoryKey: `tutorials.tutorials-categories.${tutorialCategoryNameTranslationKeys[TutorialCategory.All]}`,
  },
  {
    category: TutorialCategory.GettingStarted,
    categoryKey: `tutorials.tutorials-categories.${tutorialCategoryNameTranslationKeys[TutorialCategory.GettingStarted]}`,
  },
  {
    category: TutorialCategory.ITCompleteIntegrations,
    categoryKey: `tutorials.tutorials-categories.${tutorialCategoryNameTranslationKeys[TutorialCategory.ITCompleteIntegrations]}`,
  },
  {
    category: TutorialCategory.Onboarding,
    categoryKey: `tutorials.tutorials-categories.${tutorialCategoryNameTranslationKeys[TutorialCategory.Onboarding]}`,
  },
];
