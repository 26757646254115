import { Injectable, NgZone } from '@angular/core';
import { IndividualConfig, ToastrService } from 'ngx-toastr';
import { ToastType } from '@design/toast/models/toast-type.enum';
import { TranslateService } from '@ngx-translate/core';
import { ToastComponent } from '@design/toast/components/toast.component';
import { IToastPayloadWithConfiguration } from '@design/toast/models/toast-payload-with-configuration.interface';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private readonly timeOutMs = 5000;
  private initialConfig: Partial<IndividualConfig> = {
    toastComponent: ToastComponent,
    disableTimeOut: true,
    progressBar: true,
    newestOnTop: false,
    positionClass: 'toast__container--bottom-left',
    toastClass: 'toast__wrapper',
    closeButton: true,
  };

  constructor(
    private toastService: ToastrService,
    private translateService: TranslateService,
    private ngZone: NgZone
  ) {}

  showSuccessToast(
    descriptionTranslationKey: string = '',
    titleTranslationKey: string = 'Success',
    params: Partial<IndividualConfig> = {}
  ): void {
    this.showToast(descriptionTranslationKey, titleTranslationKey, ToastType.Success, params);
  }

  showInformationToast(
    descriptionTranslationKey: string = '',
    titleTranslationKey: string = 'Information',
    params: Partial<IndividualConfig> = {}
  ): void {
    this.showToast(descriptionTranslationKey, titleTranslationKey, ToastType.Information, params);
  }

  showWarningToast(
    descriptionTranslationKey: string = '',
    titleTranslationKey: string = 'Warning',
    params: Partial<IndividualConfig> = {}
  ): void {
    this.showToast(descriptionTranslationKey, titleTranslationKey, ToastType.Warning, params);
  }

  showErrorToast(
    descriptionTranslationKey: string = '',
    titleTranslationKey: string = 'Error',
    params: Partial<IndividualConfig> = {}
  ): void {
    this.showToast(descriptionTranslationKey, titleTranslationKey, ToastType.Error, params);
  }

  closeAllToasts(): void {
    this.toastService.clear();
  }

  private showToast(
    descriptionTranslationKey: string,
    titleTranslationKey: string,
    type: ToastType,
    params: Partial<IndividualConfig>
  ): void {
    const updatedParams = this.getUpdatedPayloadWithType(params, type);
    this.translateService.get([descriptionTranslationKey, titleTranslationKey]).subscribe({
      next: (translations: Record<string, string>) => {
        this.ngZone.run(() => {
          this.toastService.show(translations[descriptionTranslationKey], translations[titleTranslationKey], updatedParams);
        });
      },
    });
  }

  private getUpdatedPayloadWithType(
    params: Partial<IndividualConfig>,
    type: ToastType
  ): Partial<IndividualConfig<IToastPayloadWithConfiguration>> {
    return {
      ...this.initialConfig,
      ...params,
      payload: {
        ...params.payload,
        type,
        timeOutMs: params.payload?.timeOutMs ?? this.timeOutMs,
      } as IToastPayloadWithConfiguration,
    };
  }
}
