import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { IValidationError } from '@design/input/models/validation-errors.interface';
import { asNonNull } from '@shared/utils/as-non-null';

@Component({
  selector: 'itc-input-error',
  templateUrl: './input-error.component.html',
  styleUrls: ['./input-error.component.scss'],
})
export class InputErrorComponent {
  @Input() componentFormControl: FormControl;
  @Input() isSmallFontSize = false;

  get error(): IValidationError {
    const errors = asNonNull(this.componentFormControl.errors);
    const errorKeys = Object.keys(errors);
    const firstErrorKey = errorKeys.find(errorKey => errors[errorKey]);

    return firstErrorKey ? errors[firstErrorKey] : null;
  }
}
