<itc-menu
  [tooltipText]="'header.tooltips.help' | translate"
  [closeMenu$]="closeMenu$"
  [tooltipDisabled]="false"
  [ariaLabel]="'header.tooltips.help' | translate"
  [menuTemplateRef]="menuTemplateRef"
>
  <div class="help__labels">
    <div class="help__icon"></div>
  </div>
  <ng-template #menu>
    <div class="help__items">
      <span class="help__header">{{ 'header.help' | translate }}</span>
      <span class="help__item" (click)="openLink(knowledgeBaseLink)">{{ 'header.knowledge-base' | translate }}</span>
      <span *itcPermission="Permission.VideoTutorialsEdit" class="help__item" (click)="openVideoTutorialsDialog()">
        {{ 'header.video-tutorials' | translate }}
      </span>
      <span class="help__item" (click)="openLink(statusLink)">{{ 'header.status' | translate }}</span>
      <span class="help__item" (click)="openLink(releaseNotesLink)">{{ 'header.release-notes' | translate }}</span>
      <span class="help__header">{{ 'header.legal' | translate }}</span>
      <span class="help__item" (click)="openLink(termsOfUseLink)">{{ 'header.terms-of-use' | translate }}</span>
      <span class="help__item" (click)="openLink(privacyPolicyLink)">{{ 'header.privacy-policy' | translate }}</span>
      <itc-whats-new (openedLink)="closeMenu$.next()" />
    </div>
  </ng-template>
</itc-menu>
