import { ChangeDetectionStrategy, Component, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CurrentUserFacade } from '@core/authorization/facades/current-user.facade';
import { MenuModule } from '@design/menu/menu.module';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipModule } from '@design/tooltip/tooltip.module';
import { EmailComponent } from '@layout/profile-menu/components/email/email.component';

@Component({
  standalone: true,
  selector: 'itc-profile-menu',
  templateUrl: './profile-menu.component.html',
  styleUrls: ['./profile-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MenuModule, TranslateModule, TooltipModule, EmailComponent],
})
export class ProfileMenuComponent {
  @ViewChild('menu') menuTemplateRef: TemplateRef<unknown>;

  constructor(
    private currentUserFacade: CurrentUserFacade,
    private router: Router
  ) {}

  isTruncated(element: HTMLSpanElement): boolean {
    return element.offsetWidth < element.scrollWidth;
  }

  navigateTo(route: string): void {
    this.router.navigate([route]);
  }

  get userFullName(): string {
    return this.currentUserFacade.getFullName() || this.email;
  }

  get organizationName(): string {
    return this.currentUserFacade.getOrganizationName();
  }

  get email(): string {
    return this.currentUserFacade.getEmail() || '';
  }
}
