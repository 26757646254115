import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[itcScrollListener]',
})
export class ScrollListenerDirective {
  @Output() scrolled = new EventEmitter();
  @Output() scrolledToTop = new EventEmitter();
  @Output() scrolledToBottom = new EventEmitter();

  @HostListener('scroll')
  onScroll(): void {
    this.scrolled.emit();

    if (this.isScrolledToTop) {
      this.scrolledToTop.emit(true);
      return;
    }
    if (this.isScrolledToBottom) {
      this.scrolledToBottom.emit(true);
      return;
    }
    this.scrolledToTop.emit(false);
    this.scrolledToBottom.emit(false);
  }

  constructor(private elementRef: ElementRef) {}

  private get isScrolledToTop(): boolean {
    return this.currentScrollPosition <= 0;
  }

  private get isScrolledToBottom(): boolean {
    return Math.ceil(this.currentScrollPosition) >= this.maxScrollPosition;
  }

  private get currentScrollPosition(): number {
    return this.elementRef.nativeElement.scrollTop;
  }

  private get maxScrollPosition(): number {
    return this.elementRef.nativeElement.scrollHeight - this.elementRef.nativeElement.clientHeight;
  }
}
