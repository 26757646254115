import { Injectable } from '@angular/core';
import { StorageService } from '@core/storage/services/storage.service';
import { Permission } from '@core/permissions/models/permission.enum';

@Injectable({ providedIn: 'root' })
export class PermissionsService {
  constructor(private storageService: StorageService) {}

  hasUserPermission(permission: Permission): boolean {
    return !!this.getUserPermissions()?.includes(permission);
  }

  private getUserPermissions(): Permission[] | null {
    const authorizationData = this.storageService.getAuthorizationData();
    return authorizationData?.permissions ?? null;
  }
}
