import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'itc-calendar-input',
  templateUrl: './calendar-input.component.html',
  styleUrls: ['./calendar-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DatePipe],
})
export class CalendarInputComponent implements AfterViewInit, OnDestroy {
  @Input({ required: true }) componentFormControl: UntypedFormControl;
  @Input() label = 'Datepicker';
  @Output() toggleCalendar = new EventEmitter();

  displayedValue: string | null;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private datePipe: DatePipe,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  get isInvalid(): boolean {
    return this.componentFormControl.invalid && this.componentFormControl.dirty;
  }

  get isRequired(): boolean {
    const validator = this.componentFormControl.validator;
    return validator && validator({} as never)?.required;
  }

  ngAfterViewInit(): void {
    this.setDisplayedValue();

    this.componentFormControl.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.setDisplayedValue();
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private setDisplayedValue(): void {
    this.displayedValue = this.datePipe.transform(this.componentFormControl.value, 'MM/dd/yyyy');
    this.changeDetectorRef.markForCheck();
  }
}
