import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { DialogModule } from '@design/dialog/dialog.module';
import { CardModule } from '@design/card/card.module';
import { NgxsModule } from '@ngxs/store';
import { videoTutorialsState } from '@layout/tutorials/store';
import { FilterModule } from '@design/filter/filter.module';
import { TutorialsDialogComponent } from '@layout/tutorials/components/tutorials-dialog/tutorials-dialog.component';
import { TutorialCardComponent } from '@layout/tutorials/components/tutorial-card/tutorial-card.component';
import { TutorialsFilterComponent } from '@layout/tutorials/components/tutorials-filter/tutorials-filter.component';
import { TutorialPreviewComponent } from '@layout/tutorials/components/tutorial-preview/tutorial-preview.component';
import { TutorialsComponent } from '@layout/tutorials/components/tutorials-list/tutorials.component';
import { TutorialsCategoriesComponent } from '@layout/tutorials/components/tutorials-categories/tutorials-categories.component';
import { SuggestedTutorialsComponent } from '@layout/tutorials/components/suggested-tutorials/suggested-tutorials.component';
import { SuggestedTutorialCardComponent } from '@layout/tutorials/components/suggested-tutorial-card/suggested-tutorial-card.component';
import { SuggestedTutorialsByCategoryComponent } from '@layout/tutorials/components/suggested-tutorials-by-category/suggested-tutorials-by-category.component';
import { SuggestedTutorialsHeaderComponent } from '@layout/tutorials/components/suggested-tutorials-header/suggested-tutorials-header.component';
import { ProgressSpinnerComponent } from '@design/progress-spinner/components/progress-spinner.component';
import { TagComponent } from '@design/tag/components/tag.component';
import { SafePipe } from '@core/safe-url/pipes/safe.pipe';

@NgModule({
  declarations: [
    TutorialsDialogComponent,
    TutorialsCategoriesComponent,
    TutorialsComponent,
    TutorialPreviewComponent,
    TutorialsFilterComponent,
    TutorialCardComponent,
    SuggestedTutorialsComponent,
    SuggestedTutorialCardComponent,
    SuggestedTutorialsByCategoryComponent,
    SuggestedTutorialsHeaderComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    DialogModule,
    CardModule,
    NgxsModule.forFeature([...videoTutorialsState]),
    FilterModule,
    ProgressSpinnerComponent,
    TagComponent,
    SafePipe,
  ],
})
export class TutorialsModule {}
