import { ChangeDetectorRef, Component, ElementRef, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ICalendarDate } from '@design/datepicker/models/calendar-date.interface';
import { DateTime } from '@design/datepicker/services/date-time';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { chunk } from 'lodash-es';

@Component({
  selector: 'itc-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
})
export class DatepickerComponent implements OnInit, OnDestroy {
  @Input() minDate: Date;
  @Input() maxDate: Date;
  @Input() componentFormControl: FormControl<Date | null>;
  @Input() clearDates$?: Subject<void>;
  @Input() label = 'Date';

  currentDate: DateTime;
  weeks: Array<ICalendarDate[]> = [];
  isShowCalendar = false;
  isMonthView = false;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private elementRef: ElementRef,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  get selectedDate(): Date | null {
    return this.componentFormControl.value;
  }

  private static calculateFirstDayOfGrid(dateTime: DateTime): DateTime {
    const monthFirstDay = dateTime.getMonthFirstDay();
    return dateTime.getMonthFirstDate().subtractDays(monthFirstDay);
  }

  private static calculateLastDayOfGrid(dateTime: DateTime): DateTime {
    const monthLastDayOfTheWeek = dateTime.getMonthLastDay();
    return dateTime.getMonthLastDate().addDays(7 - monthLastDayOfTheWeek);
  }

  ngOnInit(): void {
    this.resetCurrentDate();
    this.renderCalendar();

    if (this.clearDates$) {
      this.clearDates$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
        this.resetCurrentDate();
        this.renderCalendar();
      });
    }
  }

  onClearClick(): void {
    this.componentFormControl.reset();
    this.componentFormControl.markAsDirty();
    this.resetCurrentDate();
    this.renderCalendar();
    this.isShowCalendar = false;
  }

  previousMonth(): void {
    this.currentDate = this.currentDate.subtractMonths(1).copy();
    this.renderCalendar();
  }

  nextMonth(): void {
    this.currentDate = this.currentDate.addMonths(1).copy();
    this.renderCalendar();
  }

  onDayChange(dateTime: DateTime): void {
    this.componentFormControl.reset();
    this.componentFormControl.setValue(dateTime.date);
    this.currentDate = dateTime.copy();
    this.renderCalendar();
    this.isShowCalendar = false;
    this.changeDetectorRef.detectChanges();
  }

  onYearChange(year: number): void {
    this.currentDate = this.currentDate.changeYearInDate(year).copy();
    this.renderCalendar();
  }

  onMonthChange(month: number): void {
    this.currentDate = this.currentDate.changeMonthInDate(month).copy();
    this.renderCalendar();
    this.isMonthView = false;
  }

  @HostListener('document:click', ['$event'])
  clickOut(event: Event): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.isShowCalendar = false;
    }
  }

  private resetCurrentDate(): void {
    this.currentDate = DateTime.today();
  }

  private renderCalendar(): void {
    this.weeks = chunk(this.fillDates(), 7);
  }

  private fillDates(): ICalendarDate[] {
    const firstDayOfGrid = DatepickerComponent.calculateFirstDayOfGrid(this.currentDate.copy());
    const lastDayOfGrid = DatepickerComponent.calculateLastDayOfGrid(this.currentDate.copy());
    const dates: ICalendarDate[] = [];
    const calculatedDate = firstDayOfGrid.copy();

    while (calculatedDate.getIsBefore(lastDayOfGrid)) {
      calculatedDate.addDays(1);
      const mappedDate = this.mapDateTimeToICalendarDate(calculatedDate.copy());
      dates.push(mappedDate);
    }
    return dates;
  }

  private mapDateTimeToICalendarDate(dateTime: DateTime): ICalendarDate {
    return {
      today: DateTime.today().getIsTheSameDate(dateTime),
      selected: !!this.selectedDate && new DateTime(this.selectedDate).getIsTheSameDate(dateTime),
      date: dateTime,
    };
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
