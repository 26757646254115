import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { patch } from '@ngxs/store/operators';
import { catchError, finalize, tap } from 'rxjs/operators';
import { ProfessionalServicesHasAnyProjectsService } from '@pages/professional-services/services/professional-services-has-any-projects.service';
import { LoadHasAnyProjects } from '@pages/professional-services/store/actions/professional-services-has-any-projects.actions';

interface IProfessionalServicesHasAnyProjectsStateModel {
  hasAnyProjects?: boolean;
  loading: boolean;
  error: boolean;
}

@State<IProfessionalServicesHasAnyProjectsStateModel>({
  name: 'professionalServicesHasAnyProjects',
  defaults: {
    loading: false,
    error: false,
  },
})
@Injectable()
export class ProfessionalServicesHasAnyProjectsState {
  constructor(private professionalServicesHasAnyProjectsService: ProfessionalServicesHasAnyProjectsService) {}

  @Selector()
  static hasAnyProjects(state: IProfessionalServicesHasAnyProjectsStateModel): boolean | undefined {
    return state.hasAnyProjects;
  }

  @Selector()
  static loading(state: IProfessionalServicesHasAnyProjectsStateModel): boolean {
    return state.loading;
  }

  @Selector()
  static error(state: IProfessionalServicesHasAnyProjectsStateModel): boolean {
    return state.error;
  }

  @Action(LoadHasAnyProjects)
  loadHasAnyProjects(context: StateContext<IProfessionalServicesHasAnyProjectsStateModel>): Observable<boolean> {
    context.setState(patch<IProfessionalServicesHasAnyProjectsStateModel>({ loading: true }));

    return this.professionalServicesHasAnyProjectsService.getProfessionalServicesHasAnyProjects().pipe(
      tap(hasAnyProjects =>
        context.setState(
          patch<IProfessionalServicesHasAnyProjectsStateModel>({
            hasAnyProjects,
            error: false,
          })
        )
      ),
      catchError(() => {
        context.setState(
          patch<IProfessionalServicesHasAnyProjectsStateModel>({
            hasAnyProjects: undefined,
            error: true,
          })
        );
        return EMPTY;
      }),
      finalize(() => context.setState(patch<IProfessionalServicesHasAnyProjectsStateModel>({ loading: false })))
    );
  }
}
