import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class WhatsNewService {
  private readonly whatsNew = 'api/v1/application-settings/whats-new-page-url';

  constructor(private httpClient: HttpClient) {}

  getWhatsNewUrl(): Observable<string> {
    return this.httpClient.get<string>(environment.portalApiUrl + this.whatsNew);
  }
}
