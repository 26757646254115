import { IK365OnboardingSubscription } from '@pages/k365-setup-guides/models/k365-onboarding-subscription.interface';

export class LoadK365OnboardingSubscriptions {
  static readonly type = '[K365OnboardingSubscriptions] Load K365 Onboarding Subscriptions';
}

export class SelectK365OnboardingSubscription {
  static readonly type = '[K365OnboardingSubscriptions] Select K365 Onboarding Subscription';

  constructor(readonly subscription: IK365OnboardingSubscription) {}
}

export class SetSelectedK365OnboardingSubscription {
  static readonly type = '[K365OnboardingSubscriptions] Set Selected K365 Onboarding Subscription';
}

export class MarkK365OnboardingSubscriptionAsCompleted {
  static readonly type = '[K365OnboardingSubscriptions] Mark K365 Onboarding Subscription As Completed';

  constructor(readonly subscriptionId: string) {}
}
