import { ChangeDetectionStrategy, Component, HostListener, inject, Input } from '@angular/core';
import { SidePanelService } from '@design/side-panel/services/side-panel.service';
import { ISidePanelConfig } from '@design/side-panel/model/side-panel-config.interface';

@Component({
  selector: 'itc-side-panel',
  templateUrl: './side-panel.component.html',
  styleUrls: ['./side-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidePanelComponent {
  @Input() config: ISidePanelConfig;

  private sidePanelService = inject(SidePanelService);

  @HostListener('document:keydown.escape') onPressEscape(): void {
    if (this.config.closeOnEscape) {
      this.sidePanelService.closePanelIfExists();
    }
  }

  onBackdropClick(): void {
    if (this.config.closeOnBackdropClick) {
      this.sidePanelService.closePanelIfExists();
    }
  }
}
