import { Directive, HostListener, Input, OnDestroy } from '@angular/core';
import { filter, finalize, first, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { SsoTargetPage } from '@design/open-sso-link/models/sso-target-page.enum';
import { BehaviorSubject, Subject } from 'rxjs';
import { OpenSsoLinkService } from '@design/open-sso-link/services/open-sso-link.service';

@Directive({
  selector: '[itcOpenSsoLink]',
})
export class OpenSsoLinkDirective implements OnDestroy {
  @Input() itcOpenSsoLink: SsoTargetPage;
  @Input() isLoading$ = new BehaviorSubject(false);
  private readonly unsubscribe$ = new Subject<void>();

  constructor(private openSsoLinkService: OpenSsoLinkService) {}

  @HostListener('click')
  clickOnWidget(): void {
    this.isLoading$
      .pipe(
        first(),
        filter(isLoading => !isLoading),
        tap(() => this.isLoading$.next(true)),
        mergeMap(() =>
          this.openSsoLinkService.openSsoLink(this.itcOpenSsoLink).pipe(
            finalize(() => {
              this.isLoading$.next(false);
            })
          )
        ),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
