import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardComponent } from '@design/card/components/card/card.component';
import { CardCountComponent } from '@design/card/components/card-count/card-count.component';
import { CardHeaderComponent } from '@design/card/components/card-header/card-header.component';
import { TranslateModule } from '@ngx-translate/core';
import { CardLoaderComponent } from '@design/card/components/card-loader/card-loader.component';
import { CardLoaderDirective } from '@design/card/directive/card-loader.directive';
import { ProgressSpinnerComponent } from '@design/progress-spinner/components/progress-spinner.component';

@NgModule({
  declarations: [CardComponent, CardCountComponent, CardHeaderComponent, CardLoaderComponent, CardLoaderDirective],
  exports: [CardComponent, CardCountComponent, CardHeaderComponent, CardLoaderDirective],
  imports: [CommonModule, TranslateModule, ProgressSpinnerComponent],
})
export class CardModule {}
