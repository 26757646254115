import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OnboardingVideoDialogApiService {
  private readonly welcomeVideoViewStatusUrl = 'api/v1/user/video-tutorials/settings/welcome-video-viewed';

  constructor(private httpClient: HttpClient) {}

  loadIsWelcomeVideoViewed(): Observable<boolean> {
    return this.httpClient.get<boolean>(environment.portalApiUrl + this.welcomeVideoViewStatusUrl);
  }

  markWelcomeVideoAsViewed(): Observable<void> {
    return this.httpClient.put<void>(environment.portalApiUrl + this.welcomeVideoViewStatusUrl, null);
  }
}
