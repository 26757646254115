import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { IK365SubscriptionActivationModule } from '@pages/k365-setup-guides/models/k365-subscription-activation-module.interface';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { IK365SubscriptionModule } from '@pages/k365-setup-guides/models/k365-subscription-module.interface';
import { IK365SubscriptionResources } from '@pages/k365-setup-guides/models/k365-subscription-resources.interface';

@Injectable({
  providedIn: 'root',
})
export class K365SetupGuidesService {
  private readonly httpClient = inject(HttpClient);

  getK365SubscriptionActivationModules(subscriptionId: string): Observable<IK365SubscriptionActivationModule[]> {
    const url = `api/v2/onboarding/subscriptions/${subscriptionId}/activation-modules`;

    return this.httpClient.get<IK365SubscriptionActivationModule[]>(environment.portalApiUrl + url);
  }

  getK365SubscriptionModules(subscriptionId: string): Observable<IK365SubscriptionModule[]> {
    const url = `api/v1/onboarding/subscriptions/${subscriptionId}/modules`;

    return this.httpClient.get<IK365SubscriptionModule[]>(environment.portalApiUrl + url);
  }

  getK365SubscriptionResources(subscriptionId: string): Observable<IK365SubscriptionResources> {
    const url = `api/v1/onboarding/subscriptions/${subscriptionId}/resource`;

    return this.httpClient.get<IK365SubscriptionResources>(environment.portalApiUrl + url);
  }
}
