export enum TranslationFeature {
  PartnerProgram = 'partner-program',
  ProfessionalServices = 'professional-services',
  CyberInsurance = 'cyber-insurance',
  Subscriptions = 'subscriptions',
  ExecutiveReports = 'executive-reports',
  OrganizationMapping = 'organization-mapping',
  Kaseya365SetupGuide = 'kaseya-365-setup-guide',
  AdminSettings = 'admin-settings',
  ThirdPartyIdp = 'third-party-idp',
  Support = 'support',
  TalentFinder = 'talent-finder',
  CooperBot = 'cooper-bot',
  ProfileSecurity = 'profile-security',
  Security = 'security',
  Onboarding = 'onboarding',
  Home = 'home',
}
