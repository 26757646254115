<div class="organization-managers" [class.organization-managers--expanded]="expanded">
  <div class="organization-managers__header" (click)="expanded = !expanded">
    <img ngSrc="assets/icons/organization-managers/user-group.svg" width="16" height="12" />
    <span class="organization-managers__header-title">
      {{ 'organization-managers.header-title' | translate }}
    </span>
    <img ngSrc="assets/icons/chevron-down-regular.svg" class="organization-managers__expander" width="16" height="12" />
  </div>
  @if ((loading$ | async) === false && ((accountManager$ | async) || (relationshipManager$ | async))) {
    <div class="organization-managers__content" [@expand]="expanded ? 'open' : 'close'">
      @if (accountManager$ | async; as accountManager) {
        <itc-manager [jobTitle]="'organization-managers.account-manager-job-title' | translate" [manager]="accountManager" />
      }

      @if (relationshipManager$ | async; as relationshipManager) {
        <div class="organization-managers__relationship">
          <div class="organization-managers__relationship-title">
            {{ 'organization-managers.relationship-template' | translate: { firstName: (accountManager$ | async)?.firstName } | uppercase }}
          </div>
          <itc-manager [jobTitle]="'organization-managers.relationship-manager-job-title' | translate" [manager]="relationshipManager" />
        </div>
      }
    </div>
  }
</div>
