import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpStatusCode } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LoggerService } from '@core/logger/services/logger.service';
import { loggable } from '@core/logger/models/context';

@Injectable()
export class LogErrorsInterceptor implements HttpInterceptor {
  constructor(private loggerService: LoggerService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((errorResponse: HttpErrorResponse) => {
        if (errorResponse.error instanceof ProgressEvent && navigator.onLine) {
          this.loggerService.logServerException(errorResponse);
        }

        if (this.isServerError(errorResponse)) {
          this.loggerService.logServerException(errorResponse);
        }

        if (this.isInvalidRequestError(errorResponse)) {
          if (request.context.get(loggable)) {
            this.loggerService.logServerException(errorResponse, request);
          } else {
            this.loggerService.logServerException(errorResponse);
          }
        }

        return throwError(() => errorResponse);
      })
    );
  }

  private isServerError(errorResponse: HttpErrorResponse): boolean {
    return (
      errorResponse.status >= HttpStatusCode.InternalServerError && errorResponse.status <= HttpStatusCode.NetworkAuthenticationRequired
    );
  }

  private isInvalidRequestError(errorResponse: HttpErrorResponse): boolean {
    return errorResponse.error === 'InvalidRequest';
  }
}
