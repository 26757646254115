import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { InputComponent } from '@design/input/components/input.component';
import { ReactiveFormsModule } from '@angular/forms';
import { InputErrorModule } from '@design/input-error/input-error.module';
import { TranslateModule } from '@ngx-translate/core';
import { RemoveSpacesDirective } from '@design/input/directives/remove-spaces.directive';
import { NgxMaskDirective } from 'ngx-mask';
import { NumbersOnlyDirective } from '@design/input/directives/numbers-only.directive';
import { ClipboardModule } from 'ngx-clipboard';
import { CopyButtonComponent } from '@design/copy-button/copy-button.component';
import { AutofocusDirective } from '@design/auto-focus/directives/auto-focus.directive';
import { ProgressSpinnerComponent } from '@design/progress-spinner/components/progress-spinner.component';

@NgModule({
  declarations: [InputComponent, RemoveSpacesDirective, NumbersOnlyDirective],
  exports: [InputComponent, NumbersOnlyDirective],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    InputErrorModule,
    TranslateModule,
    NgxMaskDirective,
    ClipboardModule,
    NgOptimizedImage,
    CopyButtonComponent,
    AutofocusDirective,
    ProgressSpinnerComponent,
  ],
})
export class InputModule {}
