import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { useMfaToken } from '@core/authorization/models/mfa-token-context';
import { Feature } from '@core/features/models/feature.enum';

@Injectable({
  providedIn: 'root',
})
export class FeaturesService {
  private readonly getFeaturesUrl = 'api/v1/organization/feature-flags';

  constructor(private httpClient: HttpClient) {}

  getFeatures(mfaToken?: string): Observable<Feature[]> {
    const httpOptions = {
      context: mfaToken ? useMfaToken(mfaToken) : undefined,
    };
    return this.httpClient.get<Feature[]>(environment.portalApiUrl + this.getFeaturesUrl, httpOptions);
  }
}
