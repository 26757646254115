import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class Kaseya365UpgradeService {
  constructor(private httpClient: HttpClient) {}

  getIsKaseya365Customer(): Observable<boolean> {
    const url = 'api/v1/salesforce/kaseya-365-customer';

    return this.httpClient.get<boolean>(environment.portalApiUrl + url);
  }
}
