import { Component, DestroyRef, inject } from '@angular/core';
import { AuthorizationService } from '@core/authorization/services/authorization.service';
import { ImpersonateBannerComponent } from '@layout/impersonate-banner/components/impersonate-banner/impersonate-banner.component';
import { HeaderComponent } from '@layout/header/components/header.component';
import { LeftPanelComponent } from '@layout/left-panel/components/left-panel/left-panel.component';
import { RouterOutlet } from '@angular/router';
import { RouteDataService } from '@core/route-data/services/route-data.service';
import { toSignal } from '@angular/core/rxjs-interop';
import { map } from 'rxjs/operators';

@Component({
  standalone: true,
  selector: 'itc-main-view',
  templateUrl: './main-view.component.html',
  styleUrls: ['./main-view.component.scss'],
  imports: [ImpersonateBannerComponent, HeaderComponent, LeftPanelComponent, RouterOutlet],
})
export class MainViewComponent {
  authorizationService = inject(AuthorizationService);
  routeDataService = inject(RouteDataService);
  destroyRef = inject(DestroyRef);
  fitsScreenWidth = toSignal(
    this.routeDataService.getDataWithTheDeepestProperty('fitsScreenWidth').pipe(map(value => value?.fitsScreenWidth))
  );

  get isImpersonatedUser(): boolean {
    return this.authorizationService.isImpersonatedUser();
  }
}
