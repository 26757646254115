import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UpperCasePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

@Component({
  standalone: true,
  selector: 'itc-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [UpperCasePipe],
})
export class AvatarComponent {
  @Input() firstName?: string;
  @Input() lastName?: string;
  @Input() photoUrl?: string | null;
  @Input() isSmall = true;

  readonly defaultPhotoSrc = 'assets/icons/avatar.png';

  constructor(private translateService: TranslateService) {}

  get elementDescriptor(): string {
    return `${this.firstName} ${this.lastName} ${this.translateService.instant('general.alts.avatar')}`;
  }

  getInitials(): string {
    if (this.firstName && this.lastName) {
      return this.getFirstLetter(this.firstName) + this.getFirstLetter(this.lastName);
    }

    return '';
  }

  private getFirstLetter(text: string): string {
    return text.charAt(0);
  }
}
