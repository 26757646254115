import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { INotification } from '@layout/notifications/models/notification.interface';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { NotificationSource } from '@layout/notifications/models/notification-source.enum';
import { IZendeskSsoRequestInfo } from '@core/sso/models/zendesk/zendesk-sso-request-info.interface';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  private readonly getNotificationsUrl = 'api/v1/user/notifications';
  private readonly markAllNotificationsAsReadUrl = 'api/v1/user/notifications/mark-all-as-read';
  private readonly deleteAllNotificationsUrl = 'api/v1/user/notifications';
  private readonly updateNotificationViewedUrl = (notificationId: string): string => `api/v1/user/notifications/${notificationId}/viewed`;
  private readonly deleteNotificationUrl = (notificationId: string): string => `api/v1/user/notifications/${notificationId}`;
  private readonly getZendeskSsoRequestInfoUrl = (ticketId: string): string => `api/v3/zendesk/tickets/${ticketId}/url`;

  constructor(private httpClient: HttpClient) {}

  getNotifications(): Observable<INotification[]> {
    return this.httpClient.post<INotification[]>(environment.portalApiUrl + this.getNotificationsUrl, null);
  }

  markAllNotificationsAsRead(): Observable<void> {
    return this.httpClient.post<void>(environment.portalApiUrl + this.markAllNotificationsAsReadUrl, null);
  }

  updateNotificationViewability(notificationId: string, source: NotificationSource, viewed: boolean): Observable<void> {
    return this.httpClient.put<void>(environment.portalApiUrl + this.updateNotificationViewedUrl(notificationId), { source, viewed });
  }

  deleteNotification(notificationId: string, source: NotificationSource): Observable<void> {
    return this.httpClient.delete<void>(environment.portalApiUrl + this.deleteNotificationUrl(notificationId), { params: { source } });
  }

  deleteAllNotifications(): Observable<void> {
    return this.httpClient.delete<void>(environment.portalApiUrl + this.deleteAllNotificationsUrl);
  }

  getZendeskSsoRequestInfo(ticketId: string): Observable<IZendeskSsoRequestInfo> {
    return this.httpClient.get<IZendeskSsoRequestInfo>(environment.portalApiUrl + this.getZendeskSsoRequestInfoUrl(ticketId));
  }
}
