import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Feature } from '@core/features/models/feature.enum';
import { ClearState, LoadFeatures } from '@core/features/store/actions/features.actions';
import { FeaturesService } from '@core/features/services/features.service';
import { StateReset } from 'ngxs-reset-plugin';

interface IFeaturesStateModel {
  features?: Record<Feature, boolean>;
}

@State<IFeaturesStateModel>({
  name: 'features',
})
@Injectable()
export class FeaturesState {
  constructor(private featuresService: FeaturesService) {}

  @Selector()
  static features(state: IFeaturesStateModel): Record<Feature, boolean> | undefined {
    return state.features;
  }

  @Selector()
  static isFeatureEnabled({ features }: IFeaturesStateModel): (feature: Feature) => boolean | undefined {
    return (feature: Feature) => features?.[feature];
  }

  @Action(LoadFeatures)
  loadFeatures(context: StateContext<IFeaturesStateModel>, { mfaToken }: LoadFeatures): Observable<Feature[]> {
    return this.featuresService.getFeatures(mfaToken).pipe(
      tap(features => {
        context.patchState({ features: FeaturesState.getMappedFeatures(features) });
      })
    );
  }

  @Action(ClearState)
  clearState(context: StateContext<IFeaturesStateModel>): void {
    context.dispatch(new StateReset(FeaturesState));
  }

  private static getMappedFeatures(features: Feature[]): Record<Feature, boolean> {
    const allFeatures = Object.values(Feature);
    return allFeatures.reduce(
      (accumulator, feature) => {
        accumulator[feature] = features.includes(feature);
        return accumulator;
      },
      {} as Record<Feature, boolean>
    );
  }
}
