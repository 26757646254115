import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ImagePreloaderService {
  preload(urls: string[]): void {
    urls.forEach(url => this.fetchImage(url));
  }

  private fetchImage(url: string): void {
    new Image().src = url;
  }
}
