<div class="left-panel" [class.left-panel--collapsed]="isLeftPanelCollapsed">
  <div class="left-panel__navigation-scroll">
    <div class="left-panel__navigation">
      <itc-collapse-button [isCollapsed]="isLeftPanelCollapsed" (toggleCollapse)="toggleLeftPanel()" />
      <itc-left-navigation [isCollapsed]="isLeftPanelCollapsed" />
    </div>
  </div>
  <div *itcPermission="Permission.AccountManagers" class="left-panel__footer">
    @if (isLeftPanelCollapsed) {
      <itc-managers-button (click)="toggleLeftPanel()" />
    } @else {
      <itc-organization-managers />
    }
  </div>
</div>
