<div class="year__container">
  <div [ngClass]="{ year: true, 'year-selected': isOpenSelect }" (click)="isOpenSelect = true">
    <div class="year__text">{{ currentDate.date | date: 'yyyy' }}</div>
    <div class="year__icon"></div>
  </div>
  @if (isOpenSelect) {
    <div class="select">
      @for (year of years; track year) {
        <div class="select__item" (click)="selectYear($event, year)">{{ year }}</div>
      }
    </div>
  }
</div>
