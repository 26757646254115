import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { patch } from '@ngxs/store/operators';
import { catchError, finalize, tap } from 'rxjs/operators';
import { ProfessionalServicesService } from '@pages/professional-services/services/professional-services.service';
import { LoadSessionProjects } from '@pages/professional-services/store/actions/professional-services-projects.actions';
import { ISessionProject } from '@pages/professional-services/models/table/session-project.interface';
import { ISessionProjectsResponse } from '@pages/professional-services/models/table/session-projects-response.interface';
import { nonFlickerLoader } from '@shared/rxjs-operators/non-flicker-loader';

interface IProfessionalServicesProjectsStateModel {
  sessionProjects?: ISessionProject[];
  totalCount?: number;
  isAnyExpandableRow?: boolean;
  loading: boolean;
  error: boolean;
}

@State<IProfessionalServicesProjectsStateModel>({
  name: 'professionalServicesProjects',
  defaults: {
    loading: false,
    error: false,
  },
})
@Injectable()
export class ProfessionalServicesProjectsState {
  constructor(private professionalServicesService: ProfessionalServicesService) {}

  @Selector()
  static sessionProjects(state: IProfessionalServicesProjectsStateModel): ISessionProject[] | undefined {
    return state.sessionProjects;
  }

  @Selector()
  static totalCount(state: IProfessionalServicesProjectsStateModel): number | undefined {
    return state.totalCount;
  }

  @Selector()
  static isAnyExpandableRow(state: IProfessionalServicesProjectsStateModel): boolean | undefined {
    return state.isAnyExpandableRow;
  }

  @Selector()
  static loading(state: IProfessionalServicesProjectsStateModel): boolean {
    return state.loading;
  }

  @Selector()
  static error(state: IProfessionalServicesProjectsStateModel): boolean {
    return state.error;
  }

  @Action(LoadSessionProjects)
  loadSessionProjects(
    context: StateContext<IProfessionalServicesProjectsStateModel>,
    { sessionProjectsRequest }: LoadSessionProjects
  ): Observable<ISessionProjectsResponse | undefined> {
    return nonFlickerLoader(this.professionalServicesService.getProfessionalServicesSessionProjects(sessionProjectsRequest)).pipe(
      tap(sessionProjectsResponse => {
        if (sessionProjectsResponse === undefined) {
          context.setState(patch<IProfessionalServicesProjectsStateModel>({ loading: true }));
          return;
        }

        context.setState(
          patch<IProfessionalServicesProjectsStateModel>({
            sessionProjects: sessionProjectsResponse.items,
            totalCount: sessionProjectsResponse.totalCount,
            isAnyExpandableRow: this.isAnyExpandableRow(sessionProjectsResponse.items),
            error: false,
          })
        );
      }),
      catchError(() => {
        context.setState(
          patch<IProfessionalServicesProjectsStateModel>({
            sessionProjects: undefined,
            totalCount: undefined,
            isAnyExpandableRow: undefined,
            error: true,
          })
        );
        return EMPTY;
      }),
      finalize(() => context.setState(patch<IProfessionalServicesProjectsStateModel>({ loading: false })))
    );
  }

  private isAnyExpandableRow(sessionProjects: ISessionProject[]): boolean {
    return sessionProjects.some(({ sessions }) => sessions.length > 0);
  }
}
