import { IUser } from '@pages/first-time-onboarding/models/user.interface';
import { OnboardingOption } from '@pages/first-time-onboarding/models/onboarding-option.enum';

export class SelectOnboardingOption {
  static readonly type = '[InviteUser] Select Onboarding Option';
  constructor(public onboardingOption: OnboardingOption) {}
}

export class CheckUserExistence {
  static readonly type = '[InviteUser] Check User Existence';
  constructor(public userEmail: string) {}
}

export class InviteUser {
  static readonly type = '[InviteUser] Invite User';
  constructor(public user: IUser) {}
}

export class InviteUserCompleted {
  static readonly type = '[InviteUser] Invite User Completed';
}
