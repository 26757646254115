import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { patch } from '@ngxs/store/operators';
import { catchError, finalize, tap } from 'rxjs/operators';
import { ISessionDetails } from '@pages/professional-services/models/details/session-details.interface';
import { ProfessionalServicesSessionDetailsService } from '@pages/professional-services/services/professional-services-session-details.service';
import { LoadSessionDetails } from '@pages/professional-services/store/actions/professional-services-session-details.actions';

interface IProfessionalServicesSessionDetailsStateModel {
  sessionDetails?: ISessionDetails;
  loading: boolean;
  error: boolean;
}

@State<IProfessionalServicesSessionDetailsStateModel>({
  name: 'professionalServicesSessionDetails',
  defaults: {
    loading: false,
    error: false,
  },
})
@Injectable()
export class ProfessionalServicesSessionDetailsState {
  constructor(private professionalServicesSessionDetailsService: ProfessionalServicesSessionDetailsService) {}

  @Selector()
  static sessionDetails(state: IProfessionalServicesSessionDetailsStateModel): ISessionDetails | undefined {
    return state.sessionDetails;
  }

  @Selector()
  static loading(state: IProfessionalServicesSessionDetailsStateModel): boolean {
    return state.loading;
  }

  @Selector()
  static error(state: IProfessionalServicesSessionDetailsStateModel): boolean {
    return state.error;
  }

  @Action(LoadSessionDetails)
  loadSessionDetails(
    context: StateContext<IProfessionalServicesSessionDetailsStateModel>,
    { projectId, sessionId }: LoadSessionDetails
  ): Observable<ISessionDetails> {
    context.setState(patch<IProfessionalServicesSessionDetailsStateModel>({ loading: true }));

    return this.professionalServicesSessionDetailsService.getProfessionalServicesSessionDetails(projectId, sessionId).pipe(
      tap(sessionDetails =>
        context.setState(
          patch<IProfessionalServicesSessionDetailsStateModel>({
            sessionDetails,
            error: false,
          })
        )
      ),
      catchError(() => {
        context.setState(
          patch<IProfessionalServicesSessionDetailsStateModel>({
            sessionDetails: undefined,
            error: true,
          })
        );
        return EMPTY;
      }),
      finalize(() => context.setState(patch<IProfessionalServicesSessionDetailsStateModel>({ loading: false })))
    );
  }
}
