import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DatepickerModule } from '@design/datepicker/datepicker.module';
import { SuggestionsComponent } from '@design/filter/components/suggestions/suggestions.component';
import { MultiselectFilterComponent } from '@design/filter/components/filters/multiselect-filter/multiselect-filter.component';
import { InputFilterComponent } from '@design/filter/components/filters/input-filter/input-filter.component';
import { SmartFilterComponent } from '@design/filter/components/smart-filter.component';
import { MultiselectChipComponent } from '@design/filter/components/chips/multiselect-chip/multiselect-chip.component';
import { ChipComponent } from '@design/filter/components/chips/chip/chip.component';
import { TextChipComponent } from '@design/filter/components/chips/text-chip/text-chip.component';
import { TranslateModule } from '@ngx-translate/core';
import { DateRangeFilterComponent } from '@design/filter/components/filters/date-range-filter/date-range-filter.component';
import { DateRangeChipComponent } from '@design/filter/components/chips/date-range-chip/date-range-chip.component';
import { InputModule } from '@design/input/input.module';
import { CheckboxComponent } from '@design/checkbox/components/checkbox.component';
import { ProgressSpinnerComponent } from '@design/progress-spinner/components/progress-spinner.component';

@NgModule({
  declarations: [
    SuggestionsComponent,
    MultiselectFilterComponent,
    DateRangeFilterComponent,
    InputFilterComponent,
    SmartFilterComponent,
    MultiselectChipComponent,
    DateRangeChipComponent,
    TextChipComponent,
    ChipComponent,
  ],
  exports: [SmartFilterComponent],
  imports: [
    CommonModule,
    FormsModule,
    DatepickerModule,
    ReactiveFormsModule,
    TranslateModule,
    InputModule,
    CheckboxComponent,
    ProgressSpinnerComponent,
  ],
})
export class FilterModule {}
