@if (menuItem.externalLink) {
  <div class="navigation-external-link__link" (click)="openExternalLink()">
    <ng-template *ngTemplateOutlet="linkContent" />
  </div>
}

@if (menuItem.ssoTargetPage) {
  <div class="navigation-external-link__sso-link" [itcOpenSsoLink]="menuItem.ssoTargetPage" [isLoading$]="isLoading$">
    <ng-template *ngTemplateOutlet="linkContent" />
  </div>
}

<ng-template #linkContent>
  @if ((isLoading$ | async) === true) {
    <div class="navigation-external-link__spinner">
      <itc-progress-spinner [spinnerSize]="14" [spinnerLineHeight]="14" />
    </div>
  }
  <div class="navigation-external-link__regular-link">
    <itc-navigation-link
      [testId]="testId"
      [iconSrc]="getIconPath(menuItem.iconName)"
      [linkName]="'navigation.items.' + menuItem.id | translate"
      [isTooltipEnabled]="isTooltipEnabled"
    />
    @if (!isTooltipEnabled) {
      <img class="navigation-external-link__external-icon" ngSrc="assets/icons/external-link-regular.svg" height="12" width="12" />
    }
  </div>
</ng-template>
