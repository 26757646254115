<header class="header-container" [attr.aria-label]="'header.label' | translate">
  <div class="header-container__wave">
    <div class="header-container__logo" routerLink=""></div>
    <div class="header-container__actions">
      <itc-notifications *itcPermission="Permission.Notifications" />
      <itc-help-menu />
      <itc-profile-menu />
      <itc-launcher />
    </div>
  </div>
</header>
