import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RouteDataService } from '@core/route-data/services/route-data.service';

@Injectable({
  providedIn: 'root',
})
export class MobileVersionManagerService {
  constructor(private routeDataService: RouteDataService) {}

  isMobileVersionDisabled(): Observable<boolean> {
    return this.routeDataService.getDataOfTheLastRoute().pipe(map(data => data.disableMobileVersion ?? false));
  }
}
