import { ChangeDetectionStrategy, Component, DestroyRef, inject, Input } from '@angular/core';
import { asNonUndefined } from '@shared/utils/as-non-undefined';
import { BehaviorSubject, filter, finalize } from 'rxjs';
import { IExternalMenuItem } from '@layout/left-navigation/model/menu-item.interface';
import { iconBasePath } from '@layout/left-navigation/model/icon-paths';
import { AsyncPipe, NgOptimizedImage, NgTemplateOutlet } from '@angular/common';
import { NavigationLinkComponent } from '@layout/left-navigation/components/navigation-link/navigation-link.component';
import { TranslateModule } from '@ngx-translate/core';
import { OpenSsoLinkModule } from '@design/open-sso-link/open-sso-link.module';
import { ProgressSpinnerComponent } from '@design/progress-spinner/components/progress-spinner.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  standalone: true,
  selector: 'itc-navigation-external-link',
  templateUrl: './navigation-external-link.component.html',
  styleUrls: ['./navigation-external-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgOptimizedImage,
    NavigationLinkComponent,
    TranslateModule,
    OpenSsoLinkModule,
    AsyncPipe,
    NgTemplateOutlet,
    ProgressSpinnerComponent,
  ],
})
export class NavigationExternalLinkComponent {
  @Input({ required: true }) menuItem: IExternalMenuItem;
  @Input({ required: true }) isTooltipEnabled: boolean;
  @Input() testId?: string;

  readonly isLoading$ = new BehaviorSubject(false);
  private destroyRef = inject(DestroyRef);

  openExternalLink(): void {
    if (this.isLoading$.value) {
      return;
    }

    this.isLoading$.next(true);
    asNonUndefined(this.menuItem.externalLink)
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter(Boolean),
        finalize(() => this.isLoading$.next(false))
      )
      .subscribe(link => window.open(link));
  }

  getIconPath(iconName: string): string {
    return iconBasePath + iconName;
  }
}
