import { Injectable } from '@angular/core';
import { TutorialCategory } from '@layout/tutorials/models/tutorial-category.enum';
import { ITutorialFilter } from '@layout/tutorials/models/tutorial-filter.interface';
import { ITutorial } from '@layout/tutorials/models/tutorial.interface';
import { TutorialsListState } from '@layout/tutorials/models/tutorials-list-state.enum';
import { ITutorialsResponse } from '@layout/tutorials/models/tutorials-response.interface';
import { TutorialsService } from '@layout/tutorials/services/tutorials.service';
import {
  LoadSuggestedTutorials,
  LoadTutorials,
  ResetState,
  SelectCategory,
  SelectTutorial,
  SetSearchKey,
  UnselectTutorial,
} from '@layout/tutorials/store/actions/tutorials.actions';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { groupBy } from 'lodash-es';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

interface ITutorialsStateModel {
  tutorialsListState?: TutorialsListState;
  suggestedTutorialsListState?: TutorialsListState;
  tutorials?: ITutorial[];
  totalTutorialsCount?: number;
  suggestedTutorialsByCategory?: Record<TutorialCategory, ITutorial[]>;
  selectedTutorial?: string;
  filter: ITutorialFilter;
  selectedCategory: TutorialCategory;
}

const tutorialsStateDefaults: ITutorialsStateModel = {
  filter: {},
  selectedCategory: TutorialCategory.All,
};

@State<ITutorialsStateModel>({
  name: 'tutorials',
  defaults: tutorialsStateDefaults,
})
@Injectable()
export class TutorialsState {
  constructor(private videoTutorialsService: TutorialsService) {}

  @Selector()
  static tutorials(state: ITutorialsStateModel): ITutorial[] | undefined {
    return state.tutorials;
  }

  @Selector()
  static totalTutorialsCount(state: ITutorialsStateModel): number | undefined {
    return state.totalTutorialsCount;
  }

  @Selector()
  static tutorialsByCategory(state: ITutorialsStateModel): ITutorial[] | undefined {
    return state.tutorials?.filter(tutorial => tutorial.category === state.selectedCategory);
  }

  @Selector()
  static foundTutorialsCount(state: ITutorialsStateModel): number | undefined {
    return state.tutorials?.length;
  }

  @Selector()
  static suggestedTutorialsByCategory(state: ITutorialsStateModel): Record<TutorialCategory, ITutorial[]> | undefined {
    return state.suggestedTutorialsByCategory;
  }

  @Selector()
  static selectedTutorial(state: ITutorialsStateModel): ITutorial | undefined {
    return state.tutorials?.find(tutorial => tutorial.id === state.selectedTutorial);
  }

  @Selector()
  static tutorialsListState(state: ITutorialsStateModel): TutorialsListState | undefined {
    return state.tutorialsListState;
  }

  @Selector()
  static suggestedTutorialsListState(state: ITutorialsStateModel): TutorialsListState | undefined {
    return state.suggestedTutorialsListState;
  }

  @Selector()
  static filter(state: ITutorialsStateModel): ITutorialFilter {
    return state.filter;
  }

  @Action(LoadTutorials)
  loadVideoTutorials(context: StateContext<ITutorialsStateModel>): Observable<ITutorialsResponse> {
    const { filter } = context.getState();
    context.patchState({ tutorials: undefined, tutorialsListState: TutorialsListState.Loading });
    return this.videoTutorialsService.getTutorials(filter).pipe(
      tap(tutorialsResponse => {
        context.patchState({
          tutorials: tutorialsResponse.items,
          totalTutorialsCount: tutorialsResponse.totalCount,
          tutorialsListState: tutorialsResponse.items.length ? TutorialsListState.LoadedList : TutorialsListState.LoadedEmptyList,
        });
      }),
      catchError(error => {
        context.patchState({
          tutorialsListState: TutorialsListState.LoadingError,
        });
        return throwError(error);
      })
    );
  }

  @Action(SelectTutorial)
  selectTutorial(context: StateContext<ITutorialsStateModel>, { id }: SelectTutorial): void {
    context.patchState({ selectedTutorial: id });
  }

  @Action(UnselectTutorial)
  unselectTutorial(context: StateContext<ITutorialsStateModel>): void {
    context.patchState({
      selectedTutorial: undefined,
      suggestedTutorialsByCategory: undefined,
    });
  }

  @Action(SelectCategory)
  selectCategory(context: StateContext<ITutorialsStateModel>, { category }: SelectCategory): void {
    const { filter } = context.getState();
    context.patchState({
      filter: {
        ...filter,
        category,
      },
    });
    context.dispatch(new LoadTutorials());
  }

  @Action(SetSearchKey)
  setSearchKey(context: StateContext<ITutorialsStateModel>, { searchKey }: SetSearchKey): void {
    const { filter } = context.getState();
    context.patchState({
      filter: {
        ...filter,
        searchKey,
      },
    });
    context.dispatch(new LoadTutorials());
  }

  @Action(LoadSuggestedTutorials)
  loadSuggestedTutorials(context: StateContext<ITutorialsStateModel>, { category }: LoadSuggestedTutorials): Observable<ITutorial[]> {
    context.patchState({ suggestedTutorialsByCategory: undefined, suggestedTutorialsListState: TutorialsListState.Loading });
    return this.videoTutorialsService.getSuggestedTutorials(category).pipe(
      tap(suggestedTutorials => {
        context.patchState({
          suggestedTutorialsByCategory: groupBy(suggestedTutorials, 'category') as Record<TutorialCategory, ITutorial[]>,
          suggestedTutorialsListState: suggestedTutorials.length ? TutorialsListState.LoadedList : TutorialsListState.LoadedEmptyList,
        });
      }),
      catchError(error => {
        context.patchState({
          suggestedTutorialsListState: TutorialsListState.LoadingError,
        });
        return throwError(error);
      })
    );
  }

  @Action(ResetState)
  resetState(context: StateContext<ITutorialsStateModel>): void {
    context.setState(tutorialsStateDefaults);
  }
}
