import { Action, Actions, ofActionDispatched, Selector, State, StateContext } from '@ngxs/store';
import { inject, Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { finalize, takeUntil, tap } from 'rxjs/operators';
import { OrganizationMappingSynchronizationStatus } from '@pages/organization-mapping/models/organization-mapping-synchronization-status.enum';
import {
  CancelLoadingSynchronizationStatus,
  LoadSynchronizationStatus,
  TriggerSynchronization,
} from '@pages/organization-mapping/store/actions/organization-mapping-synchronization.actions';
import { OrganizationMappingSynchronizationService } from '@pages/organization-mapping/services/organization-mapping-synchronization.service';

interface IOrganizationMappingSynchronizationStateModel {
  synchronizationStatus?: OrganizationMappingSynchronizationStatus;
  loading: boolean;
}

@State<IOrganizationMappingSynchronizationStateModel>({
  name: 'organizationMappingSynchronization',
  defaults: {
    loading: false,
  },
})
@Injectable()
export class OrganizationMappingSynchronizationState {
  private readonly organizationMappingSynchronizationService = inject(OrganizationMappingSynchronizationService);
  private readonly actions$ = inject(Actions);

  @Selector()
  static synchronizationStatus(state: IOrganizationMappingSynchronizationStateModel): OrganizationMappingSynchronizationStatus | undefined {
    return state.synchronizationStatus;
  }

  @Action(LoadSynchronizationStatus)
  loadSynchronizationStatus(
    context: StateContext<IOrganizationMappingSynchronizationStateModel>
  ): Observable<OrganizationMappingSynchronizationStatus | undefined> {
    if (context.getState().loading) {
      return EMPTY;
    }

    context.patchState({ loading: true });
    return this.organizationMappingSynchronizationService.getSynchronizationStatus().pipe(
      tap(synchronizationStatus => {
        context.patchState({ synchronizationStatus });
      }),
      finalize(() => {
        context.patchState({ loading: false });
      }),
      takeUntil(this.actions$.pipe(ofActionDispatched(CancelLoadingSynchronizationStatus)))
    );
  }

  @Action(TriggerSynchronization)
  triggerSynchronization(context: StateContext<IOrganizationMappingSynchronizationStateModel>): Observable<void> {
    context.patchState({
      synchronizationStatus: OrganizationMappingSynchronizationStatus.SynchronizationInProgress,
    });
    return this.organizationMappingSynchronizationService.triggerSynchronization();
  }
}
