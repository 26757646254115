import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ICalendarDate } from '@design/datepicker/models/calendar-date.interface';
import { DateTime } from '@design/datepicker/services/date-time';
import { DateTimeLabelsService } from '@design/datepicker/services/date-time-labels.service';

@Component({
  selector: 'itc-days',
  templateUrl: './days.component.html',
  styleUrls: ['./days.component.scss'],
})
export class DaysComponent {
  @Input({ required: true }) weeks: ICalendarDate[][];
  @Input({ required: true }) currentDate: DateTime;
  @Input({ required: true }) minDate: Date;
  @Input({ required: true }) maxDate: Date;
  @Output() changeDate = new EventEmitter<DateTime>();

  daysNames: string[] = this.dateTimeLabelsService.getShortWeekdays();

  constructor(private dateTimeLabelsService: DateTimeLabelsService) {}

  isSelectedMonth(date: DateTime): boolean {
    return this.currentDate.getIsTheSameMonth(date);
  }

  isDisabledDays(day: DateTime): boolean {
    if (this.minDate) {
      return day.getIsBefore(new DateTime(this.minDate));
    }

    if (this.maxDate) {
      return day.getIsAfter(new DateTime(this.maxDate));
    }

    return false;
  }

  selectDate(event: Event, date: DateTime): void {
    event.stopPropagation();

    this.changeDate.emit(date);
  }
}
