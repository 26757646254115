export const environment = {
  production: true,
  envName: 'qa',
  portalApiUrl: 'https://portal-api.qa-one.kaseya.com/',
  backOfficeUrl: 'https://kaseya1admincsiqa.z6.web.core.windows.net/',
  helpAndSupportLink: 'https://kaseya1523304719.zendesk.com',
  appInsightsInstrumentationKey: '24fd105f-600f-4a1c-b419-65928da44849',
  walkMeSnippetUrl: 'https://walkme.k1.datto.com/qa/walkme_7d19d2ded9a9416ba301fe0c4bef5ef0_https.js',
  zendeskTicketsUrl: 'https://kaseya1523304719.zendesk.com/access/normal',
};
