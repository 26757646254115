import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ApplicationVersionService {
  private readonly applicationVersion = 'api/v1/application-settings/version';

  constructor(private httpClient: HttpClient) {}

  getApplicationVersion(): Observable<string> {
    return this.httpClient.get(environment.portalApiUrl + this.applicationVersion, { responseType: 'text' });
  }
}
