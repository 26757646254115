import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { routes } from '@app/routes';

@NgModule({
  declarations: [],
  imports: [CommonModule, RouterModule.forRoot(routes, { bindToComponentInputs: true, onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
