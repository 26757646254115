import { ITagColors } from '@design/tag/models/tag-colors.interface';
import { TagColorName } from '@design/tag/models/tag-color-name.enum';
import { getCssVariableValue } from '@shared/utils/css-variable-value';

export const tagColorThemes: Record<TagColorName, ITagColors> = {
  [TagColorName.Default]: {
    backgroundColor: '#B3C4CB',
    color: getCssVariableValue('--font-color-dark-blue'),
  },
  [TagColorName.OceanBlue]: {
    backgroundColor: '#E0EDFA',
    color: '#0089DE',
  },
  [TagColorName.DarkBlue]: {
    backgroundColor: '#4573D2',
    color: '#ffffff',
  },
  [TagColorName.SalmonPink]: {
    backgroundColor: '#FC979A',
    color: getCssVariableValue('--font-color-dark-blue'),
  },
  [TagColorName.Green]: {
    backgroundColor: '#AECF55',
    color: getCssVariableValue('--font-color-dark-blue'),
  },
  [TagColorName.CharcoalGray]: {
    backgroundColor: '#505355',
    color: '#ffffff',
  },
  [TagColorName.LightGreen]: {
    backgroundColor: '#E0EFE7',
    color: '#389E64',
  },
  [TagColorName.SolidTeal]: {
    backgroundColor: '#4ECBC4',
    color: getCssVariableValue('--font-color-dark-blue'),
  },
  [TagColorName.Teal]: {
    backgroundColor: '#C9EFED',
    color: '#1A5A56',
  },
  [TagColorName.BluePurple]: {
    backgroundColor: '#8D84E8',
    color: '#ffffff',
  },
  [TagColorName.Pink]: {
    backgroundColor: '#F9AAEF',
    color: getCssVariableValue('--font-color-dark-blue'),
  },
  [TagColorName.DarkGreen]: {
    backgroundColor: '#25BF79',
    color: getCssVariableValue('--font-color-dark-blue'),
  },
  [TagColorName.Black]: {
    backgroundColor: '#ECECEC',
    color: getCssVariableValue('--font-color-dark-blue'),
  },
  [TagColorName.Critical]: {
    backgroundColor: '#FFD2CC',
    color: getCssVariableValue('--font-color-dark-blue'),
  },
  [TagColorName.SolidGreen]: {
    backgroundColor: '#33CC39',
    color: getCssVariableValue('--font-color-dark-blue'),
  },
  [TagColorName.SolidGray]: {
    backgroundColor: '#515151',
    color: getCssVariableValue('--font-color-dark-blue'),
  },
  [TagColorName.SolidDefault]: {
    backgroundColor: '#BFC5CB',
    color: getCssVariableValue('--font-color-dark-blue'),
  },
  [TagColorName.SolidCritical]: {
    backgroundColor: '#811508',
    color: '#ffffff',
  },
  [TagColorName.SolidBlue]: {
    backgroundColor: '#9EE7E3',
    color: getCssVariableValue('--font-color-dark-blue'),
  },
  [TagColorName.Success]: {
    backgroundColor: '#ECFAED',
    color: '#175D1A',
  },
  [TagColorName.Danger]: {
    backgroundColor: '#FFEDEB',
    color: '#811508',
  },
  [TagColorName.Gray]: {
    backgroundColor: getCssVariableValue('--font-color-light-dull-gray'),
    color: getCssVariableValue('--font-color-dark-gray'),
  },
  [TagColorName.LowSeverity]: {
    backgroundColor: '#FFF7D6',
    color: '#7F5F01',
  },
  [TagColorName.Purple]: {
    backgroundColor: '#EDE2F5',
    color: '#7326D3',
  },
  [TagColorName.New]: {
    backgroundColor: '#EDFAFE',
    color: '#005D89',
  },
  [TagColorName.Orange]: {
    backgroundColor: '#FEC57B',
    color: getCssVariableValue('--font-color-dark-blue'),
  },
  [TagColorName.SolidPurple]: {
    backgroundColor: '#893DE7',
    color: '#FFFFFF',
  },
  [TagColorName.SolidYellow]: {
    backgroundColor: '#FFCF33',
    color: getCssVariableValue('--font-color-dark-blue'),
  },
  [TagColorName.SolidSuccess]: {
    backgroundColor: '#33CC39',
    color: getCssVariableValue('--font-color-dark-blue'),
  },
  [TagColorName.SolidIndigo]: {
    backgroundColor: '#5258E4',
    color: '#FFFFFF',
  },
  [TagColorName.SolidOrange]: {
    backgroundColor: '#F18D13',
    color: getCssVariableValue('--font-color-dark-blue'),
  },
  [TagColorName.Blue]: {
    backgroundColor: '#E8F1FF',
    color: '#033F9F',
  },
};
