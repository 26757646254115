<a
  class="navigation-link__item"
  routerLinkActive="navigation-link__item--active"
  tooltipCustomClass="navigation-link__tooltip"
  [attr.data-test-id]="testId"
  [routerLink]="routerLink"
  [itcTooltip]="linkName"
  [tooltipPosition]="'right-center'"
  [disableTooltip]="!isTooltipEnabled"
>
  <div aria-hidden="true" class="navigation-link__item-icon">
    <img class="navigation-link__item-icon-image" height="13" width="14" [ngSrc]="iconSrc" />
  </div>
  <span class="navigation-link__item-text">{{ linkName }}</span>
  <ng-content />
</a>
