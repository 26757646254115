import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { OrganizationManagersFacade } from '@layout/organization-managers/facades/organization-managers.facade';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { AsyncPipe, NgOptimizedImage, UpperCasePipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ManagerComponent } from '@layout/organization-managers/components/manager/manager.component';

@Component({
  standalone: true,
  selector: 'itc-organization-managers',
  templateUrl: './organization-managers.component.html',
  styleUrls: ['./organization-managers.component.scss'],
  providers: [OrganizationManagersFacade],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('expand', [
      state('open', style({ height: '*' })),
      state('close', style({ opacity: '0', padding: '0', height: '0px' })),
      transition('open <=> close', animate('300ms ease-in-out')),
    ]),
  ],
  imports: [NgOptimizedImage, TranslateModule, AsyncPipe, UpperCasePipe, ManagerComponent],
})
export class OrganizationManagersComponent implements OnInit {
  accountManager$ = this.managerFacade.accountManager$;
  relationshipManager$ = this.managerFacade.relationshipManager$;
  loading$ = this.managerFacade.loading$;

  expanded = true;

  constructor(private managerFacade: OrganizationManagersFacade) {}

  ngOnInit(): void {
    this.managerFacade.loadManagers();
  }
}
