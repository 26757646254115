import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { IProjectsCount } from '@pages/professional-services/models/projects-widget/projects-count.interface';

@Injectable({
  providedIn: 'root',
})
export class ProfessionalServicesProjectsCountService {
  constructor(private httpClient: HttpClient) {}

  getProjectsCount(): Observable<IProjectsCount> {
    const url = 'api/v1/professional-services-dashboard/projects-count';

    return this.httpClient.get<IProjectsCount>(environment.portalApiUrl + url);
  }
}
