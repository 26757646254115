import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { IK365Subscription } from '@layout/k365-subscriptions/models/k365-subscription.interface';

@Injectable({
  providedIn: 'root',
})
export class K365SubscriptionsService {
  private readonly httpClient = inject(HttpClient);

  getK365Subscriptions(): Observable<IK365Subscription[]> {
    const url = 'api/v1/onboarding/users/subscriptions/kaseya-365';

    return this.httpClient.get<IK365Subscription[]>(environment.portalApiUrl + url);
  }

  markSubscriptionsAsViewed(subscriptionIds: string[]): Observable<boolean> {
    const url = 'api/v1/onboarding/users/subscriptions/kaseya-365/viewed';

    return this.httpClient.post<boolean>(environment.portalApiUrl + url, { subscriptionIds });
  }
}
