import { ComponentRef, createComponent, EnvironmentInjector, Injectable } from '@angular/core';
import { SsoTargetPage } from '@design/open-sso-link/models/sso-target-page.enum';
import { map, tap } from 'rxjs/operators';
import { RedirectFormComponent } from '@design/open-sso-link/components/redirect-form.component';
import { SsoService } from '@design/open-sso-link/services/sso.service';
import { Observable } from 'rxjs';
import { ISsoDeepLinkRequest } from '@design/open-sso-link/models/sso-deep-link-request.interface';
import { SsoParamsResponse } from '@design/open-sso-link/models/sso-params-response';
import { LoginUrlResponse } from '@design/open-sso-link/models/login-url-response';

@Injectable({ providedIn: 'root' })
export class OpenSsoLinkService {
  private redirectFormComponent: ComponentRef<RedirectFormComponent>;

  constructor(
    private ssoService: SsoService,
    private environmentInjector: EnvironmentInjector
  ) {}

  openSsoLink(ssoTargetPage: SsoTargetPage, inTheSameTab = false): Observable<void> {
    return this.ssoService.getSsoParams(ssoTargetPage).pipe(
      tap(response => {
        if (response.isSsoEnabled) {
          this.createdRedirectForm(inTheSameTab);
          this.redirectFormComponent.instance.submit(response.body.replyTo, response.body.token);
          this.removeRedirectForm();
          return;
        }

        window.open(response.body as string);
      }),
      map(() => void 0)
    );
  }

  openSsoDeepLink(ssoDeepLinkRequest: ISsoDeepLinkRequest, inTheSameTab = false): Observable<void> {
    return this.ssoService.getSsoDeepLinkParams(ssoDeepLinkRequest).pipe(
      tap(response => {
        this.handleResponse(response, inTheSameTab);
      }),
      map(() => void 0)
    );
  }

  private handleResponse(response: SsoParamsResponse | LoginUrlResponse, inTheSameTab = false): void {
    if (response.isSsoEnabled) {
      this.createdRedirectForm(inTheSameTab);
      this.redirectFormComponent.instance.submit(response.body.replyTo, response.body.token);
      this.removeRedirectForm();
      return;
    }

    window.open(response.body as string);
  }

  private createdRedirectForm(inTheSameTab: boolean): void {
    this.redirectFormComponent = createComponent(RedirectFormComponent, { environmentInjector: this.environmentInjector });
    this.redirectFormComponent.instance.target = inTheSameTab ? '_self' : '_blank';
    document.body.appendChild(this.redirectFormComponent.location.nativeElement);
    this.redirectFormComponent.changeDetectorRef.detectChanges();
  }

  private removeRedirectForm(): void {
    document.body.removeChild(this.redirectFormComponent.location.nativeElement);
  }
}
