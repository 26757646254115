import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IFilter, IFilterConfig } from '@design/filter/models/filter.interface';
import { IMultiselectFilter } from '@design/filter/models/multiselect-filter.interface';
import { IDateRangeFilter } from '@design/filter/models/date-range-filter.interface';

@Component({
  selector: 'itc-input-filter',
  templateUrl: './input-filter.component.html',
  styleUrls: ['./input-filter.component.scss'],
})
export class InputFilterComponent {
  @Input() config: IFilterConfig<unknown>;
  @Input() model: Record<string, unknown>;
  @Input() inputTestId: string;

  @Output() updateModel = new EventEmitter();
  @Output() selectFilter = new EventEmitter();

  searchText: string;
  isOpenSuggestions = false;

  get isFiltering(): boolean {
    return !!this.availableFilters.length;
  }

  get availableFilters(): IFilter<unknown>[] {
    if (!this.config.filters) {
      return [];
    }

    return this.config.filters.filter(item => {
      if (item.type === 'multiselect') {
        const { field } = item as IMultiselectFilter<unknown>;
        return !this.model[field] && !item.hidden;
      }

      const { fieldTo, fieldFrom } = item as IDateRangeFilter<unknown>;
      return !(this.model[fieldFrom] && this.model[fieldTo]) && !item.hidden;
    });
  }

  toggleSuggestions(value: boolean, event?: Event): void {
    event?.stopPropagation();
    this.isOpenSuggestions = value;
  }

  openFilter(name: string): void {
    this.toggleSuggestions(false);
    this.selectFilter.emit(name);
  }

  search(): void {
    if (!this.searchText) {
      return;
    }

    this.model[this.config.search.field] = this.searchText;
    this.updateModel.emit(this.model);
    this.clearInput();
  }

  clearInput(): void {
    this.searchText = '';
  }
}
