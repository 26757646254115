import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ITutorial } from '@layout/tutorials/models/tutorial.interface';
import { tutorialCategoryTagColorNames } from '@layout/tutorials/models/tutorial-category-tag-color-names';
import { tutorialCategoryNameTranslationKeys } from '@layout/tutorials/models/tutorial-category-name-translation-keys';
import { TagColorName } from '@design/tag/models/tag-color-name.enum';
import { TutorialCategory } from '@layout/tutorials/models/tutorial-category.enum';

@Component({
  selector: 'itc-suggested-tutorials-by-category',
  templateUrl: './suggested-tutorials-by-category.component.html',
  styleUrls: ['./suggested-tutorials-by-category.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SuggestedTutorialsByCategoryComponent implements OnInit {
  @Input() category: TutorialCategory;
  @Input() tutorials: ITutorial[];
  @Input() selectedTutorialId: string;

  tagColorName: TagColorName;
  categoryTranslationKey: string;

  ngOnInit(): void {
    this.tagColorName = tutorialCategoryTagColorNames[this.category];
    this.categoryTranslationKey = `tutorials.tutorials-categories.${tutorialCategoryNameTranslationKeys[this.category]}`;
  }
}
