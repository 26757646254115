import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpResponse, HttpStatusCode } from '@angular/common/http';
import { environment } from '@env/environment';
import { SsoTargetPage } from '@design/open-sso-link/models/sso-target-page.enum';
import { ISsoParams } from '@design/open-sso-link/models/sso-params.interface';
import { map } from 'rxjs/operators';
import { SsoParamsResponse } from '@design/open-sso-link/models/sso-params-response';
import { LoginUrlResponse } from '@design/open-sso-link/models/login-url-response';
import { ISsoDeepLinkRequest } from '@design/open-sso-link/models/sso-deep-link-request.interface';

@Injectable({ providedIn: 'root' })
export class SsoService {
  private readonly ssoLinkUrl = 'api/v1/unified-billing-platform/sso-parameters';
  private readonly ssoDeeplinkUrl = 'api/v1/unified-billing-platform/deep-link-sso-parameters';

  constructor(private httpClient: HttpClient) {}

  getSsoParams(ssoTargetPage: SsoTargetPage): Observable<SsoParamsResponse | LoginUrlResponse> {
    return this.httpClient
      .post(environment.portalApiUrl + this.ssoLinkUrl, { ssoTargetPage }, { observe: 'response', responseType: 'text' })
      .pipe(map((response: HttpResponse<string>) => this.mapResponse(response)));
  }

  getSsoDeepLinkParams(ssoDeepLinkRequest: ISsoDeepLinkRequest): Observable<SsoParamsResponse | LoginUrlResponse> {
    return this.httpClient
      .post(environment.portalApiUrl + this.ssoDeeplinkUrl, ssoDeepLinkRequest, { observe: 'response', responseType: 'text' })
      .pipe(map((response: HttpResponse<string>) => this.mapResponse(response)));
  }

  private mapResponse(response: HttpResponse<string>): SsoParamsResponse | LoginUrlResponse {
    if (response.status === HttpStatusCode.Ok) {
      if (response.body) {
        return new SsoParamsResponse(JSON.parse(response.body) as ISsoParams);
      }
    }

    return new LoginUrlResponse(response.body as string);
  }
}
