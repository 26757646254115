import { Injectable } from '@angular/core';
import { IAuthorizationData } from '@core/authorization/models/authorization-data.interface';
import { LocalStorageKey } from '@core/storage/models/local-storage-key.enum';
import { SessionStorageKey } from '@core/storage/models/session-storage-key.enum';

@Injectable({ providedIn: 'root' })
export class StorageService {
  setOrganizationName(value: string): void {
    localStorage.setItem(LocalStorageKey.OrganizationName, value);
  }

  setAuthorizationData(authorizationData: IAuthorizationData): void {
    localStorage.setItem(LocalStorageKey.AuthorizationData, JSON.stringify(authorizationData));
  }

  setTemplateUrl(value: string): void {
    sessionStorage.setItem(SessionStorageKey.UsersTemplate, value);
  }

  getOrganizationName(): string {
    return localStorage.getItem(LocalStorageKey.OrganizationName) || '';
  }

  getAuthorizationData(): IAuthorizationData | undefined {
    const authorizationData = localStorage.getItem(LocalStorageKey.AuthorizationData);
    if (!authorizationData) {
      return undefined;
    }

    return JSON.parse(authorizationData);
  }

  getTemplateUrl(): string {
    return sessionStorage.getItem(SessionStorageKey.UsersTemplate) || '';
  }

  clearAuthorizationData(): void {
    localStorage.removeItem(LocalStorageKey.AuthorizationData);
  }

  clear(): void {
    localStorage.clear();
    sessionStorage.clear();
  }
}
